/** @jsxImportSource @emotion/react */
import { FC } from "react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import { ColorNames, theme } from "../../theme";
import Column from "../Grid/Column";
import Row from "../Grid/Row";
import Typography from "../Typography";

interface BooleanInputProps {
  value: boolean;
  name: string;
  label: string;
  handleChange: (e: { target: { value: boolean; name: string } }) => void;
  disabled?: boolean;
}

const getStyles: CSSRulesResolver<Partial<BooleanInputProps>> = ({
  ...props
}) => ({
  inputWrapper: {
    marginTop: "0.75em",
    textAlign: "left",
    width: "100%",
    height: 20,
    cursor: props.disabled ? "inherit" : "pointer",
    "& input:checked + span": {
      color: `${props.colors.Green[100]}`,
    },
    "& input:checked": {
      backgroundColor: `${props.colors.Green[100]}`,
    },
  },
  radio: {
    webkitAppearance: "none",
    mozAppearance: "none",
    appearance: "none",
    display: "inline-block",
    font: "inherit",
    width: 15,
    height: 15,
    padding: 3,
    margin: 0,
    marginRight: "1em",
    backgroundClip: "content-box",
    border: "0.2px solid #bbbbbb",
    backgroundColor: "#fff",
    borderRadius: "50%",
    boxShadow: theme.boxShadow.default,
  },
});

const BooleanInput: FC<BooleanInputProps> = ({
  name,
  value,
  label,
  handleChange,
  disabled,
}) => {
  const onClick = (value: boolean) => {
    if (!disabled) {
      handleChange({
        target: { value, name },
      });
    }
  };
  const styles = useCSSRulesWithTheme(getStyles, { disabled });
  return (
    <Row noGutters css={{ margin: 0 }}>
      <Column xs={12}>
        <Typography
          variant="textMedium"
          color={ColorNames.BLACK}
          component="div"
          align="left"
        >
          {label}
        </Typography>
      </Column>
      <Column xs={6}>
        <div css={styles.inputWrapper} onClick={() => onClick(true)}>
          <input
            css={styles.radio}
            name={`${name}-yes`}
            type={"radio"}
            checked={!!value}
            onChange={() => {}}
          />
          <Typography
            variant="textMedium"
            component={"span"}
            uppercase
            color={value ? ColorNames.GREEN : ColorNames.GREY}
          >
            Sim
          </Typography>
        </div>
      </Column>
      <Column xs={6}>
        <div css={styles.inputWrapper} onClick={() => onClick(false)}>
          <input
            css={styles.radio}
            name={`${name}-no`}
            type={"radio"}
            checked={!value}
            onChange={() => {}}
          />
          <Typography
            variant="textMedium"
            component={"span"}
            uppercase
            color={!value ? ColorNames.GREEN : ColorNames.GREY}
          >
            Não
          </Typography>
        </div>
      </Column>
    </Row>
  );
};

export default BooleanInput;
