import Typography from "../../../components/Typography";
import { useAuth } from "../../../hooks/useAuth";
import { formatInputMask } from "../../../utils/formatNumber";
import TermLine from "./TermLine";

const TermsAndConditonsText = () => {
  const { user } = useAuth();
  return (
    <>
      <TermLine>
        <Typography
          variant="textLarge"
          align="left"
          component="p"
          margin="sm"
          uppercase
        >
          Sobre nossos serviços
        </Typography>
      </TermLine>
      <TermLine>
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Estes{" "}
          <Typography variant="textMedium" component="span" uppercase>
            Termos e Condições
          </Typography>{" "}
          para Prestação de Serviço constituem um contrato{" ("}"
          <Typography variant="textMedium" component="span" color="Green">
            contrato
          </Typography>
          "{") "}
          celebrado entre{" "}
          <Typography variant="textMedium" component="span" color="Green">
            {user.name}
          </Typography>
          , brasileiro,{" "}
          <Typography variant="textMedium" component="span" color="Green">
            {user.civilState}
          </Typography>
          ,{" "}
          <Typography variant="textMedium" component="span" color="Green">
            {user.profession}
          </Typography>
          , portador do CPF nº{" "}
          <Typography variant="textMedium" component="span" color="Green">
            {formatInputMask(user.cpf || "", "cpfCnpj")}
          </Typography>
          , e do R.G. nº{" "}
          <Typography variant="textMedium" component="span" color="Green">
            {user.rg}
          </Typography>
          , residente e domiciliado em{" "}
          <Typography variant="textMedium" component="span" color="Green">
            {user.city}
          </Typography>
          ,{" "}
          <Typography variant="textMedium" component="span" color="Green">
            {user.state}{" "}
          </Typography>
          -
          <Typography variant="textMedium" component="span" color="Green">
            {" "}
            {formatInputMask(user.cep || "", "cep")}
          </Typography>
          {" ("}"
          <Typography variant="textMedium" component="span" color="Green">
            contratado
          </Typography>
          "{") "}
          Prisma Corretora de Seguros Ltda., sociedade empresa limitada, com
          sede na cidade de São Paulo, Estado de São Paulo, na Av. Brigadeiro
          Faria Lima, nº 1.903 – Cj. 44, CEP 01452- 911, inscrita no CNPJ nº
          57.061.327/0001-90 {" ("}"
          <Typography variant="textMedium" component="span" color="Green">
            contratante
          </Typography>
          " e, em conjunto com o Contratado, "
          <Typography variant="textMedium" component="span" color="Green">
            partes
          </Typography>
          "{") "}.
        </Typography>
      </TermLine>
      <TermLine>
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          O Contratado deve ler, entender e aceitar todas as condições
          estabelecidas neste Contrato antes de acessar a(s) plataforma(s)
          disponibilizada(s) pela Contratante e iniciar a prestação dos
          serviços. Ao aderir a este Contrato, o Contratado concorda com todas
          as regras aqui estabelecidas.
        </Typography>
      </TermLine>
      <TermLine>
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Considerando que:
        </Typography>
      </TermLine>
      <TermLine subSection="i">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          A Contratante é uma corretora de seguros devidamente registrada na
          Superintendência de Seguros Privados – SUSEP e opera no mercado de
          seguros há mais de 35 anos, tendo se especializado no segmento
          agrícola;
        </Typography>
      </TermLine>
      <TermLine subSection="ii">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          O Contratado é capacitado para orientar agricultores na seleção das
          tecnologias produtivas e na gestão das explorações;
        </Typography>
      </TermLine>
      <TermLine subSection="iii">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          A Contratante têm interesse em contratar o Contratado para prestar
          serviços de consultoria e assessoria para agricultores sobre os
          benefícios dos Seguros Rurais para suas atividades, considerando sua
          expertise na seleção das tecnologias produtivas e na gestão das
          explorações.
        </Typography>
      </TermLine>
      <TermLine>
        <Typography
          variant="textMedium"
          align="left"
          component="p"
          margin="sm"
          uppercase
        >
          CLÁUSULA 1ª // DO OBJETO DO CONTRATO
        </Typography>
      </TermLine>
      <TermLine>
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          O objeto desse Contrato é a contratação, do Contratado pela
          Contratante, para prestação de serviços para orientar agricultores e
          produtores rurais ("Clientes") sobre os benefícios dos Seguros Rurais
          para suas atividades, apresentando os produtos disponíveis no mercado,
          seus custos e benefícios ("Serviços").
        </Typography>
      </TermLine>
      <TermLine>
        <Typography
          variant="textMedium"
          align="left"
          component="p"
          margin="sm"
          uppercase
        >
          CLÁUSULA 2ª // DOS SERVIÇOS
        </Typography>
      </TermLine>
      <TermLine section="2" subSection="1">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          A prestação dos Serviços objeto do Contrato será realizada pelo
          Contratado, sem qualquer exclusividade, inexistindo qualquer
          subordinação ou controle de horário, uma vez que não existe vínculo
          empregatício entre a Contratante e o Contratado.
        </Typography>
      </TermLine>
      <TermLine section="2" subSection="2">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          O Contratado declara que presta serviços para outras empresas,
          entretanto, fica neste ato, proibido de estabelecer qualquer relação
          comercial e/ou de prestar serviços similares ao objeto deste Contrato
          a quaisquer empresas concorrentes, ou ainda que atuem no mesmo
          segmento da Contratante, pelo período de 2 (dois) anos contados da
          assinatura deste Contrato.
        </Typography>
      </TermLine>
      <TermLine>
        <Typography
          variant="textMedium"
          align="left"
          component="p"
          margin="sm"
          uppercase
        >
          CLÁUSULA 3ª // OBRIGAÇÕES DAS PARTES
        </Typography>
      </TermLine>
      <TermLine section="3" subSection="1">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          A Contratante obriga-se a:
        </Typography>
      </TermLine>
      <TermLine subSection="i">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          fornecer ao Contratado as informações necessárias para a execução dos
          Serviços;
        </Typography>
      </TermLine>
      <TermLine subSection="ii">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          deixar à disposição do Contratado um canal aberto de comunicação,
          nomeando um ou mais interlocutores para permitir fluidez e dinamismo
          dos trabalhos;
        </Typography>
      </TermLine>
      <TermLine subSection="iii">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          realizar os pagamentos em conformidade com a Cláusula 4ª abaixo;
        </Typography>
      </TermLine>
      <TermLine subSection="iv">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          efetuar o processo de contratação dos seguros do ramo agrícola
          contratados pelos Clientes, incluindo: (a) a elaboração de cotações;
          (b) a emissão de propostas e verificação de suas emissões; (c) o
          encaminhamento das respectivas apólices aos clientes; (d) o
          acompanhamento dos pagamentos dos valores devidos no âmbito das
          respectivas apólices, e (f) a assessoria nas liquidações de sinistro.
        </Typography>
      </TermLine>
      <TermLine section="3" subSection="2">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          O Contratado obriga-se a:
        </Typography>
      </TermLine>
      <TermLine subSection="i">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          prestar regularmente os Serviços objeto deste Contrato de acordo com
          as melhores práticas e padrões aceitos pela indústria, obrigando-se a
          fornecer aos Clientes orientação técnica adequada e de forma pontual;
        </Typography>
      </TermLine>
      <TermLine subSection="ii">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          orientar os Clientes sobre os benefícios dos seguros rurais, incluindo
          apresentação dos seguros disponíveis para as diferentes iniciativas
          produtivas no meio rural;
        </Typography>
      </TermLine>
      <TermLine subSection="iii">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          observar e cumprir todas as leis, normas, regulamentos, portarias,
          códigos e licenças aplicáveis, sejam federais, estaduais ou
          municipais;
        </Typography>
      </TermLine>
      <TermLine subSection="iv">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          cumprir integralmente este Contrato e seus anexos, obedecendo
          rigorosamente os prazos contratuais, projetos e cronogramas acordados;
        </Typography>
      </TermLine>
      <TermLine subSection="v">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          arcar com todos os tributos e encargos incidentes sobre a prestação
          dos Serviços, bem como quaisquer outros encargos de natureza
          trabalhista, previdenciária ou tributária;
        </Typography>
      </TermLine>
      <TermLine subSection="vi">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          cumprir todas as obrigações fiscais de sua responsabilidade
          decorrentes do presente Contrato, bem como quanto a emissão de
          documentos fiscais exigíveis de acordo com a legislação vigente;
        </Typography>
      </TermLine>
      <TermLine subSection="vii">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          atender, em prazo não superior a 48 (quarenta e oito) horas,
          solicitações da Contratante para contato com os Clientes;
        </Typography>
      </TermLine>
      <TermLine subSection="viii">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          fornecer, à Contratante, relatório sobre a orientação dada aos
          Clientes que manifestaram eventual interesse em contratar seguros
          rurais;
        </Typography>
      </TermLine>
      <TermLine subSection="ix">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          fornecer todas as informações e documentos solicitados pela
          Contratante, no prazo solicitado;
        </Typography>
      </TermLine>
      <TermLine subSection="x">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          comunicar à Contratante, assim que tiver conhecimento, sobre qualquer
          anormalidade que se verifique durante a prestação dos Serviços, tais
          como sinistros ou qualquer outro evento que possa ocasionar danos, bem
          como tentar minimizar suas consequências, elaborando relatório formal
          das providências tomadas e ações corretivas adotadas;
        </Typography>
      </TermLine>
      <TermLine subSection="xi">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          não subcontratar qualquer terceiro para a prestação dos Serviços,
          salvo mediante o consentimento prévio e por escrito da Contratante.
        </Typography>
      </TermLine>
      <TermLine>
        <Typography
          variant="textMedium"
          align="left"
          component="p"
          margin="sm"
          uppercase
        >
          CLÁUSULA 4ª // REMUNERAÇÃO E CONDIÇÕES DE PAGAMENTO
        </Typography>
      </TermLine>
      <TermLine section="4" subSection="1">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Em contrapartida à prestação dos Serviços, a Contratante deverá ao
          Contratado 25% do comissionamento registrado em cada apólice
          contratada pelo Clientes que forem orientados diretamente pelo
          Contratado, conforme relatório emitido nos termos da Cláusula
          3.2(viii). Para que não haja dúvida, o Contratado não fará jus a
          qualquer remuneração com relação aos Clientes para os quais não
          prestar os Serviços.
        </Typography>
      </TermLine>
      <TermLine section="4" subSection="2">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          O pagamento da remuneração será realizado, pela Contratante ao
          Contratado, após recebimento do respectivo comissionamento pela
          Contratante e mediante recebimento da nota fiscal de serviço
          apresentada pelo Contratado. Fica entendido e acordado que a
          remuneração correspondente às participações devidas ao Contratado nos
          termos da Cláusula 4.1 será calculada sobre o montante líquido
          recebido pela Contratante (deduzido todos os impostos devidos) e o
          pagamento poderá ser diferido de acordo com a vigência da apólice.
        </Typography>
      </TermLine>
      <TermLine section="4" subSection="3">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          A Contratante poderá reter o pagamento, e os pagamentos serão
          efetuados, sem nenhum custo ou multa contratual, após a regularização
          das pendências, nas seguintes hipóteses: (i) se o Contratado deixar de
          apresentar os documentos exigidos neste Contrato, ou, nestes, sejam
          verificadas quaisquer irregularidades ou (ii) se houver erro de
          faturamento ou divergência de valor.
        </Typography>
      </TermLine>
      <TermLine section="4" subSection="4">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          A remuneração especificada nesta Cláusula 4ª equivale à remuneração
          total do Contratado, não sendo devido pela Contratante qualquer valor
          adicional, inclusive em razão das despesas incorridas pelo Contratado
          com relação à prestação dos Serviços. Não obstante o disposto acima,
          caso o Contratado amplie a prestação dos serviços de assessoria,
          incluindo empresas assistidas que sejam Cooperativas; Revendas de
          Insumos; Revenda de Máquinas e Equipamentos Agrícolas ou Associação de
          Produtores, de forma a ampliar consideravelmente o número de
          produtores rurais assistidos, a Contratante será responsável pelo
          pagamento das despesas operacionais referentes exclusivamente à
          assessoria prestada pelo Contratado às Cooperativas; Revendas de
          Insumos; Revenda de Máquinas e Equipamentos Agrícolas ou Associação de
          Produtores, ressalvado, entretanto, que (i) o valor máximo das
          despesas operacionais de responsabilidade da Contratante está limitado
          ao montante correspondente a 20% (vinte por cento) do comissionamento
          registrado em cada apólice contratada pelos clientes que forem
          orientados diretamente pelo Contratado, e (ii) tais despesas
          operacionais deverão ser previamente informadas à e aprovadas pela
          Contratada.
        </Typography>
      </TermLine>
      <TermLine>
        <Typography
          variant="textMedium"
          align="left"
          component="p"
          margin="sm"
          uppercase
        >
          CLÁUSULA 5ª // CONFIDENCIALIDADE
        </Typography>
      </TermLine>
      <TermLine section="5" subSection="1">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Todas as informações fornecidas pela Contratante ao Contratado ou que
          este venha a ter acesso em virtude do presente Contrato, sejam elas de
          qualquer natureza, seja de forma escrita, verbal, visual, audiovisual
          ou eletrônica, são consideradas de natureza sigilosa e confidencial,
          obrigando-se o Contratado a manter absoluto sigilo, respondendo ainda,
          civil e criminalmente, pela revelação, reprodução ou mal uso de
          referidas informações.
        </Typography>
      </TermLine>
      <TermLine section="5" subSection="2">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Ao término deste Contrato, por qualquer que seja o motivo, o
          Contratado deverá devolver para a Contratante todas as informações da
          Contratante ou dos Clientes que estiverem em seu poder,
          comprometendo-se a não extrair nem reter cópias, resumos ou anotações.
        </Typography>
      </TermLine>
      <TermLine>
        <Typography
          variant="textMedium"
          align="left"
          component="p"
          margin="sm"
          uppercase
        >
          CLÁUSULA 6ª // PRAZO DE VIGÊNCIA CONTRATUAL
        </Typography>
      </TermLine>
      <TermLine section="6" subSection="1">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          O prazo de vigência deste Contrato é de 12 (doze) meses, a contar da
          data de assinatura. Caso as Partes concordem em renovar o prazo deste
          Contrato, as Partes deverão, antes do fim do prazo de vigência de 12
          (doze) meses, celebrar um aditivo a esse contrato refletindo o novo
          prazo acordado.
        </Typography>
      </TermLine>
      <TermLine>
        <Typography
          variant="textMedium"
          align="left"
          component="p"
          margin="sm"
          uppercase
        >
          CLÁUSULA 7ª // RESCISÃO
        </Typography>
      </TermLine>
      <TermLine section="7" subSection="1">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Qualquer uma das Partes poderá rescindir este Contrato mediante aviso
          prévio por escrito de 30 (trinta) dias. Em caso de rescisão imotivada
          pela Contratante, esta compromete-se a pagar pelos Serviços
          efetivamente prestados até a data da rescisão, observado, entretanto,
          que o pagamento será realizado nos termos da Cláusula 4.2.
        </Typography>
      </TermLine>
      <TermLine section="7" subSection="2">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Este Contrato poderá ser rescindido por uma Parte em caso de um
          descumprimento pela outra Parte que não tenha sido sanado dentro de 10
          (dez) dias contados da respectiva notificação.
        </Typography>
      </TermLine>
      <TermLine section="7" subSection="3">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Em caso de rescisão ou término deste Contrato, por qualquer motivo, o
          disposto na Cláusula 2.2 deverá permanecer válido.
        </Typography>
      </TermLine>
      <TermLine>
        <Typography
          variant="textMedium"
          align="left"
          component="p"
          margin="sm"
          uppercase
        >
          CLÁUSULA 8ª // DISPOSIÇÕES GERAIS
        </Typography>
      </TermLine>
      <TermLine section="8" subSection="1">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Este Contrato só poderá ser alterado, por meio de instrumento escrito
          com o aceite de ambas as Partes.
        </Typography>
      </TermLine>
      <TermLine section="8" subSection="2">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Nenhum atraso ou falha por uma Parte em tomar medidas com relação a
          qualquer violação pela outra Parte constituirá renúncia do direito
          dessa Parte de exigir o cumprimento de qualquer disposição deste
          Contrato ou tomar qualquer ação com relação a tal quebra ou qualquer
          violação posterior. A renúncia de qualquer disposição do presente
          Contrato só será eficaz se for feita por escrito e assinada pela Parte
          renunciante.
        </Typography>
      </TermLine>
      <TermLine section="8" subSection="3">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Se qualquer disposição deste Contrato for considerada inválida ou
          inexequível, tal disposição deve ser interpretada na máxima medida
          para ser aplicável e refletir a intenção das Partes, e a validade e
          exequibilidade das demais disposições do presente Contrato não serão
          afetadas.
        </Typography>
      </TermLine>
      <TermLine section="8" subSection="4">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          Este Contrato constitui o integral acordo e entendimento entre as
          Partes com relação à questão ora tratada e substitui todas as
          discussões, negociações, entendimentos e acordos anteriores, sejam por
          escrito ou verbais.
        </Typography>
      </TermLine>
      <TermLine section="8" subSection="5">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          A Contratante poderá ceder este Contrato, no todo ou em parte, sem o
          consentimento do Contratado. O Contratado não poderá ceder seus
          direitos ou delegar qualquer suas das atribuições ou obrigações aqui
          previstas sem o consentimento prévio por escrito da Contratante.
        </Typography>
      </TermLine>
      <TermLine>
        <Typography
          variant="textMedium"
          align="left"
          component="p"
          margin="sm"
          uppercase
        >
          CLÁUSULA 9ª // FORO
        </Typography>
      </TermLine>
      <TermLine section="9" subSection="1">
        <Typography variant="textMedium" align="left" component="p" margin="sm">
          As Partes elegem o Foro Central de São Paulo, Estado de São Paulo,
          como único competente para dirimir quaisquer dúvidas ou litígios
          oriundos deste Contrato, com exclusão de qualquer outro, por mais
          privilegiado que seja ou venha a ser.
        </Typography>
      </TermLine>
    </>
  );
};

export default TermsAndConditonsText;
