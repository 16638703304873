/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { Services } from "../../../../graphql/__GENERATED__/types";
import Many2OneInput, {
  Many2OneInputProps,
} from "../../../Input/SelectInputs/Many2OneInput";

interface ContactSelectProps
  extends Omit<Many2OneInputProps, "serviceName" | "optionDisplay" | "domain"> {
  domain?: any;
}

const ContactSelect: FC<ContactSelectProps> = (props) => {
  return (
    <>
      <Many2OneInput
        {...props}
        serviceName={Services.Contacts}
        optionDisplay="avatar"
        domain={props.domain || { name: { $ne: null } }}
      />
    </>
  );
};

export default ContactSelect;
