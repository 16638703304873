/** @jsxImportSource @emotion/react */

import { CSSProperties, FC, useEffect, useState } from "react";
import { useCSSRulesWithTheme } from "../../hooks/useCSSRulesWithTheme";
import Button from "../Button";
import Column from "../Grid/Column";
import Row from "../Grid/Row";
import TextInput from "../Input/TextInput";
import Typography from "../Typography";
import { useUIStore } from "../../hooks/useUIStore";
import { validateEmailOrUsername, updateUserById } from "../../services/user";
import { useAuth } from "../../hooks/useAuth";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import { formatInputMask } from "../../utils/formatNumber";
import Tooltip from "../Tooltip";
import { IconNames } from "../Icons/styles/iconNames";
import Icon from "../Icons";
import { ColorNames } from "../../theme";
import ScrollContainer from "../ScrollContainer";

const getCSSRules = ({ ...props }) => ({
  modal: {
    position: "fixed" as CSSProperties["position"],
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    paddingTop: 64,
    backgroundColor: `${props.colors.White[60]}`,
    display: "flex",
    alignItems: "start",
    justifyContent: "end",
    zIndex: 1000,
  },
  modalContent: {
    border: `2px solid ${props.colors.Green[100]}`,
    borderRadius: 10,
    backgroundColor: `${props.colors.White[100]}`,
    width: "25%",
    height: "calc(100% - 48px)",
    minWidth: 250,
    padding: "12px 12px 12px 20px",
    marginRight: 20,
    overflow: "hidden",
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  mainInfo: {
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 24px 12px 12px",
  },
  defaultButton: {
    margin: "20px 0px",
  },
  userInfo: {
    padding: "5px 0px",
    marginBottom: 10,
  },
  userCity: {
    width: "80%",
    marginRight: 10,
  },
  colMobileMb: {
    [`@media (max-width: ${props.breakpoints.sm}px)`]: {
      marginBottom: props.gutters.xs,
    },
  },
});

export interface UserProfileModalProps {
  show: boolean;
  onClose: () => void;
  onRef: any;
}

export interface InputError {
  [key: string]: string;
}

export interface EditableInput {
  [key: string]: string;
}

const UserProfileModal: FC<UserProfileModalProps> = ({
  show,
  onClose,
  onRef,
}) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const { user, loadCurrentUser } = useAuth();
  const auth = useAuth();
  const { isProfileModalOpen } = useUIStore();
  const { errorHandler } = useErrorHandler();

  const [isInputDisabled, setIsInputDisabled] = useState(true);
  const [editableInput, setEditableInput] = useState<EditableInput>({});
  const [inputError, setInputError] = useState<InputError>({});

  const handleEditProfile = () => {
    setIsInputDisabled(false);
  };

  const handleEditInput = (fieldName: string, value: string) => {
    setEditableInput({ ...editableInput, [fieldName]: value });
  };

  const handleFinishEditing = async (fieldName: string) => {
    try {
      await validateEmailOrUsername(
        { ...auth },
        { [fieldName]: editableInput[fieldName] }
      );
      setInputError({ ...inputError, [fieldName]: "" });
    } catch (e: any) {
      if (e.message) {
        setInputError({ ...inputError, [fieldName]: e.message });
      } else {
        errorHandler(new Error("Nao foi possivel editar o usuário"), e);
      }
    }
  };

  const handleSaveProfile = async () => {
    try {
      await updateUserById({ ...auth }, user?._id!, {
        ...user,
        email: editableInput.email,
        username: editableInput.username,
      });
      setIsInputDisabled(true);
      await loadCurrentUser();
    } catch (e) {
      errorHandler(new Error("Nao foi possivel atualizar o usuário"), e);
    }
  };

  //disable edit fields and show btn when modal closes
  useEffect(() => {
    isProfileModalOpen && setIsInputDisabled(true);
    setEditableInput({
      ...editableInput,
      email: user.email,
      username: user.username,
    });
    setInputError({});
  }, [isProfileModalOpen]);

  if (!show) {
    return null;
  }
  return (
    <div css={styles.modal}>
      <div ref={onRef} css={styles.modalContent}>
        <Row>
          <Column>
            <div css={styles.modalHeader}>
              <Typography
                variant="textMedium"
                align="left"
                color="Green"
                component="div"
                uppercase
              >
                Perfil do usuário
              </Typography>
              <Icon
                name={IconNames.Close}
                color={ColorNames.GREEN}
                onClick={onClose}
              />
            </div>
          </Column>
        </Row>
        {user.name !== undefined && (
          <div css={styles.mainInfo}>
            <Typography
              variant="textLarge"
              align="left"
              color="Green"
              component="p"
              uppercase
            >
              {user.name}
            </Typography>
            <Tooltip id="editar-perfil" text="EDITAR PERFIL">
              <Icon
                name={IconNames.Edit}
                color={ColorNames.PINK}
                onClick={handleEditProfile}
              />
            </Tooltip>
          </div>
        )}
        <ScrollContainer height="calc(100% - 100px)">
          {user.username !== undefined && (
            <Row>
              <Column>
                <TextInput
                  disabled={isInputDisabled}
                  handleChange={(value) => handleEditInput("username", value)}
                  value={editableInput.username}
                  errorMessage={
                    inputError.username
                      ? inputError.username
                      : inputError.username
                  }
                  handleBlur={() => handleFinishEditing("username")}
                />
              </Column>
            </Row>
          )}
          {user.email !== undefined && (
            <Row>
              <Column>
                <TextInput
                  disabled={isInputDisabled}
                  handleChange={(value) => handleEditInput("email", value)}
                  value={editableInput.email}
                  errorMessage={
                    inputError.email ? inputError.email : inputError.email
                  }
                  handleBlur={() => handleFinishEditing("email")}
                />
              </Column>
            </Row>
          )}
          {user.cpf !== undefined && (
            <Row>
              <Column>
                <TextInput
                  disabled={true}
                  handleChange={() => null}
                  value={formatInputMask(user.cpf || "", "cpfCnpj")}
                />
              </Column>
            </Row>
          )}
          {user.rg !== undefined && (
            <Row>
              <Column>
                <TextInput
                  disabled={true}
                  handleChange={() => null}
                  value={user.rg}
                />
              </Column>
            </Row>
          )}
          {user.cep !== undefined && (
            <Row>
              <Column>
                <TextInput
                  disabled={true}
                  handleChange={() => null}
                  value={formatInputMask(user.cep || "", "cep")}
                />
              </Column>
            </Row>
          )}
          <Row>
            {user.city !== undefined && (
              <Column sm={12} md={8} css={styles.colMobileMb}>
                <TextInput
                  disabled={true}
                  handleChange={() => null}
                  value={user.city}
                />
              </Column>
            )}
            {user.state !== undefined && (
              <Column>
                <TextInput
                  disabled={true}
                  handleChange={() => null}
                  value={user.state}
                />
              </Column>
            )}
          </Row>
          {!isInputDisabled && (
            <Button
              style="outlinePrimary"
              text="Salvar alterações"
              css={styles.defaultButton}
              onClick={handleSaveProfile}
              disabled={!!inputError.email || !!inputError.username}
            />
          )}
          {/* {isFlagEnabled("admin-only") && (
            <CustomLink
              to={ToContactDetailsLocation(user.contactId)}
              onClick={onClose}
            >
              <Button
                style="outlinePrimary"
                text="Meu contato"
                css={styles.defaultButton}
                iconName={IconNames.Share}
              />
            </CustomLink>
          )} */}
        </ScrollContainer>
      </div>
    </div>
  );
};

export default UserProfileModal;
