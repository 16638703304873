import { FC, useMemo } from "react";
import EditableSection from "../../../../../../components/EditableSection";
import { IconNames } from "../../../../../../components/Icons/styles/iconNames";
import useDocumentDetails from "../../../../../../hooks/useDocumentDetails";
import { DetailsRowProps } from "../../../../../../components/Details/components/Main/Grid/DetailsRow";
import { formatISODate } from "../../../../../../utils/dateUtils";
import DetailsSectionColumns from "../../../../../../components/Details/components/Main/Grid/DetailsSectionColumns";
import { DetailsRowValueVariants } from "../../../../../../components/Details/components/Main/Grid/DetailsRowValue";
import {
  Services,
  TransactionalEvents,
} from "../../../../../../graphql/__GENERATED__/types";
import useConfirmedAction from "../../../../../../hooks/useConfirmedAction";

const ComplementaryData: FC = () => {
  const {
    handleCopyComplementaryFormLink,
    handleCopyNfeUploadFormLink,
    handleSendTransactionalEvent,
    documentData: { crop, farmer, _id, visitPerson },
  } = useDocumentDetails();

  const handleComplementaryDataEmail = useConfirmedAction(
    "Deseja enviar o email com o formulário de Datas Complementares para o produtor?",
    () => handleSendTransactionalEvent(TransactionalEvents.ComplementaryData)
  );
  const handleInvoicesEmail = useConfirmedAction(
    "Deseja enviar o email com o formulário de Notas Fiscais para o produtor?",
    () => handleSendTransactionalEvent(TransactionalEvents.InvoicesForm)
  );
  const footerButtons = useMemo(() => {
    return [
      {
        text: "Copiar link do Formulário",
        iconName: IconNames.Share,
        onClick: handleCopyComplementaryFormLink,
      },
      {
        text: "Enviar Form Dados Complementares",
        iconName: IconNames.Send,
        onClick: handleComplementaryDataEmail,
      },
      {
        text: "Formulário Notas Fiscais",
        iconName: IconNames.Share,
        onClick: handleCopyNfeUploadFormLink,
      },
      {
        text: "Enviar Form Notas Fiscais",
        iconName: IconNames.Send,
        onClick: handleInvoicesEmail,
      },
    ];
  }, []);

  const complementaryDataInfo: DetailsRowProps[][] = useMemo(() => {
    return [
      [
        {
          label: "Documento",
          variant: DetailsRowValueVariants.File,
          value: {
            file: farmer?.documentFile,
            objectId: farmer?._id,
            fieldPath: "documentFile",
            serviceName: Services.Contacts,
          },
        },
        {
          label: "Comprovante de Residência",
          variant: DetailsRowValueVariants.File,
          value: {
            file: farmer?.addressProofFile,
            objectId: farmer?._id,
            fieldPath: "documentFile",
            serviceName: Services.Contacts,
          },
        },
        {
          label: "Notas Fiscais",
          variant: DetailsRowValueVariants.File,
          value: {
            file: {
              fileName: "Notas Fiscais.zip",
            },
            objectId: _id,
            fieldPath: "costNFFiles",
            serviceName: Services.Documents,
          },
        },
      ],
      [
        {
          label: "Acompanhamento da Vistoria",
          value: visitPerson?.name,
        },
        {
          label: "Data de Início da Colheita",
          value: formatISODate(crop?.harvestStartDate),
        },
        {
          label: "Data do Fim Colheita",
          value: formatISODate(crop?.harvestEndDate),
        },
      ],
    ];
  }, []);

  return (
    <EditableSection
      noTitle
      main
      title="Dados Complementares"
      footerButtons={footerButtons}
    >
      <DetailsSectionColumns columns={complementaryDataInfo} />
    </EditableSection>
  );
};

export default ComplementaryData;
