/** @jsxImportSource @emotion/react */

import React, { FC, useEffect, useMemo, useState } from "react";
import Column from "../Grid/Column";
import Row from "../Grid/Row";
import Typography, { BaseFontSize } from "../Typography";
import Button from "../Button";
import RadioInput from "../Input/RadioInput";
import { useUIStore } from "../../hooks/useUIStore";
import { useNavigate } from "react-router-dom";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import { useAuth } from "../../hooks/useAuth";
import { AuthContextInterface } from "../../contexts/auth";

export interface RejectionProps {
  data: any;
  callbackLocation: string;
  confirmationMessage: string;
  errorMessage: string;
  rejectReasons: any[];
  deletePartnerOrQuotation: (
    auth: AuthContextInterface,
    id: string,
    rejectReason: string
  ) => void;
}

const Rejection: FC<RejectionProps> = ({
  data,
  callbackLocation,
  confirmationMessage,
  errorMessage,
  rejectReasons,
  deletePartnerOrQuotation,
}) => {
  const [isDisplayedTextArea, setIsDisplayedTextArea] =
    useState<boolean>(false);
  const displayTextArea = () => setIsDisplayedTextArea(true);
  const hideTextArea = () => setIsDisplayedTextArea(false);
  const { errorHandler } = useErrorHandler();
  const authStore = useAuth();

  const { closeDrawer, openSubmittedModal, closeSubmittedModal } = useUIStore();

  const [state, setState] = useState({
    "motivo-cancelamento": "",
  });
  const [textArea, setTextArea] = useState("");
  const navigate = useNavigate();

  const styles = {
    main: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    },
    input: {
      textAlign: "left",
    },
    textArea: {
      marginTop: 10,
      width: "100%",
      display: `${isDisplayedTextArea ? "inline" : "none"}`,
      fontSize: BaseFontSize,
    },
  };

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  }

  const handleChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextArea(e.target.value);
  };

  const showRejectedMessage = () => {
    openSubmittedModal(confirmationMessage);
    const interval = setTimeout(() => {
      closeSubmittedModal();
      closeDrawer();
      navigate(callbackLocation);
    }, 3000);
    return () => clearInterval(interval);
  };

  const rejectReason = useMemo(() => {
    if (textArea !== "") {
      return `${state["motivo-cancelamento"]} (${textArea})`;
    } else {
      return `${state["motivo-cancelamento"]}`;
    }
  }, [state, textArea]);

  const handleDelete = async (auth: AuthContextInterface) => {
    try {
      await deletePartnerOrQuotation(
        { ...auth },
        data?._id!,
        //TODO: mudar reject reason model na db e depois mudar aqui
        rejectReason // ta meio gambiarra mas funciona, no db fica esquisito
      );
      showRejectedMessage();
    } catch (e) {
      errorHandler(new Error(errorMessage), e);
    }
  };

  useEffect(() => {
    if (state["motivo-cancelamento"] === "outros") {
      displayTextArea();
    } else {
      hideTextArea();
    }
  }, [state]);

  useEffect(() => {
    if (!data) {
      closeDrawer();
      navigate(callbackLocation);
    }
  }, []);

  return (
    <div css={styles.main}>
      <Column xs={10} sm={6}>
        <Row>
          <Column>
            <Typography
              variant="textMedium"
              align="left"
              component="div"
              color="Black"
              uppercase
            >
              Motivo da recusa
            </Typography>
          </Column>
        </Row>
        <Row>
          <Column>
            {rejectReasons.map((rejectReason, id) => (
              <RadioInput
                key={`rejectReason-${id}`}
                name="motivo-cancelamento"
                value={rejectReason.value}
                label={rejectReason.label}
                onChange={handleChange}
              />
            ))}
            <textarea
              css={styles.textArea}
              id="text-area"
              value={textArea}
              name="motivo-cancelamento"
              onChange={handleChangeTextArea}
            />
          </Column>
        </Row>
        <Row justify="center">
          <Column xs={12} lg={6}>
            <Button
              text="Cancelar"
              style="outlinePrimary"
              onClick={() => navigate(-1)}
            />
          </Column>
          <Column xs={12} lg={6}>
            <Button
              text="Enviar"
              style="outlinePrimary"
              onClick={() => handleDelete(authStore)}
            />
          </Column>
        </Row>
      </Column>
    </div>
  );
};

export default Rejection;
