import { SelectInputOptionInterface } from "./../components/Input/SelectInputs/SelectInput";
import useModel from "./useModel";
import { CultureObject } from "../models/Culture";
import { useState, useEffect } from "react";

const useCultureOptions = () => {
  const culturesModel = useModel<CultureObject>("culturas");
  const [cultureOptions, setCultureOptions] = useState<
    SelectInputOptionInterface[]
  >([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const cultureSearchPromise = culturesModel.search({});
    cultureSearchPromise.then((data) => {
      const newCultureOptions = data.map((culture) => ({
        value: culture._id,
        label: culture.name,
      }));
      setCultureOptions(newCultureOptions);
      setLoading(false);
    });
  }, []);

  return { cultureOptions, loading };
};
export default useCultureOptions;
