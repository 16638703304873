/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { FC, ReactNode } from "react";
import PageTitle from "../components/PageTitle";
import { Contact } from "../graphql/__GENERATED__/types";

interface ContactDetailsContextValue {
  refetch: () => Promise<void>;
  contactData: Contact;
}
export const ContactDetailsContext =
  React.createContext<ContactDetailsContextValue>({
    contactData: {},
    refetch: async () => {},
  });

interface ContactDetailsProviderProps extends ContactDetailsContextValue {
  children: ReactNode;
  contactData: Contact;
  refetch: () => Promise<any>;
}

const ContactDetailsProvider: FC<ContactDetailsProviderProps> = ({
  children,
  contactData,
  refetch,
}) => {
  return (
    <>
      {<PageTitle title={`Contato - ${contactData.name}`} />}
      <ContactDetailsContext.Provider value={{ contactData, refetch }}>
        {children}
      </ContactDetailsContext.Provider>
    </>
  );
};

export default ContactDetailsProvider;
