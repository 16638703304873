//@ts-nocheck

import React, { CSSProperties, ReactElement, ReactNode, useMemo } from "react";
import {
  Container as GridContainer,
  setConfiguration,
  useScreenClass,
} from "react-grid-system";
import { useTheme } from "@emotion/react";
import { CustomTheme, ScreenClass } from "../../theme";

export interface ContainerProps extends ScreenClassMap<boolean> {
  fluid?: boolean;
  align?: Align;
  justify?: Justify;
  direction?: Direction;
  style?: CSSProperties;
  className?: string;
  children?: ReactNode;
  onScroll?: (e: React.UIEvent<HTMLDivElement>) => void;
}

const defaultProps = {
  fluid: true,
};

export type Align = "normal" | "start" | "center" | "end" | "stretch";
export type Justify =
  | "start"
  | "center"
  | "end"
  | "between"
  | "around"
  | "initial"
  | "inherit";
export type Direction = "column" | "row" | "column-reverse" | "row-reverse";
export type ScreenClassMap<T> = Partial<Record<ScreenClass, T>>;
export type Offsets = ScreenClassMap<number>;
export type Push = ScreenClassMap<number>;
export type Pull = ScreenClassMap<number>;

const Container = (props: ContainerProps): ReactElement => {
  const screenClass = useScreenClass();
  const theme = useTheme() as CustomTheme;

  const breakpoints: number[] = useMemo(() => {
    return Object.values(theme.breakpoints);
  }, [theme.breakpoints]);

  const gutterWidth: number = useMemo(() => {
    return theme.gutters[screenClass];
  }, [screenClass, theme.gutters]);

  setConfiguration({ breakpoints, gutterWidth });

  return <GridContainer {...props} />;
};

Container.defaultProps = defaultProps;
export default Container;
