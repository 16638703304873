import { useCallback } from "react";
import * as modelService from "../services/models";
import { useAuth } from "./useAuth";
import { FileData } from "../services/fetch";

const useModel = <T>(modelName: string) => {
  const authState = useAuth();

  const count = useCallback(
    async (filter: any): Promise<number> => {
      const res = await modelService.count(authState, modelName, filter);
      return res.data;
    },
    [modelName, authState]
  );

  const search = useCallback(
    async (filter: any): Promise<T[]> => {
      const res = await modelService.search<T>(authState, modelName, filter);
      return res.data;
    },
    [modelName, authState]
  );

  const get = useCallback(
    async (id: string): Promise<T> => {
      const res = await modelService.get<T>(authState, modelName, id);
      return res.data;
    },
    [modelName, authState]
  );

  const getShareToken = useCallback(
    async (id: string): Promise<string> => {
      const res = await modelService.getShareToken(authState, modelName, id);
      return res.token;
    },
    [modelName, authState]
  );

  const uploadFile = useCallback(
    async (
      id: string,
      fileField: string,
      file: File,
      payload: any = {}
    ): Promise<boolean> => {
      const res = await modelService.uploadFile(
        authState,
        modelName,
        id,
        fileField,
        file,
        payload
      );
      return res.success;
    },
    [modelName, authState]
  );

  const downloadFile = useCallback(
    async (id: string, fileField: string): Promise<FileData> => {
      const res = await modelService.downloadFile(
        authState,
        modelName,
        id,
        fileField
      );
      return res;
    },
    [modelName, authState]
  );

  const getTotals = useCallback(
    async (filter: any): Promise<any> => {
      const res = await modelService.fetchTotals(authState, modelName, filter);
      return res;
    },
    [modelName, authState]
  );

  return {
    search,
    get,
    count,
    getShareToken,
    uploadFile,
    downloadFile,
    getTotals,
  };
};

export default useModel;
