/** @jsxImportSource @emotion/react */

import Button from "../../../../components/Button";
import CustomLink from "../../../../components/CustomLink";
import { IconNames } from "../../../../components/Icons/styles/iconNames";
import { useAuth } from "../../../../hooks/useAuth";
import { ToSimulationLocation } from "../../../../Locations";

const styles = {
  buttons: {
    "& > button": {
      marginBottom: 12,
    },
  },
};

const SidebarTopButtons = () => {
  const { user } = useAuth();
  const handleShareProviderLink = () => {
    const { protocol, host } = window.location;
    const shareLink = `${protocol}//${host}/${user.username}`;
    navigator.clipboard.writeText(shareLink);
    alert(`Link copiado: ${shareLink}`);
  };

  return (
    <div css={styles.buttons}>
      <CustomLink to={ToSimulationLocation("new", "simulacao")}>
        <Button text="Nova Cotação" iconName={IconNames.Add} align="left" />
      </CustomLink>
      <Button
        text="Link"
        iconName={IconNames.Share}
        align="left"
        onClick={handleShareProviderLink}
      />
    </div>
  );
};

export default SidebarTopButtons;
