import { useEffect, useMemo, useState } from "react";
import { TModelFilter } from "../../../hooks/useModelFilter";
import { NestedCounter } from "../components/Cards/components/StatusIconsList";

interface useDashboardCountersProps {
  filter: TModelFilter;
  groupFilter: [string, string];
  buttonFilters: [string, string[]];
  location: string;
}

const useDashboardCounters = ({
  filter,
  groupFilter,
  location,
  buttonFilters: [nestedFilterName, nestedFilterValues],
}: useDashboardCountersProps): [NestedCounter, NestedCounter[], boolean] => {
  const [groupCount, setGroupCount] = useState<number | undefined>(undefined);

  const groupFilterAndOption = useMemo(
    () => filter.getFilterAndOption(groupFilter[0], groupFilter[1]),
    [groupFilter[0], groupFilter[1], filter]
  );

  const groupRedirectLocation = useMemo(() => {
    const urlQuery = new URLSearchParams({});
    urlQuery.append(
      groupFilterAndOption.filter.urlParam,
      groupFilterAndOption.option.value
    );
    return `${location}?${urlQuery.toString()}`;
  }, [groupFilterAndOption, location]);

  const [nestedCounters, setNestedCounters] = useState<
    (NestedCounter & { loading: boolean })[]
  >(() => {
    return nestedFilterValues.map((buttonFilterValue) => {
      const res = filter.getFilterAndOption(
        nestedFilterName,
        buttonFilterValue
      );
      const urlQuery = new URLSearchParams({});
      urlQuery.append(
        groupFilterAndOption.filter.urlParam,
        groupFilterAndOption.option.value
      );
      urlQuery.append(res.filter.urlParam, res.option.value);
      return {
        ...res,
        redirectLocation: `${location}?${urlQuery.toString()}`,
        count: 0,
        loading: true,
      };
    });
  });

  useEffect(() => {
    setGroupCount(nestedCounters.reduce((acc, curr) => acc + curr.count, 0));
  }, [...nestedCounters]);

  useEffect(() => {
    nestedFilterValues.forEach((buttonFilterValue) => {
      filter
        .getCount([groupFilter, [nestedFilterName, buttonFilterValue]] as [
          string,
          string
        ][])
        .then((count) => {
          setNestedCounters((prevButtons) =>
            prevButtons.map((button) => {
              if (button.option.value === buttonFilterValue) {
                return {
                  ...button,
                  count,
                  loading: false,
                };
              }
              return button;
            })
          );
        });
    });
  }, [...nestedCounters.map((b) => b.option.value)]);

  const loading = useMemo(() => {
    return nestedCounters.some((b) => b.loading);
  }, [nestedCounters]);

  return [
    {
      ...groupFilterAndOption,
      count: groupCount || 0,
      redirectLocation: groupRedirectLocation,
    },
    nestedCounters,
    loading,
  ];
};

export default useDashboardCounters;
