/** @jsxImportSource @emotion/react */

import { useEffect, useMemo, useState } from "react";
import DetailsSection from "../../../../../components/Details/components/Main/Section/DetailsSection";
import TextAreaInput from "../../../../../components/Input/TextAreaInput";
import { usePartnerDetails } from "../../hooks/usePartnerDetails";
import useDebounce from "../../../../../hooks/useDebounce";
import { formatISODate } from "../../../../../utils/dateUtils";
import DetailsSectionColumns from "../../../../../components/Details/components/Main/Grid/DetailsSectionColumns";
import UserSelect from "../../../../../components/Details/components/Main/UserSelect";
import { ColorNames } from "../../../../../theme";
import Typography from "../../../../../components/Typography";
import Icon from "../../../../../components/Icons";
import { getStatusIcon } from "../../../../../filters/users";
import PartnerData from "./PartnerData";
import { DetailsRowValueVariants } from "../../../../../components/Details/components/Main/Grid/DetailsRowValue";
import { DetailsRowProps } from "../../../../../components/Details/components/Main/Grid/DetailsRow";
import { IconNames } from "../../../../../components/Icons/styles/iconNames";
import { SectionFooterButton } from "../../../../Documents/DocumentDetails/components/SectionFooter";
import {
  UserStatus,
  UserRoles,
} from "../../../../../graphql/__GENERATED__/types";
import RatingStars from "../../../../../components/RatingStars";
import MultiSelectInput from "../../../../../components/Input/SelectInputs/MultiSelectInput";
import useCultureOptions from "../../../../../hooks/useCultureOptions";
import BooleanInput from "../../../../../components/Input/BooleanInput";

const UserStatusLabels = {
  [UserStatus.Registered]: "Cadastrado",
  [UserStatus.Approved]: "Aprovado",
  [UserStatus.Rejected]: "Recusado",
  [UserStatus.Active]: "Ativo",
};

const UserRolesLabels = {
  [UserRoles.Admin]: "Admin",
  [UserRoles.Consultant]: "Consultor",
  [UserRoles.Cooperative]: "Cooperativa",
  [UserRoles.Organization]: "Organização",
  [UserRoles.Resale]: "Revenda",
};

const ContactSummary = () => {
  const {
    data,
    handleAddComment,
    handleAssignPartnerResponsible,
    handleAddRating,
    handleApprovePartner,
    handleRejectPartner,
    handleDisablePartner,
    handleEnablePartner,
    handleAddCultureToPartner,
    handleAddCourse,
  } = usePartnerDetails();
  const cultures = useCultureOptions();
  const [comment, setComment] = useState<string>(data?.comments || "");
  const { debouncedValue, cancelDebounce } = useDebounce(comment, 1000);

  const handleComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
  };

  const handleAddCulture = (value: string[]) => {
    handleAddCultureToPartner(value);
  };

  useEffect(() => {
    if (debouncedValue !== data?.comments) {
      handleAddComment(debouncedValue);
    }
  }, [debouncedValue]);

  const handleBlur = () => {
    cancelDebounce(), handleAddComment(comment);
  };

  const partnerInfo = useMemo<DetailsRowProps[]>(() => {
    return [
      {
        label: "Papel // Status",
        value: (
          <Typography
            variant="textMedium"
            component="div"
            css={{ display: "flex", alignItems: "center", gap: 4 }}
          >
            <Icon
              name={
                data.disabled
                  ? IconNames.Archive
                  : getStatusIcon(data.status as UserStatus)!
              }
              color={data.disabled ? ColorNames.ERROR_RED : ColorNames.GREEN}
            />
            {data.disabled
              ? "Desabilitado"
              : `${UserRolesLabels[data?.role! as UserRoles]} ${
                  UserStatusLabels[data?.status! as UserStatus]
                }`}
          </Typography>
        ),
      },
      {
        label: "Parceiro",
        value: { text: data.name!, Component: PartnerData },
        variant: DetailsRowValueVariants.Modal,
      },
      data.signedDate
        ? {
            label: "Assinatura",
            value: formatISODate(data?.signedDate),
          }
        : {
            label: "Cadastro",
            value: formatISODate(data?.createdAt!),
          },
      {
        label: "Culturas Relacionadas",
        value: (
          <MultiSelectInput
            placeholder="Culturas do parceiro"
            inputName="culture"
            onSelect={(value) => handleAddCulture(value)}
            value={data.cultureIds}
            options={cultures.cultureOptions}
            multiValueDisplay="all"
          />
        ),
      },
    ];
  }, [data, cultures.cultureOptions]);
  const partnerEvaluation = useMemo(() => {
    return [
      {
        label: "Responsável",
        value: (
          <UserSelect
            domain={{ role: UserRoles.Admin }}
            value={data?.responsible?._id || ""}
            placeholder=""
            onSelect={(option) => handleAssignPartnerResponsible(option.value)}
            inputName="responsavel-parceiro"
          />
        ),
      },
      {
        label: "Avaliação de potencial",
        value: (
          <RatingStars
            rating={data.rating!}
            onClick={(option) => handleAddRating(option)}
            color={ColorNames.GREEN}
          />
        ),
      },
      {
        label: "Usuário fez o curso?",
        value: (
          <BooleanInput
            name="finishedCourse"
            label=""
            value={data.finishedCourse!}
            handleChange={(e) => handleAddCourse(e.target.value)}
          />
        ),
      },
      {
        label: "Comentários",
        value: (
          <TextAreaInput
            value={comment}
            onChange={handleComment}
            onBlur={handleBlur}
          />
        ),
      },
    ];
  }, [comment, data]);

  const approveAndRejectButtons = useMemo(() => {
    if (data.status !== UserStatus.Registered) return [];
    return [
      {
        text: "Rejeitar",
        iconName: IconNames.Rejected,
        onClick: () => handleRejectPartner(),
      },
      {
        text: "Aprovar",
        iconName: IconNames.Valid,
        onClick: () => handleApprovePartner(),
      },
    ];
  }, [data.status]);

  const footerButtons = useMemo(() => {
    return [
      !data.disabled && {
        text: "Desabilitar",
        iconName: IconNames.Archive,
        onClick: () => handleDisablePartner(),
      },
      data.disabled && {
        text: "Reabilitar",
        iconName: IconNames.Unarchive,
        onClick: () => handleEnablePartner(),
      },
      ...approveAndRejectButtons,
    ].filter(Boolean) as SectionFooterButton[];
  }, [data.status, data.disabled]);

  return (
    <DetailsSection title="Detalhes" noTitle main footerButtons={footerButtons}>
      <DetailsSectionColumns columns={[partnerInfo, partnerEvaluation]} />
    </DetailsSection>
  );
};

export default ContactSummary;
