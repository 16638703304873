/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { DashboardCardFC, TDashboardCard } from "./DashboardCard";

interface DashboardCardListProps {
  cards: TDashboardCard[];
  CardComponent: DashboardCardFC<any>;
}

const DashboardCardList: FC<DashboardCardListProps> = ({
  cards,
  CardComponent,
}) => {
  return (
    <>
      <div
        css={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {cards.map((card, i) => (
          <div
            key={`dashboard-card-${i}`}
            css={{ height: `${100 / cards.length - 1}%` }}
          >
            <CardComponent category={card.category} />
          </div>
        ))}
      </div>
    </>
  );
};

export default DashboardCardList;
