//public site
export const PublicSiteLocation =
  process.env.REACT_APP_PUBLIC_SITE_BASE_URL || "https://www.prismaagro.com.br";
export const PublicSiteAboutUsLocation = `${PublicSiteLocation}/#quem-somos`;
export const PublicSiteProductLocation = `${PublicSiteLocation}/#como-funciona`;

//main pages
export const HomeLocation = "/";
export const LoginLocation = "/login";
export const ToLoginLocationWithRedirect = (redirectPath: string) =>
  `/login?redirect=${redirectPath}`;
export const RegisterLocation = "/cadastro";
export const PendingRegisterLocation = "/cadastro/pendente";
export const TermsAndConditionsLocation = "/termos-e-condicoes";
export const SharedFormLocation = "/:username";

//dashboards
export const DocumentsDashboardLocation = "/seguros";
export const PartnersDashboardLocation = "/parceiros";
export const CoorporativeDashboardLocation = "/corporativo";

//documents
export const DocumentsLocation = "/seguros/documentos";
export const DocumentDetailsLocation = "/seguros/documentos/:docId";
export const ToDocumentDetailsLocation = (docId: string) =>
  `/seguros/documentos/${docId}`;
export const DocumentDetailsTabLocation = "/seguros/documentos/:docId/:tabId";
export const ToDocumentDetailsTabLocation = (docId: string, tabId: string) =>
  `/seguros/documentos/${docId}/${tabId}`;
export const DocumentDetailsNotesTabLocation =
  "/seguros/documentos/:docId/notas";
export const DocumentDetailsChatTabLocation = "/seguros/documentos/:docId/chat";

//direct documents
export const DirectDocumentDetailsLocation = "/documentos/:docId";
export const ToDirectDocumentDetailsLocation = (docId: string) =>
  `/documentos/${docId}`;

// Complementary Data
export const ComplementaryDataFormLocation =
  "/dados-complementares/:formResponseId";
export const toComplementaryDataFormLocation = (
  formResponseId: string,
  token: string
) => `/dados-complementares/${formResponseId}?token=${token}`;

// Nfe Upload
export const NfeUploadFormLocation = "/notas-fiscais/:formResponseId";
export const toNfeUploadFormLocation = (
  formResponseId: string,
  token: string
) => `/notas-fiscais/${formResponseId}?token=${token}`;

// KML upload
export const KmlUploadFormLocation = "/kml-upload/:formResponseId";
export const toKmlUploadFormLocation = (
  formResponseId: string,
  token: string
) => `/kml-upload/${formResponseId}?token=${token}`;

//simulations
export const SimulationsLocation = "/seguros/simulacoes";
export const SimulationLocation = "/seguros/documentos/form/:formId/:docId";
export const ToSimulationLocation = (docId: string, formId: string) =>
  `/seguros/documentos/form/${formId}/${docId}`;
export const SimulationRejectionLocation = "/seguros/simulacoes/:docId/reject";
export const ToSimulationRejectionLocation = (docId: string) =>
  `/seguros/simulacoes/${docId}/reject`;
export const SimulationManualLocation =
  "/seguros/simulacoes/preencher/:docId/:resultIndex";
export const ToSimulationManualLocation = (
  docId: string,
  resultIndex?: string
) => `/seguros/simulacoes/preencher/${docId}/${resultIndex || "new"}`;

//direct simulations
export const DirectSimulationsLocation = "/simulacoes";
export const DirectSimulationLocation = "/simulacoes/form/:formId/:docId";
export const ToDirectSimulationLocation = (docId: string, formId: string) =>
  `/simulacoes/form/${formId}/${docId}`;

//direct quotations
export const DirectQuotationsLocation = "/cotacoes";
export const DirectQuotationFormLocation = "/cotacoes/form/:formId/:docId";
export const ToDirectQuotationFormLocation = (docId: string) =>
  `/cotacoes/form/cotacao/${docId}`;

//partners
export const ConsultantsLocation = "/parceiros/consultores";
export const ConsultantDetailsLocation = "/parceiros/consultores/:docId";
export const ToConsultantDetailsLocation = (docId: string) =>
  `/parceiros/consultores/${docId}`;
export const ConsultantRejectionLocation =
  "/parceiros/consultores/:docId/reject";
export const ToConsultantRejectionLocation = (docId: string) =>
  `/parceiros/consultores/${docId}/reject`;
export const ConsultantDetailsNotesTabLocation =
  "/parceiros/consultores/:docId/notas";
export const ConsultantDetailsChatTabLocation =
  "/parceiros/consultores/:docId/chat";

// leads
export const LeadsLocation = "/corporativo/leads";
export const LeadDetailsLocation = "/corporativo/leads/:docId";
export const ToLeadDetailsLocation = (docId: string) =>
  `/corporativo/leads/${docId}`;
export const LeadFormLocation = "/corporativo/leads/form/:docId";
export const LeadDetailsNotesTabLocation = "/corporativo/leads/:docId/notas";
export const LeadDetailsChatTabLocation = "/corporativo/leads/:docId/chat";
export const ToLeadFormLocation = (docId: string) =>
  `/corporativo/leads/form/${docId}`;

// Configurações
export const SwissSettingsLocation = "/configuracoes/swiss";

// Contacts
export const ContactsLocation = "/contatos";
export const ContactDetailsLocation = "/contatos/:contactId";
export const ContactDetailsChatLocation = "/contatos/:contactId/chat";
export const ContactDetailsNotesLocation = "/contatos/:contactId/notas";
export const ToContactDetailsLocation = (contactId: string) =>
  ContactDetailsLocation.replace(":contactId", contactId);

// conversations
export const ContactConversationLocation = "/contatos/:contactId/conversa";
export const ToContactConversationLocation = (contactId: string) =>
  ContactConversationLocation.replace(":contactId", contactId);
