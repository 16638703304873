/** @jsxImportSource @emotion/react */

import { FC, useMemo } from "react";
import useDocumentDetails from "../../../../hooks/useDocumentDetails";
import EditableSection from "../../../../components/EditableSection";
import {
  DocumentStages,
  GetEditFarmerOnDocumentFormResponseDocument,
  GetEditFarmerOnDocumentFormResponseQueryVariables,
} from "../../../../graphql/__GENERATED__/types";
import useDocumentOrder from "../../../../hooks/useDocumentOrder";
import ContactData from "../../../../components/Details/ContactData";
import { ToContactDetailsLocation } from "../../../../Locations";
import { IconNames } from "../../../../components/Icons/styles/iconNames";
import { useFlags } from "../../../../hooks/useFlags";

const FarmerData: FC = () => {
  const {
    documentData: { farmer: farmerData, _id: documentId, stage },
    refetch,
  } = useDocumentDetails();

  const { _id: farmerId } = farmerData!;

  const currentStage = useDocumentOrder(stage!);
  const policyStage = useDocumentOrder(DocumentStages.Policy);

  const editQuery = useMemo(
    () =>
      currentStage < policyStage
        ? GetEditFarmerOnDocumentFormResponseDocument
        : undefined,
    [currentStage, policyStage]
  );

  const { isFlagEnabled } = useFlags();

  return (
    <EditableSection<{}, GetEditFarmerOnDocumentFormResponseQueryVariables>
      title="Dados do Produtor"
      footerButtons={
        isFlagEnabled("admin-only")
          ? [
              {
                text: "Página do produtor",
                link: ToContactDetailsLocation(farmerId!),
                iconName: IconNames.Share,
              },
            ]
          : []
      }
      createPayload={{}}
      editQuery={editQuery}
      editPayload={{ documentId: documentId!, objectId: farmerId! }}
      objectId={farmerId}
      onSubmit={refetch}
      main
    >
      <ContactData contact={farmerData!} />
    </EditableSection>
  );
};

export default FarmerData;
