/** @jsxImportSource @emotion/react */

import SkeletonAnimationContainer from "../../../../../components/Skeletons/components/SkeletonAnimationContainer";
import SkeletonCardContainer from "../../../../../components/Skeletons/components/SkeletonContainer";
import SkeletonTypography from "../../../../../components/Skeletons/components/SkeletonTypography";

const SkeletonDashboardCard = () => {
  return (
    <div css={{ position: "relative", height: "100%" }}>
      <SkeletonAnimationContainer />
      <SkeletonCardContainer>
        <SkeletonTypography variant="textLarge" />
      </SkeletonCardContainer>
    </div>
  );
};

export default SkeletonDashboardCard;
