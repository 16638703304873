import DashboardCard, { DashboardCardFC } from "../DashboardCard";
import { ConsultantsLocation } from "../../../../../Locations";
import { contactCards } from "../assets/dashboardCards";
import { useMemo } from "react";
import { UserRoles } from "../../../../../graphql/__GENERATED__/types";
import useContactColor from "../../../../../hooks/useContactColor";
import useUserFilter from "../../../../../hooks/useUsersFilter";

const ContactDashboardCard: DashboardCardFC<UserRoles> = ({ category }) => {
  const cardColor = useContactColor(category);
  const filter = useUserFilter();
  const cardData = useMemo(() => {
    return contactCards.find((card) => card.category === category);
  }, [category]);

  if (!cardData) return null;

  return (
    <DashboardCard
      cardData={cardData}
      color={cardColor}
      filter={filter}
      location={ConsultantsLocation}
    />
  );
};

export default ContactDashboardCard;
