/** @jsxImportSource @emotion/react */

import { ColorNames, theme } from "../../../../theme";
import Typography from "../../../../components/Typography";
import Row from "../../../../components/Grid/Row";
import { FC } from "react";
import Column from "../../../../components/Grid/Column";
import BoxContainer from "../BoxContainer";
import SkeletonFunnelCards from "./SkeletonFunnelCards";
import ButtonGroup from "../../../../components/ButtonGroup";
import FunnelCard from "./components/FunnelCard";
import { IconNames } from "../../../../components/Icons/styles/iconNames";
import useDashboard from "../../../../hooks/useDashboard";
import { DashboardFunnelDateRange } from "../../../../graphql/__GENERATED__/types";

export type TFunnelData = {
  label: string;
  value: number;
  variation: number;
  color: ColorNames;
  iconName: IconNames;
};

export interface FunnelCardsProps {
  data: TFunnelData[];
  loading: boolean;
}

const dateRangeLabels = {
  [DashboardFunnelDateRange.Week]: "Semana",
  [DashboardFunnelDateRange.Month]: "Mês",
  [DashboardFunnelDateRange.Year]: "Ano",
};

const FunnelCards: FC<FunnelCardsProps> = ({ data, loading }) => {
  const { funnelDateRange, handleChangeFunnelDateRange } = useDashboard();

  const buttons = Object.keys(dateRangeLabels).map((key) => ({
    label: dateRangeLabels[key as keyof typeof dateRangeLabels],
    onClick: async () =>
      handleChangeFunnelDateRange(key as keyof typeof dateRangeLabels),
  }));

  return (
    <BoxContainer backgroundColor={theme.colors.Grey[20]}>
      <Row align="center" justify="between">
        <Column>
          <Typography
            uppercase
            variant="textExtraLarge"
            weight="bold"
            component="div"
            align="left"
          >
            Resumo{" "}
            {`${
              funnelDateRange === DashboardFunnelDateRange.Week ? "da" : "do"
            }`}{" "}
            {dateRangeLabels[funnelDateRange]}
          </Typography>
        </Column>
        <Column xs={6} lg={4}>
          <ButtonGroup buttons={buttons} defaultActiveIndex={1} />
        </Column>
      </Row>
      {loading ? (
        <SkeletonFunnelCards />
      ) : (
        <Row noMargins>
          {data.map((item, index) => (
            <Column xs={12} lg={6} key={`prize-card-${index}`} useYPaddings>
              <FunnelCard
                label={item.label}
                value={item.value}
                iconName={item.iconName}
                color={item.color}
              />
            </Column>
          ))}
        </Row>
      )}
    </BoxContainer>
  );
};

export default FunnelCards;
