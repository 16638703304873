/** @jsxImportSource @emotion/react */

import { FC, PropsWithChildren, ReactNode, useEffect, useState } from "react";
import DetailsSectionTitle from "./DetailsSectionTitle";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../../../hooks/useCSSRulesWithTheme";
import { ScreenClass, useScreenClass } from "react-grid-system";
import { TOverflowMenuOptions } from "../../../../OverflowMenu";
import SectionFooter, {
  SectionFooterButton,
} from "../../../../../views/Documents/DocumentDetails/components/SectionFooter";
import Row from "../../../../Grid/Row";
import ScrollContainer from "../../../../ScrollContainer";

export interface DetailsSectionProps extends PropsWithChildren {
  title: string;
  noTitle?: boolean;
  subtitle?: boolean;
  button?: ReactNode;
  sectionWrap?: boolean;
  sectionOpen?: boolean;
  disabled?: boolean;
  alwaysOpen?: boolean;
  sectionPending?: boolean;
  options?: TOverflowMenuOptions[];
  footerButtons?: SectionFooterButton[];
  main?: boolean;
}

const getStyles: CSSRulesResolver<{
  screenClass: ScreenClass;
  props: DetailsSectionProps;
}> = ({ screenClass, props }) => ({
  section: props.main
    ? {
        width: "100%",
        height: "100%",
        margin: "0",
        boxSizing: "border-box",
      }
    : { margin: "0" },
  content: {
    padding: screenClass !== "xs" && !props.subtitle ? "0 1.5em" : undefined,
    margin: "3.5em 0",
  },
});

const DetailsSection: FC<DetailsSectionProps> = (props) => {
  const screenClass = useScreenClass();
  const [showSectionContent, setShowSectionContent] = useState(false);
  const toggleSectionContent = () => {
    setShowSectionContent(!showSectionContent);
  };

  const { alwaysOpen, sectionOpen, subtitle, sectionWrap, noTitle } = props;

  useEffect(() => {
    if (alwaysOpen || sectionOpen || subtitle || !sectionWrap)
      setShowSectionContent(true);
    else setShowSectionContent(false);
  }, [alwaysOpen, sectionOpen, subtitle, sectionWrap]);

  const styles = useCSSRulesWithTheme(getStyles, { screenClass, props });
  let titleComponent;
  if (!noTitle) {
    if (props.title !== undefined) {
      const {
        title,
        subtitle,
        button,
        sectionWrap,
        disabled,
        alwaysOpen,
        sectionPending,
        options,
      } = props;
      titleComponent = (
        <DetailsSectionTitle
          title={title}
          subtitle={subtitle}
          button={button}
          sectionWrap={sectionWrap}
          disabled={disabled}
          showSectionContent={showSectionContent}
          onClick={toggleSectionContent}
          alwaysOpen={alwaysOpen}
          sectionPending={sectionPending}
          options={options}
        />
      );
    }
  }
  return (
    <Row noWrap direction="column" justify="between" css={styles.section}>
      <ScrollContainer>
        {titleComponent}
        <div css={styles.content}>{showSectionContent && props.children}</div>
      </ScrollContainer>
      {props.footerButtons && props.footerButtons?.length ? (
        <SectionFooter buttons={props.footerButtons || []} />
      ) : null}
    </Row>
  );
};

export default DetailsSection;
