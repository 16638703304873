import { Outlet, useNavigate } from "react-router-dom";
import CardList from "../../components/CardList";
import { useUIStore } from "../../hooks/useUIStore";
import MainFrame from "../../components/MainFrame";
import PartnerCard from "./PartnerCard";
import { useListUsersQuery } from "../../graphql/__GENERATED__/types";
import useUserFilter from "../../hooks/useUsersFilter";
import {
  ConsultantsLocation,
  ToConsultantDetailsLocation,
} from "../../Locations";
import Layout from "../../components/Layout";

const Consultants = () => {
  const navigate = useNavigate();
  const { toggleDrawerSize } = useUIStore();

  const userFilters = useUserFilter();

  const { data, loading } = useListUsersQuery({
    variables: {
      filter: userFilters.composeQuery(),
    },
  });

  return (
    <Layout>
      <MainFrame
        drawerContent={<Outlet />}
        onClose={() => navigate(ConsultantsLocation)}
        onToggle={toggleDrawerSize}
        filter={userFilters}
      >
        <CardList
          data={data?.users || []}
          redirectLocation={ToConsultantDetailsLocation}
          CardComponent={PartnerCard}
          loading={loading}
        />
      </MainFrame>
    </Layout>
  );
};

export default Consultants;
