import ContactData from "../../../../components/Details/ContactData";
import EditableSection from "../../../../components/EditableSection";
import {
  GetEditContactOnFormResponseQueryVariables,
  GetEditContactOnFormResponseDocument,
  ContactTags,
} from "../../../../graphql/__GENERATED__/types";
import useContactDetails from "../../../../hooks/useContactDetails";

const ContactSummary = () => {
  const { contactData, refetch } = useContactDetails();
  return (
    <EditableSection<{}, GetEditContactOnFormResponseQueryVariables>
      title="Detalhes"
      createQuery={undefined}
      editQuery={
        contactData.tags?.includes(ContactTags.Farmer)
          ? GetEditContactOnFormResponseDocument
          : undefined
      }
      footerButtons={[]}
      createPayload={{}}
      editPayload={{ objectId: contactData._id! }}
      objectId={contactData._id}
      onSubmit={refetch}
      main
    >
      <ContactData contact={contactData!} />
    </EditableSection>
  );
};

export default ContactSummary;
