/** @jsxImportSource @emotion/react */

import { FC } from "react";
import Button from "../../../../components/Button";
import { IconNames } from "../../../../components/Icons/styles/iconNames";

const styles = {
  actions: {
    display: "flex",
    width: "100%",
    gap: 12,
    justifyContent: "end",
    marginBottom: 12,
  },
};

export interface DashboardAction {
  text: string;
  iconName: IconNames;
  onClick: () => void;
}

interface DashboardActionsProps {
  actions: DashboardAction[];
}

const DashboardActions: FC<DashboardActionsProps> = ({ actions }) => {
  return (
    <div css={styles.actions}>
      {actions.map((action, index) => (
        <Button
          key={index}
          style="outlinePrimary"
          text={action.text}
          iconName={action.iconName}
          width="auto"
          onClick={action.onClick}
        />
      ))}
    </div>
  );
};

export default DashboardActions;
