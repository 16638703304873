/** @jsxImportSource @emotion/react */

import * as React from "react";
import { ColorNames, theme } from "../../../../../theme";
import Tooltip from "../../../../../components/Tooltip";
import Typography from "../../../../../components/Typography";
import { TFilter, TFilterOption } from "../../../../../filters/types";
import Icon from "../../../../../components/Icons";
import { IconSizes } from "../../../../../components/Icons/styles/iconSizes";
import CustomLink from "../../../../../components/CustomLink";

export type NestedCounter = {
  option: TFilterOption;
  count: number;
  filter: TFilter;
  redirectLocation: string;
};

interface NestedCountersProps {
  nestedCounters: NestedCounter[];
  hoverColor: ColorNames;
  stage: string;
}

const styles = {
  iconsList: {
    display: "flex",
    justifyContent: "start",
    alignItems: "end",
    flexWrap: "wrap" as "wrap",
  },
  icon: {
    paddingRight: 30,
  },
  counterContainer: {
    position: "relative" as "relative",
    top: 10,
    left: 30,
    zIndex: 10,
  },
  counter: {
    borderRadius: 50,
    borderColor: theme.colors.ErrorRed[100],
    backgroundColor: theme.colors.White[100],
    border: "1px solid",
    padding: "0px 4px",
  },
};

const StatusIconsList: React.FC<NestedCountersProps> = ({
  hoverColor,
  nestedCounters,
  stage,
}) => {
  return (
    <div css={styles.iconsList}>
      {nestedCounters.map(
        (
          { count, option: { label, value, iconName }, redirectLocation },
          i
        ) => (
          <CustomLink to={redirectLocation} key={`status-icon-${label}`}>
            <div css={styles.icon} key={`statusIcon-${i}`}>
              <div css={styles.counterContainer}>
                <Typography
                  variant="textSmall"
                  weight="bold"
                  color={count ? ColorNames.RED : ColorNames.GREY}
                  css={styles.counter}
                >
                  {count}
                </Typography>
              </div>
              <Tooltip id={`tooltip-id-${stage}-${value}`} text={label}>
                <Icon
                  name={iconName!}
                  size={IconSizes.Large}
                  color={ColorNames.BLACK}
                  hoverColor={hoverColor}
                  disabled={!count}
                />
              </Tooltip>
            </div>
          </CustomLink>
        )
      )}
    </div>
  );
};

export default StatusIconsList;
