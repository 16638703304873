import { IconNames } from "../../../../../../components/Icons/styles/iconNames";
import {
  DocumentStages,
  DocumentStatus,
} from "../../../../../../graphql/__GENERATED__/types";
import useConfirmedAction from "../../../../../../hooks/useConfirmedAction";
import useDocumentDetails from "../../../../../../hooks/useDocumentDetails";
import { useUIStore } from "../../../../../../hooks/useUIStore";
import { SectionFooterButton } from "../../../components/SectionFooter";
import { useMemo } from "react";

const useFooterButtons = () => {
  const {
    documentData: { proposalResult, status, stage, archived },
    copyQuotationFormUrl,
    handleArchiveProposal,
    handleSendProposal,
  } = useDocumentDetails();

  const { openSubmittedModal, closeSubmittedModal } = useUIStore();

  const showSentProposalMessage = () => {
    openSubmittedModal("Proposta enviada com sucesso!");
    const interval = setTimeout(() => {
      closeSubmittedModal();
    }, 3000);
    return () => clearInterval(interval);
  };

  const handleSendProposalEmail = async () => {
    await handleSendProposal();
    showSentProposalMessage();
  };

  const handleSetDocumentAsSent = useConfirmedAction(
    "Tem certeza? O email não será enviado.",
    async () => {
      await handleSendProposal(true);
    }
  );

  const footerButtons = useMemo<SectionFooterButton[]>(() => {
    if (stage !== DocumentStages.Proposal) return [];
    return [
      status === DocumentStatus.Incomplete && {
        text: "Formulário Completo",
        iconName: IconNames.Share,
        onClick: copyQuotationFormUrl,
      },
      status === DocumentStatus.Done && {
        text: "Enviar",
        onClick: handleSendProposalEmail,
        iconName: IconNames.Sent,
        flag: "admin-only",
      },
      status === DocumentStatus.Done && {
        text: "Marcar como enviada",
        onClick: handleSetDocumentAsSent,
        iconName: IconNames.Sent,
        flag: "admin-only",
      },
      {
        text: archived ? "Desarquivar" : "Arquivar",
        onClick: handleArchiveProposal,
        iconName: archived ? IconNames.Unarchive : IconNames.Archive,
        flag: "admin-only",
      },
    ].filter(Boolean) as unknown as SectionFooterButton[];
  }, [proposalResult, stage, archived, status]);

  return footerButtons;
};

export default useFooterButtons;
