import React, { FC, ReactNode, useState } from "react";
import { useErrorHandler } from "../hooks/useErrorHandler";
import { SimulationsObject } from "../models/Simulation";
import {
  getSimulationsByRequest,
  getSimulationById,
  retrySimulationById,
  approveSimulationById,
} from "../services/simulations";
import { useUIStore } from "../hooks/useUIStore";
import { AuthContextInterface } from "./auth";
import { useNavigate } from "react-router-dom";
import { ToSimulationLocation } from "../Locations";
import { useAuth } from "../hooks/useAuth";
import {
  getQuotationById,
  getQuotationsByRequest,
} from "../services/quotations";
import { QuotationsObject } from "../models/Quotation";
import { ProposalsObject } from "../models/Proposals";
import { getProposalById, getProposalsByRequest } from "../services/proposals";

export interface QuotationsState {
  hideRejectedQuotations: boolean;
  showNewQuotationCard: boolean;
  [key: string]: any;
}

export const initialState: QuotationsState = {
  hideRejectedQuotations: true,
  showNewQuotationCard: false,
};

export interface QuotationsStore {
  simulationData?: SimulationsObject;
  quotationData?: QuotationsObject;
  simulationsData: Array<SimulationsObject>;
  quotationsData: Array<QuotationsObject>;
  proposalsData: Array<ProposalsObject>;
  proposalData?: ProposalsObject;
  hideRejectedQuotations: boolean;
  showNewQuotationsCard: boolean;
  fetchSimulationsData: (auth: AuthContextInterface, status: string) => void;
  fetchQuotationsData: (auth: AuthContextInterface, status: string) => void;
  fetchProposalsData: (auth: AuthContextInterface, status: string) => void;
  fetchProposalDetails: (docId: string) => void;
  fetchSimulationDetails: (docId: string) => void;
  fetchQuotationDetails: (docId: string) => Promise<void>;
  retrySimulationCalculation: () => void;
  reloadSimulationData: () => void;
  reloadQuotationData: () => void;
  reloadProposalData: () => Promise<void>;
  toggleRejectedQuotations: () => void;
  searchQuotations: (search: string) => void;
  clearSearchQuotations: () => void;
  clearQuotationsData: () => void;
  createNewQuotation: () => void;
  shareProviderLink: () => void;
  approveSimulation: (id: string, resultIndex: string) => Promise<string>;
}

const QuotationsContext = React.createContext<QuotationsStore>({
  simulationData: undefined,
  quotationData: undefined,
  proposalsData: [],
  proposalData: undefined,
  simulationsData: [],
  quotationsData: [],
  hideRejectedQuotations: initialState.hideRejectedQuotations,
  showNewQuotationsCard: initialState.showNewQuotationCard,
  fetchSimulationsData: () => null,
  fetchQuotationsData: () => null,
  fetchProposalsData: () => null,
  fetchProposalDetails: () => null,
  fetchSimulationDetails: () => null,
  fetchQuotationDetails: async () => {},
  retrySimulationCalculation: () => null,
  reloadSimulationData: () => null,
  reloadQuotationData: () => null,
  reloadProposalData: async () => {},
  toggleRejectedQuotations: () => null,
  searchQuotations: () => null,
  clearSearchQuotations: () => null,
  clearQuotationsData: () => null,
  createNewQuotation: () => null,
  shareProviderLink: () => null,
  approveSimulation: async () => "",
});

interface QuotationsContextProviderProps {
  children: ReactNode;
}

export const QuotationsContextProvider: FC<QuotationsContextProviderProps> = ({
  children,
}) => {
  const [simulationsData, setSimulationsData] = useState<
    Array<SimulationsObject>
  >([]);
  const [quotationsData, setQuotationsData] = useState<Array<QuotationsObject>>(
    []
  );
  const [proposalsData, setProposalsData] = useState<Array<ProposalsObject>>(
    []
  );
  const [simulationData, setSimulationData] = useState<SimulationsObject>();
  const [quotationData, setQuotationData] = useState<QuotationsObject>();
  const [proposalData, setProposalData] = useState<ProposalsObject>();

  const { errorHandler } = useErrorHandler();

  const { setIsLoading } = useUIStore();
  const authState = useAuth();

  const navigate = useNavigate();

  const { user } = useAuth();

  const [hideRejectedQuotations, setHideRejectedQuotations] = useState(
    initialState.hideRejectedQuotations
  );
  const toggleRejectedQuotations = () =>
    setHideRejectedQuotations(
      (hideRejectedQuotations) => !hideRejectedQuotations
    );

  const [quotationsSearch, setQuotationsSearch] = useState("");
  const searchQuotations = (search: string) => {
    setQuotationsSearch(`search=${search}`);
  };
  const clearSearchQuotations = () => setQuotationsSearch("");

  const composeQuery = (status: string) => {
    if (status === "quotations" && hideRejectedQuotations) {
      return "status=submitted&status=editting";
    }
    if (status === "simulations" && hideRejectedQuotations) {
      return "status=submitted";
    }
    if (status === "simulations" && !hideRejectedQuotations) {
      return "status=submitted&status=rejected";
    }
    return "status=approved";
  };

  const createNewQuotation = () => {
    navigate(ToSimulationLocation("new", "simulacao"));
  };

  const shareProviderLink = () => {
    const { protocol, host } = window.location;
    const shareLink = `${protocol}//${host}/${user.username}`;
    navigator.clipboard.writeText(shareLink);
    alert(`Link copiado: ${shareLink}`);
  };

  const [showNewQuotationsCard, setShowNewQuotationsCard] = useState(
    initialState.showNewQuotationCard
  );

  const fetchSimulationsData = async (
    auth: AuthContextInterface,
    status: string
  ) => {
    try {
      setIsLoading(true);
      const quotationsStatus = composeQuery(status);
      const { data }: { data: Array<SimulationsObject> } =
        await getSimulationsByRequest(
          { ...auth },
          `${quotationsStatus}&${quotationsSearch}`
        );
      const arrayData: Array<SimulationsObject> = [...data];
      setSimulationsData(arrayData);
      setShowNewQuotationsCard(!arrayData.length);
    } catch (e) {
      errorHandler(new Error("Erro ao carregar cotações"), e, "quotationsList");
    }
    setIsLoading(false);
  };

  const fetchQuotationsData = async (auth: AuthContextInterface) => {
    try {
      setIsLoading(true);
      const { data }: { data: Array<QuotationsObject> } =
        await getQuotationsByRequest({ ...auth }, ``);
      const arrayData: Array<QuotationsObject> = [...data];
      setQuotationsData(arrayData);
      setShowNewQuotationsCard(!arrayData.length);
    } catch (e) {
      errorHandler(new Error("Erro ao carregar cotações"), e, "quotationsList");
    }
    setIsLoading(false);
  };

  const fetchProposalsData = async (auth: AuthContextInterface) => {
    try {
      setIsLoading(true);
      const { data }: { data: Array<ProposalsObject> } =
        await getProposalsByRequest({ ...auth }, ``);
      const arrayData: Array<ProposalsObject> = [...data];
      setProposalsData(arrayData);
    } catch (e) {
      errorHandler(
        new Error("Erro ao carregar propostas"),
        e,
        "quotationsList"
      );
    }
    setIsLoading(false);
  };

  const approveSimulation = async (id: string, resultIndex: string) => {
    try {
      const data: any = await approveSimulationById(authState, id, resultIndex);
      return data.quotationId as string;
    } catch (e) {
      errorHandler(new Error("Erro ao aprovar simulação"), e);
    }
    return "";
  };

  const fetchSimulationDetails = async (docId: string) => {
    try {
      const { data }: { data: SimulationsObject } = await getSimulationById(
        authState,
        docId
      );
      setSimulationData(data);
    } catch (e) {
      errorHandler(new Error("Erro ao carregar detalhes da cotação"), e);
    }
  };

  const fetchQuotationDetails = async (docId: string) => {
    try {
      const { data }: { data: QuotationsObject } = await getQuotationById(
        authState,
        docId
      );
      setQuotationData(data);
    } catch (e) {
      errorHandler(new Error("Erro ao carregar detalhes da cotação"), e);
    }
  };

  const fetchProposalDetails = async (docId: string) => {
    try {
      const { data }: { data: ProposalsObject } = await getProposalById(
        authState,
        docId
      );
      setProposalData(data);
    } catch (e) {
      errorHandler(new Error("Erro ao carregar detalhes da proposta"), e);
    }
  };

  const retrySimulationCalculation = async () => {
    if (simulationData?._id) {
      try {
        await retrySimulationById(simulationData?._id, "new");
      } catch (e) {
        errorHandler(new Error("Erro ao tentar recalcular cotação"), e);
      }
      await fetchSimulationDetails(simulationData?._id);
    }
  };

  const reloadSimulationData = async () => {
    await fetchSimulationDetails(simulationData?._id || "");
  };

  const reloadQuotationData = async () => {
    await fetchQuotationDetails(quotationData?._id || "");
  };

  const reloadProposalData = async () => {
    await fetchProposalDetails(proposalData?._id || "");
  };

  const clearQuotationsData = () => {
    setSimulationsData([]);
  };

  return (
    <QuotationsContext.Provider
      value={{
        approveSimulation,
        clearSearchQuotations,
        clearQuotationsData,
        fetchSimulationsData,
        fetchQuotationsData,
        fetchProposalsData,
        fetchProposalDetails,
        fetchSimulationDetails,
        fetchQuotationDetails,
        hideRejectedQuotations,
        simulationData,
        simulationsData,
        quotationData,
        quotationsData,
        proposalsData,
        proposalData,
        retrySimulationCalculation,
        reloadSimulationData,
        reloadQuotationData,
        reloadProposalData,
        searchQuotations,
        toggleRejectedQuotations,
        showNewQuotationsCard,
        createNewQuotation,
        shareProviderLink,
      }}
    >
      {children}
    </QuotationsContext.Provider>
  );
};

export default QuotationsContext;
