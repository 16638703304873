import { FC, useMemo } from "react";
import EditableSection from "../../../../../../components/EditableSection";
import useDocumentDetails from "../../../../../../hooks/useDocumentDetails";

import {
  Claim,
  ClaimEvent,
  GetCreateClaimOnFormResponseDocument,
} from "../../../../../../graphql/__GENERATED__/types";
import Table from "../../../../../../components/Table";
import useDetailsColor from "../../../../../../hooks/useDetailsColor";
import ClaimComponent, {
  ClaimEventLabels,
  ClaimStatusLabels,
  MappedClaim,
} from "./components/Claim";
import { concatArrayOfStrings } from "../../../../../../utils/formatString";

const Claims: FC = () => {
  const {
    documentData: { claims, _id },
    refetch,
  } = useDocumentDetails();

  const mappedClaims = useMemo<MappedClaim[]>(() => {
    return (
      claims?.map((claim) => ({
        ...claim,
        eventsLabel: concatArrayOfStrings(
          claim.events.map((ev: ClaimEvent) => `${ClaimEventLabels[ev]}`)
        ),
      })) || []
    );
  }, [claims]);

  const { color } = useDetailsColor();

  return (
    <EditableSection
      title="Sinistros"
      noTitle
      main
      createPayload={{ documentId: _id! }}
      createQuery={GetCreateClaimOnFormResponseDocument}
      footerButtons={[]}
      addText={"Abrir Sinistro"}
      onSubmit={refetch}
    >
      <Table<Claim & { eventsLabel: string | string[] }>
        data={mappedClaims!}
        color={color}
        withBorder
        DetailsComponent={ClaimComponent}
        columns={[
          {
            label: "ID na Seguradora",
            key: "externalId",
          },
          {
            label: "Data de aviso na Seguradora",
            key: "noticeDate",
            special: "date",
          },
          {
            label: "Eventos",
            key: "eventsLabel",
          },
          {
            label: "Status",
            key: "status",
            options: ClaimStatusLabels,
          },
        ]}
      ></Table>
    </EditableSection>
  );
};

export default Claims;
