/** @jsxImportSource @emotion/react */

import { FC, useMemo } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { partnersDetailsTabRoutes } from "../../../Routes";
import DetailsWrapper from "../../../components/Details/components/Main/DetailsWrapper";
import PartnerDetailsProvider from "./context/partner";
import DetailsMenu from "../../../components/Details/components/Header/DetailsTabs";
import Sidebar from "../../../components/Sidebar";
import Summary from "./sections/Summary";
import PartnerMeetings from "./sections/PartnerMeetings";
import { ColorNames } from "../../../theme";

const UserDetails: FC = () => {
  const { docId } = useParams();
  const location = useLocation();

  const tab = useMemo(
    () => location?.pathname?.split("/").pop(),
    [location.pathname]
  );

  const detailsTabContent = (
    <Sidebar
      TopComponent={DetailsMenu}
      menuItems={[
        {
          title: tab!,
          Component: Outlet,
          color: ColorNames.GREEN,
        },
      ]}
    ></Sidebar>
  );

  const menuItems = [
    {
      title: "Geral",
      color: ColorNames.GREEN,
      Component: Summary,
    },
    {
      title: "Conversas",
      color: ColorNames.GREEN,
      Component: PartnerMeetings,
    },
  ];

  if (!docId) return null;

  return (
    <PartnerDetailsProvider userId={docId}>
      <DetailsWrapper
        detailsTabContent={detailsTabContent}
        detailsTabRoutes={partnersDetailsTabRoutes}
      >
        <Sidebar TopComponent={DetailsMenu} menuItems={menuItems} />
      </DetailsWrapper>
    </PartnerDetailsProvider>
  );
};

export default UserDetails;
