import { FC } from "react";
import { useForm } from "../../hooks/useForm";
import { Many2OneFieldType } from "../../models/formInterfaces";
import useFieldValue from "./hooks/useFieldValue";
import { selectionToEvent } from "./utils";
import { SelectInputOptionInterface } from "../../../../components/Input/SelectInputs/SelectInput";
import Many2OneInput from "../../../../components/Input/SelectInputs/Many2OneInput";
import FormLabel from "../FormLabel";

const FormInputMany2One: FC<Many2OneFieldType & { groupPath: string }> = ({
  serviceName,
  domain,
  name,
  label,
  type,
  groupPath,
  disabled,
}) => {
  const [value, error] = useFieldValue(name, groupPath);
  const { handleChange, startEditing, finishEditing } = useForm();
  const handleSelect = (option: SelectInputOptionInterface) => {
    handleChange(selectionToEvent(name, type, option), groupPath);
  };

  return (
    <>
      <FormLabel>{label.toUpperCase()}</FormLabel>
      <Many2OneInput
        serviceName={serviceName}
        domain={domain}
        value={value}
        inputName={name}
        placeholder={label}
        onSelect={handleSelect}
        optionDisplay="old"
        onFocus={() => startEditing(groupPath, name)}
        onBlur={() => finishEditing(groupPath, name)}
        errorMessage={error}
        disabled={disabled}
      />
    </>
  );
};

export default FormInputMany2One;
