import React, { useEffect, useMemo, useState } from "react";
import useDebounce from "../../../../hooks/useDebounce";
import { formatInputMask, parseMask } from "../../../../utils/formatNumber";
import { TInputMasks } from "../../models/formInterfaces";

const useDebouncedMaskedValue = (
  value: string,
  editing: boolean,
  handleChange: (v: string) => void,
  inputMask?: TInputMasks
) => {
  const [localValue, setLocalValue] = useState<string>(value);
  const { debouncedValue } = useDebounce(localValue, 1000);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement> | string
  ) => {
    if (typeof e === "string") {
      setLocalValue(e);
      return;
    }
    setLocalValue(inputMask ? parseMask(e.target.value) : e.target.value);
  };

  useEffect(() => {
    if (debouncedValue !== value) {
      handleChange(debouncedValue as string);
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (!editing) {
      setLocalValue(value);
    }
  }, [value]);

  const maskedValue = useMemo(() => {
    return inputMask ? formatInputMask(localValue, inputMask) : localValue;
  }, [localValue]);

  return {
    localValue,
    maskedValue,
    handleInputChange,
  };
};

export default useDebouncedMaskedValue;
