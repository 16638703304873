import DashboardCard, { DashboardCardFC } from "../DashboardCard";
import { LeadsLocation } from "../../../../../Locations";
import { leadCards } from "../assets/dashboardCards";
import { useMemo } from "react";
import { LeadTypes } from "../../../../../graphql/__GENERATED__/types";
import useLeadColor from "../../../../../hooks/useLeadColor";
import useLeadsFilter from "../../../../../hooks/useLeadsFilter";

const LeadDashboardCard: DashboardCardFC<LeadTypes> = ({ category }) => {
  const cardColor = useLeadColor(category);
  const filter = useLeadsFilter();
  const cardData = useMemo(() => {
    return leadCards.find((card) => card.category === category);
  }, [category]);

  if (!cardData) return null;

  return (
    <DashboardCard
      cardData={cardData}
      color={cardColor}
      filter={filter}
      location={LeadsLocation}
    />
  );
};

export default LeadDashboardCard;
