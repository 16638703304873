/** @jsxImportSource @emotion/react */

import { FC, useMemo } from "react";
import DetailsSectionColumns from "../../../../../components/Details/components/Main/Grid/DetailsSectionColumns";
import {
  DocumentEquipment,
  Equipment,
} from "../../../../../graphql/__GENERATED__/types";
import DetailsSection from "../../../../../components/Details/components/Main/Section/DetailsSection";
import { formatPrice } from "../../../../../utils/formatNumber";

interface PropertyDataProps {
  data: Equipment & DocumentEquipment;
}

const EquipmentData: FC<PropertyDataProps> = ({ data: data }) => {
  const equipInfo = useMemo(
    () => [
      { label: "Tipo", value: data.type },
      { label: "Marca", value: data.brand },
      { label: "Modelo", value: data.model },
      { label: "Ano de Fabricação", value: data.manufactureYear },
      { label: "Propriedade", value: data.property?.displayName },
    ],
    [data]
  );

  const docEquipInfo = useMemo(
    () => [
      { label: "Valor de Mercado", value: formatPrice(data.marketValue) },
      {
        label: "É Renovação?",
        value: data.isRenewal ? "Sim" : "Não",
      },
      { label: "Seguradora Anterior", value: data.previousProvider },
      {
        label: "É um equipamento novo?",
        value: data.isNewEquipment ? "Sim" : "Não",
      },
      {
        label: "O bem é dado em garantia a financiamento com crédito rural?",
        value: data.isFinanced ? "Sim" : "Não",
      },
      {
        label:
          "O equipamento poderá ser cedido a terceiros durante a vigência do seguro?",
        value: data.isLeased ? "Sim" : "Não",
      },
      {
        label:
          "Opera a menos de 30m da margem de rio, mar, lago ou em operações subterrâneas?",
        value: data.isNearWater ? "Sim" : "Não",
      },
      {
        label:
          "O número de série/chassi informado correspondem aos dados constantes da plaqueta de identificação fixada ao bem segurado?",
        value: data.serialNumberMatch ? "Sim" : "Não",
      },
      {
        label: "Deseja contratar Cobertura de Furto Simples?",
        value: data.hasTheftCoverage ? "Sim" : "Não",
      },
    ],
    [data]
  );

  return (
    <DetailsSection title="Dados do Equipamento" footerButtons={[]} main>
      <DetailsSectionColumns columns={[equipInfo, docEquipInfo]} />
    </DetailsSection>
  );
};

export default EquipmentData;
