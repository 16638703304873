import { FC, useMemo } from "react";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import {
  GetCreatePaymentOnDocumentFormResponseDocument,
  GetEditBillsOnDocumentFormResponseDocument,
  Payment,
  PaymentStatus,
  Services,
} from "../../../../../graphql/__GENERATED__/types";
import { IconNames } from "../../../../../components/Icons/styles/iconNames";
import { DetailsRowValueVariants } from "../../../../../components/Details/components/Main/Grid/DetailsRowValue";
import { DetailsRowProps } from "../../../../../components/Details/components/Main/Grid/DetailsRow";
import DetailsSectionColumns from "../../../../../components/Details/components/Main/Grid/DetailsSectionColumns";
import useDetailsColor from "../../../../../hooks/useDetailsColor";
import PaymentFC from "./components/Payment";
import EditableTable from "../../../../../components/EditableTable";
import EditableSection from "../../../../../components/EditableSection";

export const PaymentStatusLabels = {
  [PaymentStatus.Done]: "Pago",
  [PaymentStatus.Pending]: "Pendente",
  [PaymentStatus.Cancelled]: "Cancelado",
};

const Payments: FC = () => {
  const {
    documentData: { _id, payments, policy },
    handleSetPaymentDone,
    handleCancelPayment,
    refetch,
    handleCreatePaymentCopy,
  } = useDocumentDetails();

  const { color } = useDetailsColor();

  const disabledOption = (status: PaymentStatus) => {
    return status === PaymentStatus.Done || status === PaymentStatus.Cancelled;
  };

  const paymentActions = [
    {
      label: "Emitir nova via",
      onClick: (payment: Payment) => handleCreatePaymentCopy(payment._id!),
      iconName: IconNames.Replicate,
      disabled: (payment: Payment) => disabledOption(payment.status),
      flag: "admin-only",
    },
    {
      label: "Dar baixa",
      onClick: (payment: Payment) => handleSetPaymentDone(payment._id!),
      iconName: IconNames.Valid,
      disabled: (payment: Payment) => disabledOption(payment.status),
      flag: "admin-only",
    },
    {
      label: "Cancelar",
      onClick: (payment: Payment) => handleCancelPayment(payment._id!),
      iconName: IconNames.Rejected,
      disabled: (payment: Payment) => disabledOption(payment.status),
      flag: "admin-only",
    },
  ];

  const downloadButtons: DetailsRowProps[][] = useMemo(() => {
    return [
      [
        {
          label: "Boletos",
          variant: DetailsRowValueVariants.File,
          value: policy?.billsFile?.fileKey && {
            file: policy?.billsFile,
            objectId: policy?._id,
            fieldPath: "billsFile",
            serviceName: Services.Policies,
          },
        },
      ],
    ].filter(Boolean) as DetailsRowProps[][];
  }, [policy?.billsFile]);

  return (
    <EditableSection
      title="Pagamentos"
      noTitle
      main
      editQuery={GetEditBillsOnDocumentFormResponseDocument}
      objectId={policy ? policy._id : undefined}
      editText={"Adicionar Boletos"}
      onSubmit={refetch}
    >
      <DetailsSectionColumns columns={downloadButtons} />
      <EditableTable
        data={payments!}
        actions={paymentActions}
        color={color}
        createQuery={GetCreatePaymentOnDocumentFormResponseDocument}
        addText={"Adicionar Parcela"}
        createPayload={{ documentId: _id! }}
        onSubmit={refetch}
        withBorder
        formDisplay="modal"
        formTitle="Parcela"
        addFlag="admin-only"
        columns={[
          {
            key: "name",
            label: "Parcela",
            textAlign: "left",
          },
          {
            key: "dateDue",
            label: "Vencimento",
            special: "date",
            textAlign: "left",
          },
          {
            key: "amount",
            label: "Valor",
            special: "monetary",
            textAlign: "left",
          },
          {
            key: "status",
            label: "Status",
            options: PaymentStatusLabels,
            textAlign: "left",
          },
        ]}
        DetailsComponent={PaymentFC}
      />
    </EditableSection>
  );
};

export default Payments;
