/** @jsxImportSource @emotion/react */

import { FC } from "react";
import Reject from ".";
import { ConsultantsLocation } from "../../Locations";
import { rejectPartnerById } from "../../services/partners";
import { usePartnerDetails } from "../../views/Partners/PartnerDetails/hooks/usePartnerDetails";

const PartnerRejection: FC = () => {
  const { data } = usePartnerDetails();
  const rejectReasons = [
    {
      value: "informacoes-invalidas",
      label: "Cadastro com informações inválidas",
    },
    {
      value: "cadastro-duplicado",
      label: "Já existe um cadastro para este parceiro",
    },
    {
      value: "suspeita-spam-bot",
      label: "Suspeita de spam ou bot",
    },
    {
      value: "outros",
      label: "Outros",
    },
  ];

  return (
    <Reject
      data={data}
      callbackLocation={ConsultantsLocation}
      confirmationMessage={"Cadastro recusado!"}
      errorMessage={"Não foi possível recusar o parceiro."}
      rejectReasons={rejectReasons}
      deletePartnerOrQuotation={rejectPartnerById}
    />
  );
};

export default PartnerRejection;
