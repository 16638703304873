import React, { FC, ReactNode, useState } from "react";

type FlagsObj = { [key: string]: boolean };

export interface FlagsState {
  flags: FlagsObj;
}

export const initialState: FlagsState = {
  flags: {},
};

export interface FlagStore {
  isFlagEnabled: (flagName: string) => boolean;
  loadFlags: (flags: Array<string>) => void;
  flagsLoaded: boolean;
}

const FeatureFlagsContext = React.createContext<FlagStore>({
  isFlagEnabled: () => false,
  loadFlags: () => null,
  flagsLoaded: false,
});

interface FeatureFlagsContextProviderProps {
  children: ReactNode;
}

export const FeatureFlagsContextProvider: FC<
  FeatureFlagsContextProviderProps
> = ({ children }) => {
  const [flags, setFlags] = useState(initialState.flags);
  const [flagsLoaded, setFlagsLoaded] = useState(false);

  const loadFlags = (flagNames: Array<string>) => {
    const flagsObj: FlagsObj = {};
    flagNames.forEach((flagName) => (flagsObj[flagName] = true));
    setFlags(flagsObj);
    setFlagsLoaded(true);
  };

  const isFlagEnabled = (flagName: string) => {
    return !!flags[flagName];
  };

  return (
    <FeatureFlagsContext.Provider
      value={{ isFlagEnabled, loadFlags, flagsLoaded }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export default FeatureFlagsContext;
