import { useMemo } from "react";
import { ColorNames } from "../theme";
import { LeadTypes } from "../graphql/__GENERATED__/types";

const useLeadColor = (type: string) => {
  const color = useMemo(() => {
    switch (type) {
      case LeadTypes.Customer.toString():
        return ColorNames.GREEN;
      case LeadTypes.Partner.toString():
        return ColorNames.PINK;
      default:
        return ColorNames.LIGHTGREY;
    }
  }, [type]);
  return color;
};

export default useLeadColor;
