import { FC } from "react";

const Replicate: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3781_154)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21 16.5V13.5H22.5V16.5C22.4995 16.8977 22.3414 17.279 22.0602 17.5602C21.779 17.8414 21.3977 17.9995 21 18H18V21C17.9995 21.3977 17.8414 21.779 17.5602 22.0602C17.279 22.3414 16.8977 22.4995 16.5 22.5H3C2.60232 22.4995 2.22105 22.3414 1.93984 22.0602C1.65864 21.779 1.50046 21.3977 1.5 21V7.5C1.50046 7.10232 1.65864 6.72105 1.93984 6.43984C2.22105 6.15864 2.60232 6.00046 3 6H6V3C6.00046 2.60232 6.15864 2.22105 6.43984 1.93984C6.72105 1.65864 7.10232 1.50046 7.5 1.5H10.5V3H7.5V6H16.5C16.8977 6.00046 17.279 6.15864 17.5602 6.43984C17.8414 6.72105 17.9995 7.10232 18 7.5V16.5H21ZM3 21H16.5V7.5H3V21Z"
          fill="black"
        />
        <path d="M12.75 1.5H15.75V3H12.75V1.5Z" fill="black" />
        <path
          d="M22.5 6H21V3H18V1.5H21C21.3977 1.50042 21.779 1.65859 22.0602 1.9398C22.3414 2.22101 22.4996 2.6023 22.5 3V6Z"
          fill="black"
        />
        <path d="M22.5 8.25H21V11.25H22.5V8.25Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_3781_154">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Replicate;
