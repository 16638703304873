import { ContactTags } from "../../../graphql/__GENERATED__/types";

export const tagsLabels: Record<ContactTags, string> = {
  [ContactTags.Farmer]: "Produtor",
  [ContactTags.Admin]: "Administrador",
  [ContactTags.AllowedPerson]: "Pessoa autorizada",
  [ContactTags.Beneficiary]: "Beneficiário",
  [ContactTags.Consultant]: "Consultor",
  [ContactTags.Inspector]: "Inspetor",
  [ContactTags.PaymentResponsible]: "Responsável financeiro",
  [ContactTags.Lead]: "Lead",
};
