/** @jsxImportSource @emotion/react */

import { useMemo } from "react";
import useDocumentDetails from "../../../../../../hooks/useDocumentDetails";
import Typography from "../../../../../../components/Typography";
import {
  DocumentClaimStatus,
  DocumentRejectReason,
  DocumentStages,
  DocumentStatus,
  InsuranceTypeGroupEnum,
} from "../../../../../../graphql/__GENERATED__/types";
import DetailsSectionColumn from "../../../../../../components/Details/components/Main/Grid/DetailsSectionColumn";
import { stageLabel, statusLabel } from "./StageAndStatusLabels";
import Icon from "../../../../../../components/Icons";
import { ColorNames } from "../../../../../../theme";
import { getStatusIcon } from "../../../../../../filters/documents";
import { DetailsRowProps } from "../../../../../../components/Details/components/Main/Grid/DetailsRow";
import { IconNames } from "../../../../../../components/Icons/styles/iconNames";
import {
  formatDateWithTime,
  formatISODate,
} from "../../../../../../utils/dateUtils";

const claimStatusLabels = {
  [DocumentClaimStatus.Closed]: "Fechado",
  [DocumentClaimStatus.Open]: "Aberto",
};

const InsuranceInfo = () => {
  const {
    documentData: {
      stage,
      status,
      createdAt,
      archived,
      rejectReason,
      provider,
      claimStatus,
      updatedAt,
      policy,
      insuranceTypeGroup,
    },
  } = useDocumentDetails();

  const DocumentRejectReasonLabels = {
    [DocumentRejectReason.NotAvailable]: "Produto indisponível",
    [DocumentRejectReason.OutOfCoveragePeriod]: "Fora da época de contratação",
    [DocumentRejectReason.PriceTooHigh]: "Valor muito alto",
    [DocumentRejectReason.ContactLost]: "Contato perdido",
    [DocumentRejectReason.Other]: "Outra",
  };

  const InsuranceTypeGroupLabels = {
    [InsuranceTypeGroupEnum.Agricultural]: "Agrícola",
    [InsuranceTypeGroupEnum.Machinery]: "Máquinas",
  };

  const insuranceInfo = useMemo<DetailsRowProps[]>(() => {
    return [
      {
        label: "Etapa // Status",
        value: (
          <Typography
            variant="textMedium"
            component="div"
            css={{ display: "flex", alignItems: "center", gap: 4 }}
          >
            <Icon
              name={
                archived
                  ? IconNames.Archive
                  : getStatusIcon(status as DocumentStatus)!
              }
              color={archived ? ColorNames.ERROR_RED : ColorNames.GREEN}
            />
            {archived
              ? "Arquivado"
              : `${stageLabel[stage as DocumentStages]} ${
                  statusLabel[status as DocumentStatus]
                }`}
          </Typography>
        ),
      },
      status === DocumentStatus.Rejected && {
        label: "Motivo da Recusa",
        value:
          rejectReason?.rejectReason === DocumentRejectReason.Other
            ? `${DocumentRejectReasonLabels[DocumentRejectReason.Other]}: ${
                rejectReason.other
              }`
            : rejectReason &&
              `${DocumentRejectReasonLabels[rejectReason.rejectReason]}`,
      },
      {
        label: "Tipo de Seguro",
        value: InsuranceTypeGroupLabels[insuranceTypeGroup!],
      },
      {
        label: "Criado em",
        value: formatDateWithTime(createdAt),
      },
      {
        label: "Atualizado em",
        value: formatDateWithTime(updatedAt),
      },
      policy && {
        label: "Vigência da apólice",
        value: `${formatISODate(policy.startDate)} - ${formatISODate(
          policy.endDate
        )}`,
      },
      claimStatus && {
        label: "Sinistro",
        value: claimStatusLabels[claimStatus],
      },
    ].filter(Boolean) as DetailsRowProps[];
  }, [
    archived,
    stage,
    status,
    policy,
    claimStatus,
    rejectReason,
    createdAt,
    updatedAt,
    insuranceTypeGroup,
  ]);

  const approvedInsuranceInfo = useMemo(() => {
    return (
      provider && {
        label: "Seguradora",
        value: provider,
      }
    );
  }, [provider]);

  const info = approvedInsuranceInfo
    ? [...insuranceInfo, approvedInsuranceInfo]
    : insuranceInfo;

  return <DetailsSectionColumn xs={12} sm={6} rows={info} />;
};

export default InsuranceInfo;
