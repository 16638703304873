import { FC } from "react";
import { ScreenClassMap } from "../../../../Grid/Container";
import Column from "../../../../Grid/Column";
import DetailsRow, { DetailsRowProps } from "./DetailsRow";

export interface DetailsSectionColumnProps extends ScreenClassMap<number> {
  rows: DetailsRowProps[];
}

const DetailsSectionColumn: FC<DetailsSectionColumnProps> = ({
  rows,
  ...props
}) => {
  return (
    <Column {...props}>
      {rows.map((row, index) => {
        return <DetailsRow key={index} {...row} />;
      })}
    </Column>
  );
};

export default DetailsSectionColumn;
