/** @jsxImportSource @emotion/react */
import { FC } from "react";

import {
  ContactTags,
  useListContactsQuery,
} from "../../../graphql/__GENERATED__/types";
import CardList from "../../../components/CardList";
import Layout from "../../../components/Layout";
import { ContactsLocation, ToContactDetailsLocation } from "../../../Locations";
import ContactCard from "./ContactCard";
import MainFrame from "../../../components/MainFrame";
import { Outlet, useNavigate } from "react-router-dom";
import { useUIStore } from "../../../hooks/useUIStore";
import useContactsFilter from "../../../hooks/useContactsFilter";

const Contacts: FC = () => {
  const contactsFilter = useContactsFilter();
  const filterQuery = contactsFilter.composeQuery();
  const { data, loading } = useListContactsQuery({
    variables: {
      filter: [...filterQuery, [{ tags: ContactTags.Farmer }]],
      sort: { updatedAt: -1 },
    },
  });
  const navigate = useNavigate();
  const { toggleDrawerSize } = useUIStore();

  return (
    <Layout>
      <MainFrame
        drawerContent={<Outlet />}
        onClose={() => navigate(ContactsLocation)}
        onToggle={toggleDrawerSize}
        drawerParamKey="contactId"
        filter={contactsFilter}
      >
        <CardList
          data={data?.contacts || []}
          redirectLocation={ToContactDetailsLocation}
          CardComponent={ContactCard}
          loading={loading}
        />
      </MainFrame>
    </Layout>
  );
};

export default Contacts;
