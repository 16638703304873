import {
  GetCreateMeetingOnObjectFormResponseDocument,
  Services,
} from "../../../../graphql/__GENERATED__/types";
import Meetings from "../../../../components/Meetings";
import useLeadDetails from "../../../../hooks/useLeadDetails";

const LeadMeetings = () => {
  const {
    leadData: { _id, meetings },
    refetch,
  } = useLeadDetails();
  return (
    <Meetings
      meetings={meetings!}
      createQuery={GetCreateMeetingOnObjectFormResponseDocument}
      parentId={_id!}
      serviceName={Services.Leads}
      refetch={refetch}
    />
  );
};

export default LeadMeetings;
