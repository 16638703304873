import React, { ReactNode, FC } from "react";
import useFormState from "../hooks/useFormState";
import { FormObj } from "../models/formInterfaces";

export interface FormState {
  [key: string]: any;
}

export interface FormSectionState {
  [key: string]: string | boolean | number;
}

export const initialState: FormState = {};

export type FormContextState = ReturnType<typeof useFormState>;

const FormContext = React.createContext<FormContextState>({
  formState: initialState,
  isFormUpdated: false,
  isEditing: false,
  startEditing: () => null,
  finishEditing: () => null,
  handleChange: () => null,
  handleSubmit: async () =>
    Promise.resolve({
      data: { formData: initialFormObj, formState: {}, errors: {} },
    }),
  updateFormState: () => null,
  upsert: async () =>
    Promise.resolve({
      data: { formData: initialFormObj, formState: {}, errors: {} },
    }),
  errors: {},
  fieldsToValidate: {},
  clickedSubmit: false,
  setClickedSubmit: () => {},
  handleFileUpload: () => {},
  handleFileDelete: () => {},
  formData: { sections: [], onSubmit: () => {} },
  error: null,
  setError: () => {},
  validSections: {},
  clearErrors: () => {},
  visibility: {},
});

const initialFormObj: FormObj = {
  title: "",
  subtitle: "",
  id: "",
  sections: [],
  onSubmit: () => {},
};

interface FormContextProviderProps {
  children: ReactNode;
  formId: string;
  docId: string;
}

export const FormContextProvider: FC<FormContextProviderProps> = ({
  children,
  formId,
  docId,
}) => {
  const state = useFormState(formId, docId);

  return (
    <FormContext.Provider value={{ ...state }}>{children}</FormContext.Provider>
  );
};

export default FormContext;
