/** @jsxImportSource @emotion/react */

import { FC, useMemo } from "react";
import {
  BasicCoverageTypes,
  CoverageEvent,
  DocumentStages,
  DocumentStatus,
  Result,
  ResultCoverage,
} from "../../../../../graphql/__GENERATED__/types";
import ProviderLogo from "../../../../../components/ProviderLogos";
import QuotationCardSection from "./QuotationCardSection";
import { IQuotationCardRow } from "./QuotationCardRow";
import { formatNumber, formatPrice } from "../../../../../utils/formatNumber";
import { theme } from "../../../../../theme";
import Typography from "../../../../../components/Typography";
import Button from "../../../../../components/Button";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";

interface QuotationCardContentProps {
  result: Result;
}

const BasicCoverageTypeLabels = {
  [BasicCoverageTypes.Custeio]: "Multirrisco, Custeio",
  [BasicCoverageTypes.Produtividade]: "Multirrisco, Produtividade",
  [BasicCoverageTypes.RiscoNomeado]: "Risco Nomeado",
  [BasicCoverageTypes.Parametrico]: "Paramétrico",
};

const styles = {
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "space-between",
  },
  logo: {
    paddingLeft: "8px",
    textAlign: "left" as const,
  },
  coverageType: {
    padding: "0px 20px",
  },
};

const eventsMatch = (events1: CoverageEvent[], events2: CoverageEvent[]) =>
  events1.length &&
  events1.length === events2.length &&
  events1
    .map(({ _id }) => _id!)
    .every((eventId) => events2.map(({ _id }) => _id!).includes(eventId));

const QuotationCardContent: FC<QuotationCardContentProps> = ({ result }) => {
  const {
    documentData: { stage, status },
    handleApproveResult,
  } = useDocumentDetails();

  const parametricWarning = useMemo(() => {
    return result.referenceLocation ? "**" : "";
  }, [result.referenceLocation]);

  const coverageTypeInfo = useMemo(() => {
    if (
      ![BasicCoverageTypes.Produtividade, BasicCoverageTypes.Custeio].includes(
        result.basicCoverageType!
      )
    ) {
      return;
    } else {
      return [
        {
          label: "Produtividade",
          value: `${formatNumber(
            result.guaranteedProductivity,
            2,
            2
          )} sacas/ha`,
        },
        result.sackValue && {
          label: "Valor da saca",
          value: formatPrice(result.sackValue),
        },
      ].filter(Boolean) as IQuotationCardRow[];
    }
  }, [
    result.guaranteedProductivity,
    result.basicCoverageType,
    result.sackValue,
  ]);

  const itens = useMemo<IQuotationCardRow[][]>(() => {
    return result.itens?.map(({ coverages, name }) => {
      const uniqueCoverages: ResultCoverage[] = [];
      coverages.forEach((coverage) => {
        const { coverageEvents, lmi } = coverage;
        const existingCoverage = uniqueCoverages.find(
          ({ coverageEvents: existingCoverageEvents }) =>
            eventsMatch(coverageEvents!, existingCoverageEvents!)
        );
        if (existingCoverage) {
          existingCoverage.lmi = existingCoverage.lmi! + lmi!;
        } else {
          uniqueCoverages.push({ ...coverage! });
        }
      });
      const rows = uniqueCoverages.map((coverage) => {
        return {
          label: `${coverage
            .coverageEvents!.map(({ name }) => name)
            .join("/")}${parametricWarning}`,
          value: formatPrice(coverage?.lmi),
        };
      });
      return [
        result.itens!.length > 1 && {
          label: name,
          subtitle: true,
          textBold: true,
        },
        {
          label: "Cobertura",
          value: "Valor Segurado",
          subtitle: true,
          textBold: true,
        },
        ...rows!,
      ].filter(Boolean) as IQuotationCardRow[];
    })!;
  }, [result.itens]);

  const costs = useMemo(() => {
    return [
      {
        label: "Custo Total",
        value: formatPrice(result.amounts?.totalPremium),
        textBold: true,
      },
      {
        label: "Subvenção Estadual*",
        value: `- ${formatPrice(result.amounts?.stateSubvention)}`,
      },
      {
        label: "Subvenção Federal*",
        value: `- ${formatPrice(result.amounts?.federalSubvention)}`,
      },
    ] as IQuotationCardRow[];
  }, [result.amounts]);

  const payment = useMemo(() => {
    return [
      {
        label: `Custo Final${parametricWarning}`,
        value: formatPrice(result.amounts?.finalPremium),
        textLarge: true,
        textBold: true,
      },
      {
        label: "Pagamento",
        value: `${result.amounts?.maxPayments}x ${formatPrice(
          result.amounts?.finalPremium! / result.amounts?.maxPayments!
        )}`,
        textLarge: true,
        textBold: true,
      },
    ];
  }, [result.amounts]);

  const approvable = useMemo(() => {
    return (
      stage === DocumentStages.Simulation && status === DocumentStatus.Sent
    );
  }, [stage, status]);

  return (
    <div css={styles.container}>
      <div>
        <div css={styles.logo}>
          <ProviderLogo name={result.provider!} />
        </div>
        <Typography
          variant="textLarge"
          uppercase
          weight="bold"
          component="div"
          align="left"
          css={styles.coverageType}
        >
          {BasicCoverageTypeLabels[result.basicCoverageType!]}
        </Typography>
        {coverageTypeInfo && <QuotationCardSection rows={coverageTypeInfo} />}
        {itens?.map((itensRows, index) => (
          <QuotationCardSection key={`itemRows-${index}`} rows={itensRows} />
        ))}
      </div>
      <div>
        <QuotationCardSection
          rows={costs}
          backgroundColor={theme.colors.LightGrey[100]}
        />
        <br />
        <QuotationCardSection
          rows={payment}
          backgroundColor={theme.colors.Grey[40]}
        />
        {approvable && (
          <>
            <br />
            <Button
              text="Aprovar"
              onClick={() => handleApproveResult(result._id!)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default QuotationCardContent;
