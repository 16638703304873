import { FC, ReactNode } from "react";
import Typography from "../../../../Typography";
import FileDetails, { FileDetailsProps } from "./valueVariants/File";
import ModalVariant, { ModalVariantProps } from "./valueVariants/Modal";

export type DetailsRowVariantValues = FileDetailsProps;

export enum DetailsRowValueVariants {
  File = "File",
  Modal = "Modal",
}

type DetailsRowVariant<Variant extends DetailsRowValueVariants, Value> = {
  variant: Variant;
  value: Value;
};

type DetailsRowBasicProps = { value: ReactNode; variant?: undefined };

type DetailsRowFileVariantProps = DetailsRowVariant<
  DetailsRowValueVariants.File,
  FileDetailsProps
>;
type DetailsRowModalVariantProps = DetailsRowVariant<
  DetailsRowValueVariants.Modal,
  ModalVariantProps
>;

export type DetailsRowValueProps =
  | DetailsRowBasicProps
  | DetailsRowFileVariantProps
  | DetailsRowModalVariantProps;

const DetailsRowValue: FC<DetailsRowValueProps> = ({ value, variant }) => {
  if (variant === DetailsRowValueVariants.File) {
    return <FileDetails {...value} />;
  }
  if (variant === DetailsRowValueVariants.Modal) {
    return <ModalVariant {...value} />;
  }
  return (
    <Typography variant="textMedium" uppercase component="div" align="left">
      {value || "-"}
    </Typography>
  );
};

export default DetailsRowValue;
