export enum ColorNames {
  BLACK = "Black",
  GREEN = "Green",
  RED = "Red",
  PINK = "Pink",
  BLUE = "Blue",
  WHITE = "White",
  ERROR_RED = "ErrorRed",
  ORANGE = "Orange",
  VALID_GREEN = "ValidGreen",
  LIGHTGREY = "LightGrey",
  GREY = "Grey",
}

export type ColorAlias =
  | "Black"
  | "Green"
  | "inherit"
  | "Red"
  | "Pink"
  | "Blue"
  | "White"
  | "ErrorRed"
  | "ValidGreen"
  | "Orange"
  | "LightGrey"
  | "Grey";

export const getThemeColorFromAlias = (
  alias: ColorAlias,
  themeColors: CustomTheme["colors"],
  opacity?: ColorLevels
): string | undefined => {
  if (alias === "inherit") return alias;
  if (alias in themeColors) {
    return themeColors[alias as ColorNames][opacity || 100];
  }

  return undefined;
};

export type ColorLevels = 20 | 40 | 60 | 80 | 100;
export type Sizes = "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
export type ScreenClass = "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
export type Margins = "none" | "xxs" | Sizes;
export type Breakpoints = "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
export type Gutters = "xs" | "sm" | "md" | "lg" | "xl" | "xxl";

export interface ColorLevelsMap extends Record<ColorLevels, string> {
  default?: string;
  hover?: string;
  active?: string;
  0?: string;
}

export interface CustomTheme {
  colors: Record<ColorNames, Record<ColorLevels, string>>;
  gutters: Record<Gutters, number>;
  margins: Record<Margins, number>;
  breakpoints: Record<Breakpoints, number>;
  columns: Record<ScreenClass, number>;
  borderRadius: Partial<Record<Sizes, number>>;
  boxShadow: Record<string, string>;
}

export const theme: CustomTheme = {
  colors: {
    [ColorNames.BLACK]: {
      100: "#000000",
      20: "#00000033",
      40: "#00000066",
      60: "#00000099",
      80: "#000000cc",
    },
    [ColorNames.GREEN]: {
      100: "#417E10",
      20: "#417E1033",
      40: "#417E1066",
      60: "#417E1099",
      80: "#417E10cc",
    },
    [ColorNames.RED]: {
      100: "#F90909",
      20: "#F9090933",
      40: "#F9090966",
      60: "#F9090999",
      80: "#F90909cc",
    },
    [ColorNames.PINK]: {
      100: "#D804B6",
      20: "#D804B633",
      40: "#D804B666",
      60: "#D804B699",
      80: "#D804B6cc",
    },
    [ColorNames.BLUE]: {
      100: "#043FD8",
      20: "#043FD833",
      40: "#043FD866",
      60: "#043FD899",
      80: "#043FD8cc",
    },
    [ColorNames.WHITE]: {
      100: "#fff",
      20: "#ffffff33",
      40: "#ffffff66",
      60: "#ffffff99",
      80: "#ffffffcc",
    },
    [ColorNames.LIGHTGREY]: {
      100: "#f5f5f5",
      20: "#f5f5f533",
      40: "#f5f5f566",
      60: "#f5f5f599",
      80: "#f5f5f5cc",
    },
    [ColorNames.GREY]: {
      100: "#878784",
      20: "#87878433",
      40: "#87878466",
      60: "#87878499",
      80: "#878784cc",
    },
    [ColorNames.ERROR_RED]: {
      100: "#C81F1C",
      40: "#C81F1C40",
      20: "#C81F1C20",
      60: "#C81F1C99",
      80: "#C81F1Ccc",
    },
    [ColorNames.ORANGE]: {
      100: "#F8AD1B",
      20: "#F8AD1B33",
      40: "#F8AD1B66",
      60: "#F8AD1B99",
      80: "#F8AD1Bcc",
    },
    [ColorNames.VALID_GREEN]: {
      100: "#1A7F37",
      20: "#1A7F3733",
      40: "#1A7F3766",
      60: "#1A7F3799",
      80: "#1A7F37cc",
    },
  },
  breakpoints: {
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
    xl: 1600,
    xxl: 1920,
  },
  columns: {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12,
    xxl: 12,
  },
  borderRadius: {
    xs: 2,
    sm: 4,
    md: 8,
    lg: 12,
  },
  gutters: {
    xs: 20,
    sm: 24,
    md: 24,
    lg: 24,
    xl: 24,
    xxl: 24,
  },
  margins: {
    none: 0,
    xxs: 8,
    xs: 12,
    sm: 16,
    md: 24,
    lg: 36,
    xl: 40,
    xxl: 64,
  },
  get boxShadow() {
    return {
      default: `${ColorNames.LIGHTGREY} 0px 3px 6px`,
      dark: `${this.colors.Grey[100]} 0px 3px 6px`,
    };
  },
};
