/** @jsxImportSource @emotion/react */

import { FC } from "react";
import Dashboard from "..";
import { Services } from "../../../graphql/__GENERATED__/types";
import { ToConsultantDetailsLocation } from "../../../Locations";
import { contactCards } from "../components/Cards/assets/dashboardCards";
import ContactDashboardCard from "../components/Cards/components/ContactDashboardCard";

const PartnersDashboard: FC = () => {
  return (
    <Dashboard
      serviceName={Services.Contacts}
      redirectLocation={ToConsultantDetailsLocation}
      cards={contactCards}
      CardComponent={ContactDashboardCard}
    />
  );
};

export default PartnersDashboard;
