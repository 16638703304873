/** @jsxImportSource @emotion/react */

import { FC, useState } from "react";
import logo from "../../assets/logo.svg";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../../hooks/useCSSRulesWithTheme";
import {
  HomeLocation,
  TermsAndConditionsLocation,
} from "../../../../Locations";
import Typography from "../../../Typography";
import { useUIStore } from "../../../../hooks/useUIStore";
import Container from "../../../Grid/Container";
import { ColorNames, theme } from "../../../../theme";
import { IconSizes } from "../../../Icons/styles/iconSizes";
import Avatar from "../../../Avatar";
import HR from "../../../HorizontalLine";
import { IconNames } from "../../../Icons/styles/iconNames";
import Icon from "../../../Icons";
import CustomLink from "../../../CustomLink";
import useOutsideClick from "../../../../hooks/useOutsideClick";

export const headerHeight = 75;

interface HeaderProps {
  username: string;
  email: string;
  isLoggedIn: boolean;
  signOut: () => void;
}

const getCSSRules: CSSRulesResolver = ({ ...props }) => ({
  header: {
    position: "sticky",
    top: 0,
    height: headerHeight,
    zIndex: 300,
    backgroundColor: `${props.colors.White[100]}`,
    boxShadow: theme.boxShadow.default,
  },
  container: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  logo: {
    cursor: "pointer",
  },
  dropdownMenu: {
    position: "absolute",
    top: headerHeight,
    right: 12,
    backgroundColor: `${props.colors.White[100]}`,
    minWidth: 250,
    borderRadius: 5,
    border: `2px solid ${props.colors.Green[100]}`,
    padding: 10,
    zIndex: 300,
  },
  menuItem: {
    cursor: "pointer",
    padding: "10px 5px",
    display: "flex",
    gap: 6,
    alignItems: "center",
    "& > div": {
      marginRight: 10,
    },
    "&:hover": {
      backgroundColor: `${props.colors.LightGrey[80]}`,
    },
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  profileName: {
    cursor: "pointer",
  },
  userName: {
    cursor: "default",
    display: "flex",
    alignItems: "center",
    "& > div": {
      marginRight: 10,
    },
  },
});

const Header: FC<HeaderProps> = ({ isLoggedIn, username, email, signOut }) => {
  const styles = useCSSRulesWithTheme(getCSSRules);
  const { openProfileModal } = useUIStore();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleClickDropdown = () => {
    setIsMenuOpen((isMenuOpen) => !isMenuOpen);
  };

  const handleOpenProfileModal = () => {
    openProfileModal();
    setIsMenuOpen(false);
  };

  const ref = useOutsideClick(() => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  });

  return (
    <div css={styles.header}>
      <Container css={styles.container}>
        <CustomLink to={HomeLocation}>
          <img src={logo} css={styles.logo} />
        </CustomLink>
        <div>
          {isLoggedIn && (
            <>
              <Avatar
                name={username}
                size={IconSizes.ExtraLarge}
                color={ColorNames.GREEN}
                filled
                onClick={handleClickDropdown}
              />
              <div ref={ref}>
                {isMenuOpen && (
                  <div css={styles.dropdownMenu}>
                    <div css={styles.userName}>
                      <Avatar
                        name={username}
                        color={ColorNames.GREEN}
                        size={IconSizes.ExtraLarge}
                        filled
                      />
                      <div>
                        <Typography
                          variant="textMedium"
                          component="div"
                          align="left"
                          weight="bold"
                        >
                          {username}
                        </Typography>
                        <Typography
                          variant="textSmall"
                          component="div"
                          align="left"
                        >
                          {email}
                        </Typography>
                      </div>
                    </div>
                    <HR color={ColorNames.GREEN} />
                    <Typography
                      variant="textMedium"
                      align="left"
                      component="div"
                      css={styles.menuItem}
                      onClick={handleOpenProfileModal}
                    >
                      Perfil
                    </Typography>
                    <CustomLink to={TermsAndConditionsLocation}>
                      <Typography
                        variant="textMedium"
                        align="left"
                        component="div"
                        css={styles.menuItem}
                      >
                        Termos e condições
                      </Typography>
                    </CustomLink>
                    <Typography
                      variant="textMedium"
                      align="left"
                      component="div"
                      css={styles.menuItem}
                      onClick={signOut}
                    >
                      Logout
                      <Icon name={IconNames.Logout} size={IconSizes.Small} />
                    </Typography>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Header;
