import {
  DocumentStages,
  LeadTypes,
  UserRoles,
} from "../../../../../graphql/__GENERATED__/types";
import { TDashboardCard } from "../DashboardCard";

export const documentCards: TDashboardCard[] = [
  {
    category: DocumentStages.Simulation,
    groupFilter: ["stage", "cotacao"],
    buttonFilters: [
      "status",
      ["pendentes", "finalizadas", "enviadas", "expiradas"],
    ],
  },
  {
    category: DocumentStages.Proposal,
    groupFilter: ["stage", "proposta"],
    buttonFilters: [
      "status",
      ["incompletas", "pendentes", "finalizadas", "enviadas"],
    ],
  },
  {
    category: DocumentStages.Policy,
    groupFilter: ["stage", "apolice"],
    buttonFilters: [
      "status",
      ["pendentes", "finalizadas", "vigentes", "nao-vigentes"],
    ],
  },
];

export const contactCards: TDashboardCard[] = [
  {
    category: UserRoles.Consultant,
    groupFilter: ["papel", "consultor"],
    buttonFilters: ["status", ["cadastrado", "aprovado", "ativo", "recusado"]],
  },
  {
    category: UserRoles.Organization,
    groupFilter: ["papel", "organizacao"],
    buttonFilters: ["status", ["cadastrado", "aprovado", "ativo", "recusado"]],
  },
  {
    category: UserRoles.Cooperative,
    groupFilter: ["papel", "cooperativa"],
    buttonFilters: ["status", ["cadastrado", "aprovado", "ativo", "recusado"]],
  },
  {
    category: UserRoles.Resale,
    groupFilter: ["papel", "revenda"],
    buttonFilters: ["status", ["cadastrado", "aprovado", "ativo", "recusado"]],
  },
];

export const leadCards: TDashboardCard[] = [
  {
    category: LeadTypes.Customer,
    groupFilter: ["tipo", "cliente"],
    buttonFilters: [
      "status",
      ["novo", "diagnostico", "apresentacao", "negociacao"],
    ],
  },
  {
    category: LeadTypes.Partner,
    groupFilter: ["tipo", "parceiro"],
    buttonFilters: [
      "status",
      ["novo", "diagnostico", "apresentacao", "negociacao"],
    ],
  },
];
