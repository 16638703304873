import { FC } from "react";
import { Meeting, Services } from "../../graphql/__GENERATED__/types";
import EditableSection from "../EditableSection";
import Table from "../Table";
import MeetingComponent, {
  MeetingStatusLabels,
  MeetingTopicLabels,
} from "./Meeting";
import { ColorNames } from "../../theme";

interface MeetingsProps {
  meetings: Meeting[];
  createQuery: any;
  parentId: string;
  serviceName: Services;
  refetch: () => void;
  color?: ColorNames;
}

const Meetings: FC<MeetingsProps> = ({
  meetings,
  createQuery,
  parentId,
  serviceName,
  color,
  refetch,
}) => {
  return (
    <EditableSection
      title="Conversas"
      noTitle
      main
      createPayload={{ parentId, serviceName }}
      createQuery={createQuery}
      addText={"Nova Conversa"}
      onSubmit={refetch}
    >
      <Table<Meeting>
        data={meetings || []}
        withBorder
        DetailsComponent={MeetingComponent}
        color={color}
        columns={[
          {
            label: "Data",
            key: "date",
            special: "date",
          },
          {
            label: "Assunto",
            key: "topic",
            options: MeetingTopicLabels,
          },
          {
            label: "Status",
            key: "status",
            options: MeetingStatusLabels,
          },
        ]}
      ></Table>
    </EditableSection>
  );
};

export default Meetings;
