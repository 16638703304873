/** @jsxImportSource @emotion/react */

import { FC, useMemo } from "react";
import StageChart, { StageChartProps } from "./components";
import Row from "../../../../components/Grid/Row";
import Column from "../../../../components/Grid/Column";
import { theme } from "../../../../theme";
import SkeletonStageCharts from "./SkeletonStageCharts";
import Typography from "../../../../components/Typography";
import CustomLink from "../../../../components/CustomLink";
import BoxContainer from "../BoxContainer";
import { useScreenClass } from "react-grid-system";

export interface StageChartsProps {
  data: StageChartProps[];
  loading: boolean;
  selectedTypeLabel?: string;
}

const StageCharts: FC<StageChartsProps> = ({
  data,
  loading,
  selectedTypeLabel,
}) => {
  const screenClass = useScreenClass();

  const useFullHeight = useMemo(() => {
    return (
      screenClass === "xl" || screenClass === "xxl" || screenClass === "xxxl"
    );
  }, [screenClass]);

  if (loading) return <SkeletonStageCharts />;
  return (
    <BoxContainer
      backgroundColor={theme.colors.Grey[20]}
      useFullHeight={useFullHeight}
    >
      <Row justify="between" align="center">
        <Column xs={6}>
          <Typography
            variant="textExtraLarge"
            uppercase
            component="div"
            align="left"
            weight="bold"
          >
            Seguro {selectedTypeLabel || "Rural"}
          </Typography>
        </Column>
        <Column xs={6}>
          <CustomLink to={data[0].redirectLocation}>
            <Typography
              variant="textLarge"
              uppercase
              component="div"
              align="right"
              weight="bold"
            >
              Ver Todos
            </Typography>
          </CustomLink>
        </Column>
      </Row>
      <Row justify="around" align="center" noMargins>
        {data.map((d, index) => (
          <Column
            xs={12}
            md={6}
            xl={3}
            key={`stage-chart-${index}`}
            useYPaddings
          >
            <StageChart
              label={d.label}
              count={d.count}
              color={d.color}
              statuses={d.statuses}
              redirectLocation={d.redirectLocation}
              urlParam={d.urlParam}
            />
          </Column>
        ))}
      </Row>
    </BoxContainer>
  );
};

export default StageCharts;
