import { createElement, FC, memo, useMemo } from "react";
import { FormFieldV2, FormInputTypes } from "../../graphql/__GENERATED__/types";
import TextField from "./components/inputV2/TextField";
import ObjectField from "./components/inputV2/ObjectField";
import ObjectListField from "./components/inputV2/ObjectListField";
import DropdownField from "./components/inputV2/DropdownField";
import Many2manyField from "./components/inputV2/Many2manyField";
import MonetaryField from "./components/inputV2/MonetaryField";
import Many2OneField from "./components/inputV2/Many2OneField";
import NumberField from "./components/inputV2/NumberField";
import DateField from "./components/inputV2/DateField";
import FileField from "./components/inputV2/FileField";

interface FormFieldProps {
  field: FormFieldV2;
}

const FormField: FC<FormFieldProps> = memo(({ field }) => {
  const { inputType, invisible } = field;
  const inputComponent = useMemo(() => {
    if (invisible) {
      return null;
    }
    switch (inputType) {
      case FormInputTypes.TextField:
        return TextField;
      case FormInputTypes.Object:
        return ObjectField;
      case FormInputTypes.ObjectList:
        return ObjectListField;
      case FormInputTypes.Dropdown:
        return DropdownField;
      case FormInputTypes.Many2one:
        return Many2OneField;
      case FormInputTypes.Many2many:
        return Many2manyField;
      case FormInputTypes.Monetary:
        return MonetaryField;
      case FormInputTypes.Number:
        return NumberField;
      case FormInputTypes.Date:
        return DateField;
      case FormInputTypes.File:
        return FileField;
      default:
        return TextField;
    }
  }, [inputType, invisible]);

  if (!inputComponent) return <></>;

  const element = createElement<{ field: FormFieldV2 }>(inputComponent as FC, {
    field,
  });
  return element;
});

export default FormField;
