/** @jsxImportSource @emotion/react */

import { FC } from "react";
import Information from "./components/Information";
import ActionButtons from "./components/ActionButtons";

const styles = {
  container: {
    height: "100%",
  },
  information: {
    height: "50%",
  },
  actions: {
    height: "50%",
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "start",
    gap: 10,
  },
};

const PartnersActions: FC = () => {
  return (
    <div css={styles.container}>
      <div css={styles.information}>
        <Information />
      </div>
      <div css={styles.actions}>
        <ActionButtons />
      </div>
    </div>
  );
};

export default PartnersActions;
