import { useMemo } from "react";
import { ColorNames } from "../theme";
import { UserRoles } from "../graphql/__GENERATED__/types";

const useContactColor = (role: string) => {
  const color = useMemo(() => {
    switch (role) {
      case UserRoles.Consultant.toString():
        return ColorNames.GREEN;
      case UserRoles.Admin.toString():
        return ColorNames.GREEN;
      default:
        return ColorNames.LIGHTGREY;
    }
  }, [role]);
  return color;
};

export default useContactColor;
