import { useMemo } from "react";
import { DashboardFunnelDateRange } from "../../../graphql/__GENERATED__/types";

const useDashboardFunnelDates = (dateRangeEnum: DashboardFunnelDateRange) => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const currentDate = new Date().getDate();
  const currentDay = new Date().getDay();

  const range = useMemo(() => {
    let startDate;
    let endDate;
    switch (dateRangeEnum) {
      case DashboardFunnelDateRange.Year:
        startDate = new Date(currentYear, 0, 1);
        endDate = new Date(currentYear + 1, 0, 1);
        break;
      case DashboardFunnelDateRange.Month:
        startDate = new Date(currentYear, currentMonth, 1);
        endDate = new Date(currentYear, currentMonth + 1, 1);
        break;
      case DashboardFunnelDateRange.Week:
        startDate = new Date(
          currentYear,
          currentMonth,
          currentDate - currentDay
        );
        endDate = new Date(
          currentYear,
          currentMonth,
          currentDate - currentDay + 7
        );
        break;
      default:
        startDate = new Date();
        endDate = new Date();
    }
    return { startDate, endDate };
  }, [dateRangeEnum]);
  return range;
};

export default useDashboardFunnelDates;
