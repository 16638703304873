import Table from "../../../../components/Table";
import {
  ContactTags,
  useListContactsQuery,
} from "../../../../graphql/__GENERATED__/types";
import useContactDetails from "../../../../hooks/useContactDetails";
import { ColorNames } from "../../../../theme";
import { ToContactDetailsLocation } from "../../../../Locations";
import { formatCpfCnpj, formatPhone } from "../../../../utils/formatNumber";
import { tagsLabels } from "../../utils/tagsLabels";
import { useMemo } from "react";

type TableContactData = {
  _id: string;
  name: string;
  cpfCnpj: string;
  tags: string;
  email: string;
  mobilePhone: string;
};

const Subcontacts = () => {
  const { contactData } = useContactDetails();

  const { data } = useListContactsQuery({
    variables: { filter: { parentId: contactData._id! } },
  });

  const tableContactData: TableContactData[] = useMemo(() => {
    const contacts = [
      ...(data?.contacts || []),
      ...(contactData.parent ? [contactData.parent] : []),
    ];
    return contacts.map((contact) => ({
      _id: contact!._id!,
      name: contact!.name! || "Sem nome",
      cpfCnpj: contact!.cpfCnpj
        ? formatCpfCnpj(contact.cpfCnpj)
        : "Sem documento",
      tags: (contact.tags || [])
        .map((t) => tagsLabels[t as ContactTags])
        .join(", "),
      email: contact!.email! || "Sem email",
      mobilePhone: contact!.mobilePhone
        ? formatPhone(contact.mobilePhone)
        : "Sem documento",
    }));
  }, [data?.contacts, contactData.parent]);

  return (
    <Table<TableContactData>
      data={tableContactData}
      withBorder
      color={ColorNames.PINK}
      rowLink={(doc: TableContactData) => ToContactDetailsLocation(doc._id!)}
      columns={[
        {
          label: "Nome",
          key: "name",
        },
        {
          label: "CPF/CNPJ",
          key: "cpfCnpj",
        },
        {
          label: "Telefone",
          key: "mobilePhone",
        },
        {
          label: "E-mail",
          key: "email",
        },
        {
          label: "Tags",
          key: "tags",
        },
      ]}
    />
  );
};

export default Subcontacts;
