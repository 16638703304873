import { FC, useMemo, useState } from "react";
import {
  Services,
  useGetMany2XOptionsQuery,
} from "../../../graphql/__GENERATED__/types";
import useDebounce from "../../../hooks/useDebounce";
import MultiSelectInput, { MultiSelectInputProps } from "./MultiSelectInput";
import { ColorNames } from "../../../theme";

interface Many2ManyInputProps
  extends Omit<Omit<MultiSelectInputProps, "options">, "onInputChange"> {
  serviceName: Services;
  domain: any;
  color?: ColorNames;
}

const Many2ManyInput: FC<Many2ManyInputProps> = ({
  serviceName,
  domain,
  value,
  color,
  ...props
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const { debouncedValue } = useDebounce(searchTerm, 500);

  const { data, loading } = useGetMany2XOptionsQuery({
    variables: {
      serviceName: serviceName,
      domain,
      searchTerm: debouncedValue,
      currentValue: value,
    },
  });

  const options = useMemo(() => {
    return (
      data?.getMany2XOptions.map((option) => ({
        label: option.label!,
        value: option.value!,
      })) || []
    );
  }, [data?.getMany2XOptions]);

  return (
    <MultiSelectInput
      {...props}
      value={value}
      onInputChange={setSearchTerm}
      options={options}
      loading={loading}
      color={color}
    />
  );
};

export default Many2ManyInput;
