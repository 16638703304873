/** @jsxImportSource @emotion/react */

import { Dispatch, FC, SetStateAction } from "react";
import Typography from "../../../../../components/Typography";
import { ColorNames } from "../../../../../theme";
import { DocumentNavTabs } from "..";

export const notificationsHeaderHeight = 50;

const styles = {
  header: {
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    height: notificationsHeaderHeight,
    "& > span": {
      marginRight: 20,
      cursor: "pointer",
    },
  },
};

const NotificationsHeader: FC<{
  activeTab: DocumentNavTabs;
  setActiveTab: Dispatch<SetStateAction<DocumentNavTabs>>;
}> = ({ activeTab, setActiveTab }) => {
  const handleNavigate = (nav: DocumentNavTabs) => {
    switch (nav) {
      case DocumentNavTabs.NOTIFICATIONS:
        return setActiveTab(nav);
      case DocumentNavTabs.CHAT:
        return setActiveTab(nav);
      default:
        return;
    }
  };

  return (
    <div css={styles.header}>
      <Typography
        variant="textLarge"
        uppercase
        weight="bold"
        color={
          activeTab === DocumentNavTabs.NOTIFICATIONS
            ? ColorNames.GREEN
            : ColorNames.GREY
        }
        onClick={() => handleNavigate(DocumentNavTabs.NOTIFICATIONS)}
      >
        Notificações
      </Typography>
      <Typography
        variant="textLarge"
        uppercase
        weight="bold"
        color={
          activeTab === DocumentNavTabs.CHAT
            ? ColorNames.GREEN
            : ColorNames.GREY
        }
        onClick={() => handleNavigate(DocumentNavTabs.CHAT)}
      >
        Chat
      </Typography>
    </div>
  );
};

export default NotificationsHeader;
