/** @jsxImportSource @emotion/react */

import { FC } from "react";
import LeadData from "../../../../components/Details/LeadData";
import DetailsSection from "../../../../components/Details/components/Main/Section/DetailsSection";
import useLeadDetails from "../../../../hooks/useLeadDetails";

const LeadDataSection: FC = () => {
  const { leadData } = useLeadDetails();

  return (
    <DetailsSection title="Dados do Lead" main noTitle>
      <LeadData lead={leadData} />
    </DetailsSection>
  );
};

export default LeadDataSection;
