import { FC } from "react";
import { FormFieldV2 } from "../../../../graphql/__GENERATED__/types";
import Column from "../../../../components/Grid/Column";
import useFormField from "../../hooks/useFormField";
import SelectInput, {
  SelectInputOptionInterface,
} from "../../../../components/Input/SelectInputs/SelectInput";
import FormLabel from "../FormLabel";

interface DropdownFieldProps {
  field: FormFieldV2;
}

const DropdownField: FC<DropdownFieldProps> = ({ field }) => {
  const { options, value, name, label, disabled } = field;
  const { handleChange, startEditing, finishEditing, error } =
    useFormField(field);
  const handleSelect = (option: SelectInputOptionInterface) => {
    handleChange(option.value);
  };

  return (
    <Column useYPaddings>
      <FormLabel>{label.toUpperCase()}</FormLabel>
      <SelectInput
        options={options!}
        value={value}
        inputName={name}
        placeholder={label}
        onSelect={handleSelect}
        onFocus={() => startEditing()}
        onBlur={() => finishEditing()}
        errorMessage={error}
        disabled={!!disabled}
        optionDisplay="old"
      />
    </Column>
  );
};

export default DropdownField;
