/** @jsxImportSource @emotion/react */

import { FC, useMemo } from "react";
import { formatCep, formatNumber } from "../../../../../utils/formatNumber";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import DetailsSectionColumns from "../../../../../components/Details/components/Main/Grid/DetailsSectionColumns";
import EditableSection from "../../../../../components/EditableSection";
import {
  Address,
  DocumentStages,
  GetEditPropertyOnDocumentFormResponseDocument,
  GetEditPropertyOnDocumentFormResponseQueryVariables,
  Property,
} from "../../../../../graphql/__GENERATED__/types";
import useDocumentOrder from "../../../../../hooks/useDocumentOrder";
import Table from "../../../../../components/Table";
import DetailsSection from "../../../../../components/Details/components/Main/Section/DetailsSection";

interface PropertyDataProps {
  data: Property & Address;
}

const PropertyData: FC<PropertyDataProps> = ({ data: propertyData }) => {
  const {
    documentData: { _id: documentId, stage, allowedVisitPeople },
    refetch,
  } = useDocumentDetails();

  const propertyInfo = useMemo(
    () => [
      {
        label: "nome",
        value: propertyData?.name,
      },
      {
        label: "car",
        value: propertyData?.carRegistration,
      },
      {
        label: "endereço",
        value: propertyData?.address?.street,
      },
      {
        label: "cep",
        value: formatCep(propertyData?.address?.cep!),
      },
      {
        label: "cidade",
        value: propertyData?.address?.city,
      },
      {
        label: "estado",
        value: propertyData?.address?.state,
      },
    ],
    [propertyData]
  );

  const propertyCoordinates = useMemo(
    () => [
      {
        label: "latitude",
        value: propertyData?.entranceLocation?.lat
          ? formatNumber(propertyData?.entranceLocation?.lat!, 6, 6)
          : "",
      },
      {
        label: "longitude",
        value: propertyData?.entranceLocation?.lat
          ? formatNumber(propertyData?.entranceLocation?.lng!, 6, 6)
          : "",
      },
      {
        label: "roteiro de acesso",
        value: propertyData?.accessInstructions,
      },
    ],
    [propertyData]
  );

  const currentStage = useDocumentOrder(stage!);
  const policyStage = useDocumentOrder(DocumentStages.Policy);

  const editQuery = useMemo(
    () =>
      currentStage < policyStage
        ? GetEditPropertyOnDocumentFormResponseDocument
        : undefined,
    [currentStage, policyStage]
  );

  return (
    <EditableSection<{}, GetEditPropertyOnDocumentFormResponseQueryVariables>
      title="Dados da Propriedade"
      footerButtons={[]}
      createPayload={{}}
      editQuery={editQuery}
      editPayload={{
        documentId: documentId!,
        objectId: propertyData?._id!,
      }}
      objectId={propertyData?._id}
      onSubmit={refetch}
      main
    >
      <DetailsSectionColumns columns={[propertyInfo, propertyCoordinates]} />
      {allowedVisitPeople && allowedVisitPeople.length > 0 && (
        <DetailsSection
          title="Pessoas Autorizadas a Acompanhar a Vistoria"
          subtitle
        >
          <Table
            data={allowedVisitPeople!}
            columns={[
              { key: "name", label: "Nome" },
              { key: "mobilePhone", label: "Celular" },
              { key: "parentRelation", label: "Relação com o segurado" },
            ]}
          />
        </DetailsSection>
      )}
    </EditableSection>
  );
};

export default PropertyData;
