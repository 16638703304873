import { FC } from "react";
import ArrowRightIcon from "../../../../../../components/Icons/arrowRightIcon";
import Typography from "../../../../../../components/Typography";
import DetailsSection from "../../../../../../components/Details/components/Main/Section/DetailsSection";
import Row from "../../../../../../components/Grid/Row";
import useDocumentDetails from "../../../../../../hooks/useDocumentDetails";
import Button from "../../../../../../components/Button";
import { ColorNames } from "../../../../../../theme";

const FailedQuotations: FC = () => {
  const {
    documentData: { failedSimulationResults },
    handleRetrySimulationResult,
  } = useDocumentDetails();

  if (!failedSimulationResults?.length) return null;

  return (
    <DetailsSection title="Cotações com erro" subtitle>
      {failedSimulationResults.map((result, i) => (
        <Row key={`resultError-${i}`} noGutters justify="start" align="center">
          <ArrowRightIcon color="ErrorRed" style={{ paddingRight: 5 }} />
          <Typography
            variant="textMedium"
            component="span"
            color="Grey"
            align="left"
            uppercase
          >
            {result!.provider}:
            <Typography variant="textMedium" component="span">
              {" "}
              {result!.error}
            </Typography>
          </Typography>
          <Button
            style="primaryLink"
            color={ColorNames.RED}
            text="Tentar Novamente"
            width={"auto"}
            onClick={() => handleRetrySimulationResult(result?._id!)}
          />
        </Row>
      ))}
    </DetailsSection>
  );
};

export default FailedQuotations;
