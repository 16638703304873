import { useCallback } from "react";
import { useAuth } from "./useAuth";
import { Services } from "../graphql/__GENERATED__/types";
import { serviceFetchFile } from "../services/fetch";
import { openFile } from "../helpers/openFile";
import { useErrorHandler } from "./useErrorHandler";

export interface DownloadFileProps {
  serviceName: Services;
  objectId: string;
  fieldPath: string;
}

const useDownloadFile = ({
  serviceName,
  objectId,
  fieldPath,
}: DownloadFileProps) => {
  const { errorHandler } = useErrorHandler();
  const auth = useAuth();
  const handleDownloadFile = useCallback(async () => {
    try {
      return serviceFetchFile(
        auth,
        `download-file/${serviceName}/${objectId}/${fieldPath}`,
        null,
        "get"
      ).then(openFile);
    } catch (e) {
      errorHandler(new Error("Não foi baixar o arquivo."), e);
    }
  }, [auth, serviceName, objectId, fieldPath]);
  return handleDownloadFile;
};

export default useDownloadFile;
