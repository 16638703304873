/** @jsxImportSource @emotion/react */

import { FC, useEffect, useMemo, useState } from "react";
import useDebounce from "../../hooks/useDebounce";
import { TModelFilter } from "../../hooks/useModelFilter";
import { IconNames } from "../Icons/styles/iconNames";
import Icon from "../Icons";
import { ColorNames, theme } from "../../theme";
import ScrollContainer from "../ScrollContainer";
import { headerHeight } from "../Layout/components/Header";
import { totalBreadcrumbsHeight } from "../Breadcrumbs";
import useOutsideClick from "../../hooks/useOutsideClick";
import Filters from "./Filters";

const styles = {
  searchBar: {
    width: "100%",
    height: 45,
    padding: "0px 12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 25,
    transition: "all 0.2s ease-in-out",
    border: `1px solid ${theme.colors.Black[100]}`,
    boxSizing: "border-box" as "border-box",
    position: "relative" as const,
  },
  searchInput: {
    cursor: "text",
    width: "100%",
    border: "none",
    fontFamily: "inherit",
    fontSize: 16,
    "&:focus-visible": {
      outline: "none",
    },
    "&::placeholder": {
      color: `${theme.colors.Grey[100]}`,
    },
    "&:-ms-input-placeholder": {
      color: `${theme.colors.Grey[100]}`,
    },
    "&::-ms-input-placeholder": {
      color: `${theme.colors.Grey[100]}`,
    },
  },
  filters: {
    position: "absolute" as const,
    top: totalBreadcrumbsHeight,
    right: 0,
    backgroundColor: theme.colors.White[100],
    zIndex: 100,
    width: "100%",
    height: `calc(100vh - ${headerHeight + totalBreadcrumbsHeight + 40}px)`,
    padding: 24,
    boxShadow: theme.boxShadow.default,
    boxSizing: "border-box" as const,
    borderRadius: 10,
    border: "1px solid",
    borderColor: theme.colors.Black[100],
  },
  filterCount: {
    position: "absolute" as const,
    top: 0,
    right: 0,
    backgroundColor: theme.colors.Green[100],
    boxShadow: theme.boxShadow.default,
    color: theme.colors.White[100],
    borderRadius: "50%",
    width: 20,
    height: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 12,
    fontWeight: "bold",
  },
};

interface SearchBarProps {
  filter: TModelFilter;
}

const SearchBar: FC<SearchBarProps> = ({ filter }) => {
  const { searchTerm, setSearchTerm } = filter;
  const [search, setSearch] = useState(searchTerm);
  const { debouncedValue: debouncedSearch } = useDebounce(search, 1000);
  const [showFilters, setShowFilters] = useState(false);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const ref = useOutsideClick(() => {
    setShowFilters(false);
  });

  useEffect(() => {
    setSearchTerm(debouncedSearch);
  }, [debouncedSearch]);

  const filterCount = useMemo(() => {
    return filter.filters.filter((filter) => filter.value.length > 0).length;
  }, [filter.filters]);

  return (
    <div ref={ref} css={{ height: "100%", position: "relative" }}>
      <div css={styles.searchBar}>
        {filterCount > 0 && <div css={styles.filterCount}>{filterCount}</div>}
        <Icon name={IconNames.Search} color={ColorNames.BLACK} />
        <input
          type="text"
          placeholder="BUSCAR..."
          css={styles.searchInput}
          onChange={handleSearch}
          value={search}
        />
        <Icon
          name={IconNames.Filter}
          color={ColorNames.BLACK}
          onClick={() => setShowFilters(!showFilters)}
        />
      </div>
      {showFilters && (
        <div css={styles.filters}>
          <ScrollContainer height="100%">
            <Filters filter={filter} />
          </ScrollContainer>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
