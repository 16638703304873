import { AuthContextInterface } from "../contexts/auth";
import { UserObject } from "../models/User";
import serviceFetch from "./fetch";

interface UpdateUserInterface {
  email?: string;
  username?: string;
  signedDate?: Date;
  disabled?: boolean;
}

export const getCurrentUser = async (
  auth: AuthContextInterface
): Promise<{ data: UserObject }> => serviceFetch(auth, "user", null, "get");

export const getUsers = async (): // auth: AuthContextInterface
Promise<{ data: Array<UserObject> }> =>
  serviceFetch(null, "users", null, "get");

export const getOriginator = async (
  username: string
): Promise<{ data: string }> =>
  serviceFetch(null, `originator/${username}`, null, "get");

export const updateUserById = async (
  auth: AuthContextInterface,
  id: string,
  data: UpdateUserInterface
): Promise<{ data: UserObject }> =>
  serviceFetch(auth, `user/${id}`, null, "put", data);

export const signTermsById = async (
  auth: AuthContextInterface,
  id: string
): Promise<{ data: UserObject }> =>
  serviceFetch(auth, `user/${id}/sign`, null, "put");

export const validateEmailOrUsername = async (
  auth: AuthContextInterface | null,
  data: UpdateUserInterface
): Promise<{ data: UserObject }> =>
  serviceFetch(auth, "user/validate", null, "post", data);
