import { DetailsRowProps } from "../../../../../../components/Details/components/Main/Grid/DetailsRow";
import useDocumentDetails from "../../../../../../hooks/useDocumentDetails";
import { DetailsRowValueVariants } from "../../../../../../components/Details/components/Main/Grid/DetailsRowValue";
import { Services } from "../../../../../../graphql/__GENERATED__/types";
import { useMemo } from "react";

const useProposalInfo = () => {
  const {
    documentData: { proposalResult, _id },
  } = useDocumentDetails();

  const info = useMemo(() => {
    if (!proposalResult?._id) return [];
    return [
      {
        label: "ID da proposta",
        value: proposalResult.externalId,
      },
      proposalResult?.proposalFile?.fileKey && {
        label: "Proposta",
        variant: DetailsRowValueVariants.File,
        value: {
          file: {
            fileName: proposalResult.proposalFile?.fileName,
          },
          objectId: _id,
          fieldPath: "proposalFile",
          serviceName: Services.Results,
        },
      },
      proposalResult?.proposalSketchMap?.fileKey && {
        label: "Croqui da área",
        variant: DetailsRowValueVariants.File,
        value: {
          file: {
            fileName: proposalResult.proposalSketchMap?.fileName,
          },
          objectId: proposalResult._id,
          fieldPath: "proposalSketchMap",
          serviceName: Services.Results,
        },
      },
    ].filter(Boolean) as DetailsRowProps[];
  }, [proposalResult, _id]);

  return info;
};

export default useProposalInfo;
