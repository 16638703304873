import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Form, { TDefaultValues } from "../../../modules/Forms";
import {
  ToDocumentDetailsLocation,
  ToSimulationLocation,
} from "../../../Locations";
import { useAuth } from "../../../hooks/useAuth";
import {
  useCreateFormResponseMutation,
  UserRoles,
} from "../../../graphql/__GENERATED__/types";

const NewSimulation = () => {
  const { formId, docId } = useParams();
  const authState = useAuth();
  const navigate = useNavigate();

  const [createFormResponseMutation] = useCreateFormResponseMutation();
  const { user } = useAuth();

  useEffect(() => {
    if (formId && docId === "new" && authState.loaded) {
      asyncCreateFormResponse(formId);
    }
  }, [formId, docId, authState.loaded]);

  const asyncCreateFormResponse = async (formId: string) => {
    try {
      const { data } = await createFormResponseMutation({
        variables: {
          formId: formId!,
          metadata: {
            originatorId:
              user.role === UserRoles.Consultant ? user.contactId : null,
          },
        },
      });
      navigate(ToSimulationLocation(data!.createFormResponse!._id!, formId), {
        replace: true,
      });
    } catch (e) {
      console.log("error", e);
    }
  };

  const onSubmit = async ({ documentId }: any) => {
    navigate(ToDocumentDetailsLocation(documentId));
  };

  const defaultValues: TDefaultValues = {};
  if (user && user._id) {
    defaultValues.userInfo = { originatorId: user._id };
  }

  return (
    <>
      {docId && formId && docId !== "new" && (
        <Form
          formId={formId}
          docId={docId}
          onSubmit={onSubmit}
          // defaultValues={defaultValues}
        />
      )}
    </>
  );
};

export default NewSimulation;
