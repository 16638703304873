/** @jsxImportSource @emotion/react */

import { FC } from "react";
import Row from "../../../../../../components/Grid/Row";
import Column from "../../../../../../components/Grid/Column";
import { IconSizes } from "../../../../../../components/Icons/styles/iconSizes";
import SkeletonTypography from "../../../../../../components/Skeletons/components/SkeletonTypography";

export const chartTitleHeight = 40;

const styles = {
  row: {
    margin: 0,
    height: `calc(100% - ${chartTitleHeight}px)`,
  },
  col: {
    minWidth: IconSizes.Medium,
    height: "100%",
  },
};

const SkeletonChart: FC = () => {
  const skeletonData = Array(4).fill({});
  return (
    <Row noGutters noWrap align="end" justify="center" css={styles.row}>
      {skeletonData.map((_, index) => (
        <Column xs={2} key={`chart-bar-${index}`} css={styles.col}>
          <SkeletonTypography variant="textLarge" />
        </Column>
      ))}
    </Row>
  );
};

export default SkeletonChart;
