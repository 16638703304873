import { useMemo } from "react";
import { ColorNames } from "../theme";
import {
  DocumentClaimStatus,
  DocumentStages,
} from "../graphql/__GENERATED__/types";

const useDocumentColor = (stage: DocumentStages | DocumentClaimStatus) => {
  const color = useMemo(() => {
    switch (stage) {
      case DocumentStages.Simulation:
        return ColorNames.GREEN;
      case DocumentStages.Quotation:
        return ColorNames.GREEN;
      case DocumentStages.Proposal:
        return ColorNames.PINK;
      case DocumentStages.Policy:
        return ColorNames.BLUE;
      case DocumentClaimStatus.Open:
      case DocumentClaimStatus.Closed:
        return ColorNames.ORANGE;
      default:
        return ColorNames.LIGHTGREY;
    }
  }, [stage]);
  return color;
};

export default useDocumentColor;
