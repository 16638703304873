/** @jsxImportSource @emotion/react */

import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import { useUIStore } from "../../hooks/useUIStore";
import { ToDirectSimulationLocation } from "../../Locations";
import { getOriginator } from "../../services/user";

const styles = {
  container: {
    height: "100vh",
    margin: "auto",
  },
};

const SharedForm: FC = () => {
  const { setIsLoading } = useUIStore();
  const { username } = useParams();
  const navigate = useNavigate();

  const setOriginatorData = async () => {
    setIsLoading(true);
    const originatorResponse = await getOriginator(username!);
    setIsLoading(false);
    if (originatorResponse.data) {
      localStorage.setItem("originador", originatorResponse.data);
      navigate(ToDirectSimulationLocation("new", "simulacao"));
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    setOriginatorData();
  }, []);

  return (
    <div css={styles.container}>
      <Loading size="large" />
    </div>
  );
};

export default SharedForm;
