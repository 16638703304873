/** @jsxImportSource @emotion/react */

import { FC, MouseEventHandler, useMemo } from "react";
import { ColorNames, getThemeColorFromAlias, theme } from "../../theme";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import { LogoSizes } from "./styles/logoSizes";
import { LogoByName } from "./styles/logoNames";
import { PropostaProviders } from "../../graphql/__GENERATED__/types";

interface ProviderLogoProps {
  name: PropostaProviders;
  size?: LogoSizes;
  color?: ColorNames;
  hoverColor?: ColorNames;
  onClick?: MouseEventHandler<HTMLSpanElement>;
  disabled?: boolean;
}

const getCSSRules: CSSRulesResolver<{
  onClick?: MouseEventHandler<HTMLSpanElement>;
  size: LogoSizes;
  themeColor: string;
  hoverThemeColor: string;
}> = ({ size, themeColor, hoverThemeColor, onClick }) => ({
  cursor: onClick ? "pointer" : "auto",
  display: "inline-flex",
  "& > svg": {
    width: 2 * size,
    height: size,
  },
  "& > span > svg": {
    width: size,
    height: size,
    strokeWidth: 3,
  },
  "& path": {
    fill: themeColor,
  },
  "&:hover path": {
    fill: hoverThemeColor,
  },
});

const ProviderLogo: FC<ProviderLogoProps> = ({
  name,
  size = LogoSizes.Medium,
  color = ColorNames.BLACK,
  hoverColor,
  onClick,
  disabled = false,
}) => {
  const themeColor = disabled
    ? getThemeColorFromAlias(ColorNames.GREY, theme.colors)!
    : getThemeColorFromAlias(color, theme.colors)!;
  const hoverThemeColor = disabled
    ? getThemeColorFromAlias(ColorNames.GREY, theme.colors)!
    : getThemeColorFromAlias(hoverColor || color, theme.colors)!;

  const styles = useCSSRulesWithTheme(getCSSRules, {
    themeColor,
    hoverThemeColor,
    size,
    onClick,
  });

  const Logo = useMemo(() => {
    return LogoByName[name]({});
  }, [name]);

  const handleLogoClick = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    if (!onClick) return;
    e.stopPropagation();
    onClick(e);
  };

  return (
    <span css={styles} onClick={handleLogoClick}>
      {Logo}
    </span>
  );
};

export default ProviderLogo;
