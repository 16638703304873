import { FC } from "react";
import { FormSectionV2 } from "../../graphql/__GENERATED__/types";
import FormSection from "./form.section";

interface FormSectionsProps {
  sections: FormSectionV2[];
}

const FormSections: FC<FormSectionsProps> = ({ sections }) => {
  return (
    <div>
      {sections.map((section) => {
        return <FormSection key={section.id} section={section} />;
      })}
    </div>
  );
};

export default FormSections;
