/** @jsxImportSource @emotion/react */

import { FC, Fragment, useState } from "react";
import { RatingScale } from "../../graphql/__GENERATED__/types";
import { IconNames } from "../Icons/styles/iconNames";
import Icon from "../Icons";
import { ColorNames } from "../../theme";
import { getEnumKeys } from "../../utils/getEnumKeys";
import Typography from "../Typography";
import useIsMobile from "../../hooks/useIsMobile";

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap" as const,
  },
  stars: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    flexWrap: "nowrap" as const,
    flex: 1,
  },
};

const ratingScale = {
  [RatingScale.Poor]: 0,
  [RatingScale.Unsatisfactory]: 1,
  [RatingScale.Satisfactory]: 2,
  [RatingScale.VerySatisfactory]: 3,
  [RatingScale.Outstanding]: 4,
};

interface RatingStarsProps {
  rating: RatingScale | undefined;
  onClick: (value: RatingScale) => void;
  color?: ColorNames;
}

const RatingStars: FC<RatingStarsProps> = ({ rating, onClick, color }) => {
  const isMobile = useIsMobile();
  const [currentRating, setCurrentRating] = useState<number | undefined>(
    rating ? ratingScale[rating] : undefined
  );

  const handleRatingChange = (newRating: number) => {
    setCurrentRating(newRating);
    onClick(getEnumKeys(ratingScale)[newRating] as RatingScale);
  };

  return (
    <div css={styles.container}>
      {!isMobile && (
        <Typography variant="textMedium" color={color}>
          Fraco
        </Typography>
      )}
      <div css={styles.stars}>
        {Object.keys(ratingScale).map((key) => (
          <Fragment key={`rating-${key}`}>
            <Icon
              name={
                currentRating !== undefined &&
                currentRating >= ratingScale[key as unknown as RatingScale]
                  ? IconNames.StarFilled
                  : IconNames.Star
              }
              color={color ? color : ColorNames.GREEN}
              onClick={() =>
                handleRatingChange(ratingScale[key as unknown as RatingScale])
              }
            />
          </Fragment>
        ))}
      </div>
      {!isMobile && (
        <Typography variant="textMedium" color={color}>
          Excelente
        </Typography>
      )}
    </div>
  );
};

export default RatingStars;
