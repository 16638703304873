import { Outlet, useNavigate } from "react-router-dom";
import CardList from "../../components/CardList";
import { useUIStore } from "../../hooks/useUIStore";
import MainFrame from "../../components/MainFrame";
import { useListLeadsQuery } from "../../graphql/__GENERATED__/types";
import { LeadsLocation, ToLeadDetailsLocation } from "../../Locations";
import Layout from "../../components/Layout";
import useLeadsFilter from "../../hooks/useLeadsFilter";
import LeadCard from "./LeadCard";

const Leads = () => {
  const navigate = useNavigate();
  const { toggleDrawerSize } = useUIStore();

  const leadFilters = useLeadsFilter();

  const { data, loading } = useListLeadsQuery({
    variables: {
      filter: leadFilters.composeQuery(),
    },
  });

  return (
    <Layout>
      <MainFrame
        drawerContent={<Outlet />}
        onClose={() => navigate(LeadsLocation)}
        onToggle={toggleDrawerSize}
        filter={leadFilters}
      >
        <CardList
          data={data?.leads || []}
          redirectLocation={ToLeadDetailsLocation}
          CardComponent={LeadCard}
          loading={loading}
        />
      </MainFrame>
    </Layout>
  );
};

export default Leads;
