/** @jsxImportSource @emotion/react */

import moment from "moment";
import { ObjectNotifications } from "../../../../../../../graphql/__GENERATED__/types";
import { ColorNames, theme } from "../../../../../../../theme";
import { FC, useMemo } from "react";
import CustomLink from "../../../../../../../components/CustomLink";
import Icon from "../../../../../../../components/Icons";
import { IconNames } from "../../../../../../../components/Icons/styles/iconNames";
import { IconSizes } from "../../../../../../../components/Icons/styles/iconSizes";
import Typography from "../../../../../../../components/Typography";
import Row from "../../../../../../../components/Grid/Row";
import Column from "../../../../../../../components/Grid/Column";
import { isToday } from "../../../../../../../utils/dateUtils";

interface NotificationProps {
  notification: ObjectNotifications;
  redirectLocation: (docId: string) => string;
}

const styles = {
  container: {
    width: "100%",
    marginBottom: 6,
    cursor: "pointer",
    transition: "background-color 0.5s ease",
    ":hover": {
      backgroundColor: theme.colors.Grey[20],
    },
    borderRadius: 5,
    padding: 12,
  },
};

type NotificationData = {
  message: string[];
  date: string;
  objectId: string;
  originInfo: string;
  color: ColorNames;
  author?: string | null;
};

const Notification: FC<NotificationProps> = ({
  notification,
  redirectLocation,
}) => {
  const notificationGroupType = useMemo(() => {
    if (notification?.notificationGroup.includes("-messages")) {
      return "messages";
    }
    return "actions";
  }, [notification?.notificationGroup]);

  const data = useMemo<NotificationData>(() => {
    return {
      message: [
        notificationGroupType === "messages"
          ? `${notification.groupNotifications.length} Nova${
              notification.groupNotifications.length > 1 ? "s" : ""
            } Nota${notification.groupNotifications.length > 1 ? "s" : ""}`
          : notification.groupNotifications[0].text,
      ],
      date: moment(notification.groupNotifications[0].createdAt).format(
        "HH:mm"
      ),
      objectId: notification.groupNotifications[0].objectId,
      originInfo: notification.groupNotifications[0].originInfo,
      author:
        notification.groupNotifications[0].author?.name === "Prisma Bot"
          ? ""
          : notification.groupNotifications[0].author?.name?.split(" ")[0],
      color: isToday(notification.groupNotifications[0].createdAt)
        ? ColorNames.BLUE
        : ColorNames.BLACK,
    };
  }, [notification]);

  const redirect = useMemo(() => {
    return redirectLocation(
      `${data.objectId}/${
        notification?.notificationGroup.includes("-messages") ? "notas" : ""
      }`
    );
  }, [notification?.notificationGroup, redirectLocation]);

  if (!data) return null;

  return (
    <CustomLink to={redirect}>
      <Row justify="between" css={styles.container}>
        <Column xs={1}>
          <Icon
            name={IconNames.AvatarFilled}
            size={IconSizes.ExtraSmall}
            color={data.color}
          />
        </Column>
        <Column xs={9}>
          {data.message.map((m, i) => (
            <Typography
              variant="textMedium"
              weight="bold"
              component="div"
              align="left"
              key={`${data.objectId}-${i}`}
            >
              {m}
              {data.author && (
                <Typography variant="textMedium" component="span" align="left">
                  {notificationGroupType === "messages" ? " de " : " por "}
                  {data.author}
                </Typography>
              )}
            </Typography>
          ))}
          <Typography variant="textMedium" component="div" align="left">
            {data.originInfo}
          </Typography>
        </Column>
        <Column xs={2}>
          <Typography variant="textSmall" color={data.color}>
            {data.date}
          </Typography>
        </Column>
      </Row>
    </CustomLink>
  );
};

export default Notification;
