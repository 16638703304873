/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { ColorAlias, getThemeColorFromAlias, theme } from "../../theme";

interface UploadIconProps {
  color?: ColorAlias;
}

const UploadIcon: FC<UploadIconProps> = ({ color }) => {
  const iconColor = color
    ? getThemeColorFromAlias(color, theme.colors)
    : theme.colors.Green[100];

  return (
    <svg
      width="30"
      height="20"
      viewBox="0 0 28 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 26L27 26"
        stroke={iconColor}
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M14.7071 0.292893C14.3166 -0.0976311 13.6834 -0.0976311 13.2929 0.292893L6.92893 6.65685C6.53841 7.04738 6.53841 7.68054 6.92893 8.07107C7.31946 8.46159 7.95262 8.46159 8.34315 8.07107L14 2.41421L19.6569 8.07107C20.0474 8.46159 20.6805 8.46159 21.0711 8.07107C21.4616 7.68054 21.4616 7.04738 21.0711 6.65685L14.7071 0.292893ZM13 20C13 20.5523 13.4477 21 14 21C14.5523 21 15 20.5523 15 20H13ZM13 1L13 20H15L15 1L13 1Z"
        fill={iconColor}
      />
    </svg>
  );
};

export default UploadIcon;
