import useModelFilter from "./useModelFilter";
import useUrlFilter from "./useUrlFilter";
import contactFilters from "../filters/contacts";

const useContactsFilter = () => {
  const filters = useModelFilter(contactFilters, []);

  useUrlFilter(filters);

  return filters;
};

export default useContactsFilter;
