import { UIStore } from "./../contexts/ui";
import { ErrorStore } from "./../contexts/errorHandler";
import { useMemo } from "react";
import { AuthContextInterface } from "../contexts/auth";
import { QuotationsStore } from "../contexts/_DEPRECATED";
import { useAuth } from "./useAuth";
import { useUIStore } from "./useUIStore";
import { useQuotations } from "./_DEPRECATED";
import { useErrorHandler } from "./useErrorHandler";

export interface RootStore {
  authStore: AuthContextInterface;
  quotationsStore: QuotationsStore;
  uiStore: UIStore;
  errorHandler: ErrorStore;
}

export function useRootStore() {
  const authStore = useAuth();
  const quotationsStore = useQuotations();
  const uiStore = useUIStore();
  const errorHandler = useErrorHandler();

  if (
    authStore.bearerToken &&
    authStore.tokenPayload.user &&
    !authStore.user._id
  ) {
    authStore.loadCurrentUser();
  }

  const rootStore = useMemo(
    () => ({
      authStore,
      quotationsStore,
      uiStore,
      errorHandler,
    }),
    [authStore, quotationsStore, uiStore, errorHandler]
  );

  return rootStore;
}
