import * as locations from "../Locations";

export const locationLabels: { [key: string]: string } = {
  [locations.HomeLocation]: "Home",
  [locations.DocumentsDashboardLocation]: "Seguros",
  [locations.PartnersDashboardLocation]: "Parceiros",
  [locations.CoorporativeDashboardLocation]: "Coorporativo",
  [locations.DocumentsLocation]: "Documentos",
  [locations.ConsultantsLocation]: "Contatos",
  [locations.LeadsLocation]: "Leads",
  [locations.DocumentDetailsLocation]: "Documento",
  [locations.ConsultantDetailsLocation]: "Contato",
  [locations.LeadDetailsLocation]: "Lead",
  [locations.DocumentDetailsNotesTabLocation]: "Notas do Documento",
  [locations.DocumentDetailsChatTabLocation]: "Chat do Documento",
  [locations.ConsultantDetailsNotesTabLocation]: "Notas do Contato",
  [locations.ConsultantDetailsChatTabLocation]: "Chat do Contato",
  [locations.LeadDetailsNotesTabLocation]: "Notas do Lead",
  [locations.LeadDetailsChatTabLocation]: "Chat do Lead",
  [locations.SimulationLocation]: "Simulação",
  [locations.DirectSimulationLocation]: "Simulação",
  [locations.DirectQuotationFormLocation]: "Cotação",
  [locations.LeadFormLocation]: "Novo lead",
  [locations.TermsAndConditionsLocation]: "Termos e condições",
  [locations.LoginLocation]: "Login",
  [locations.NfeUploadFormLocation]: "Anexar notas fiscais",
  [locations.RegisterLocation]: "Cadastro",
  [locations.PendingRegisterLocation]: "Cadastro pendente",
  [locations.SwissSettingsLocation]: "Configurações Swiss",
  [locations.ContactsLocation]: "Produtores",
  [locations.ContactDetailsLocation]: "Produtor",
  [locations.ContactDetailsChatLocation]: "Chat",
  [locations.KmlUploadFormLocation]: "Upload do KML",
};
