import { Meeting } from "../graphql/__GENERATED__/types";
import { DocumentsObject } from "./Document";
import { NotificationsObject } from "./Notifications";
import { TasksObject } from "./Tasks";
import { UserObject } from "./User";

export enum ModelNames {
  DOCUMENTS = "documentos",
  PARTNERS = "users",
  NOTIFICATIONS = "notificacoes",
  TASKS = "tarefas",
  MEETINGS = "conversas",
}

export const ModelLabels = {
  [ModelNames.DOCUMENTS]: "Documentos",
  [ModelNames.PARTNERS]: "Consultores",
  [ModelNames.NOTIFICATIONS]: "Notificações",
  [ModelNames.TASKS]: "Tarefas",
  [ModelNames.MEETINGS]: "Conversas",
};

export interface TModelObjects {
  [ModelNames.DOCUMENTS]: DocumentsObject;
  [ModelNames.PARTNERS]: UserObject;
  [ModelNames.NOTIFICATIONS]: NotificationsObject;
  [ModelNames.TASKS]: TasksObject;
  [ModelNames.MEETINGS]: Meeting;
}
