/** @jsxImportSource @emotion/react */

import { FC, useState } from "react";
import {
  Exact,
  GetTwilioConversationsQuery,
  useGetOrCreateContactByPhoneMutation,
  useOnConversationUpdatedSubscription,
  useOnMsgSeenUpdatedSubscription,
} from "../../../graphql/__GENERATED__/types";
import moment from "moment";
import { ColorNames, theme } from "../../../theme";
import Typography from "../../Typography";
import Row from "../../Grid/Row";
import Column from "../../Grid/Column";
import CustomLink from "../../CustomLink";
import { useNavigate } from "react-router-dom";
import { IconNames } from "../../Icons/styles/iconNames";
import Icon from "../../Icons";
import TextInput from "../../Input/TextInput";
import { containsOnlyNumbers, formatPhone } from "../../../utils/formatNumber";
import { IconSizes } from "../../Icons/styles/iconSizes";
import { useAuth } from "../../../hooks/useAuth";
import { QueryResult } from "@apollo/client";
import { CSSObject } from "@emotion/react";
import { ToContactConversationLocation } from "../../../Locations";
import useDebounce from "../../../hooks/useDebounce";
import { playNotificationSound } from "./utils/playNotificationSound";

const styles: CSSObject = {
  main: {
    display: "flex",
    justifyContent: "start",
    flexDirection: "column",
  },
  conversationCard: {
    borderTop: `1px solid ${theme.colors.Grey[20]}`,
    width: "100%",
    textAlign: "left",
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 4px",
    cursor: "pointer",
    "&:last-of-type": {
      borderBottom: `1px solid ${theme.colors.Grey[20]}`,
    },
    textDecoration: "none",
    color: "inherit",
    position: "relative" as any,
  },
  selectedConversationCard: {
    backgroundColor: theme.colors.Green[20],
  },
  newContactInputRow: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    height: "60px",
    alignItems: "center",
    marginBottom: "8px",
  },
  createContactButtonRow: {
    width: "100%",
    textAlign: "center",
  },
  createContactButton: {
    padding: "8px",
    "&:hover": {
      color: theme.colors.Blue[60],
    },
  },

  rightColumn: {
    textAlign: "center",
    display: "flex",
    alignItems: "end",
    flexDirection: "column",
    justifyContent: "end",
  },

  notSeenCount: {
    marginTop: 10,
    width: 20,
    height: 20,
    borderRadius: "50%",
    zIndex: 1,
    backgroundColor: theme.colors.Green[100],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 12,
    color: theme.colors.White[100],
    fontWeight: "bold",
    marginLeft: 10,
  },
};

const ChatList: FC<{
  selectedContactId?: string;
  query: QueryResult<
    GetTwilioConversationsQuery,
    Exact<{
      [key: string]: never;
    }>
  >;
}> = ({ selectedContactId, query }) => {
  const { data, refetch } = query;
  const auth = useAuth();

  useOnConversationUpdatedSubscription({
    onData: ({ data: { data: subData } }) => {
      if (subData?.conversationUpdated) {
        refetch();
        if (subData?.conversationUpdated?.newMsg) {
          playNotificationSound();
        }
      }
    },
  });

  useOnMsgSeenUpdatedSubscription({
    onData: ({ data: { data: subData } }) => {
      if (subData?.msgSeenUpdated?.userId === auth.user._id) {
        refetch();
      }
    },
  });

  const [searchTerm, setSearchTerm] = useState("");
  const { debouncedValue: debouncedSearchTerm } = useDebounce(searchTerm, 500);

  const navigate = useNavigate();

  const [getOrCreateContactByPhoneMutation] =
    useGetOrCreateContactByPhoneMutation();

  const getOrCreateContactByPhone = async () => {
    const contact = await getOrCreateContactByPhoneMutation({
      variables: {
        mobilePhone: debouncedSearchTerm,
      },
    });

    navigate(
      ToContactConversationLocation(
        contact.data!.getOrCreateContactByPhone._id!
      )
    );
  };

  let filteredConversations = data?.getTwilioConversations || [];
  if (debouncedSearchTerm) {
    filteredConversations =
      data?.getTwilioConversations.filter(
        (conv) =>
          conv.contact.mobilePhone?.includes(debouncedSearchTerm || "") ||
          conv.contact.name
            ?.toLowerCase()
            .includes(debouncedSearchTerm.toLowerCase() || "")
      ) || [];
  }

  const shouldShowCreateButton =
    containsOnlyNumbers(debouncedSearchTerm) &&
    filteredConversations.length === 0 &&
    [11, 10].includes(debouncedSearchTerm?.length);

  return (
    <div css={styles.main}>
      <div css={styles.newContactInputRow}>
        <Column xs={10}>
          <TextInput
            placeholder="Telefone ou nome"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Column>
        <Column xs={2} css={{ marginLeft: "8px" }}>
          <Icon
            name={IconNames.Search}
            color={ColorNames.BLACK}
            size={IconSizes.Large}
          />
        </Column>
      </div>
      {shouldShowCreateButton && (
        <div css={styles.createContactButtonRow}>
          <Typography
            variant="link"
            onClick={getOrCreateContactByPhone}
            css={styles.createContactButton}
          >
            Criar '{debouncedSearchTerm}'
          </Typography>
        </div>
      )}
      {filteredConversations.map((conv) => (
        <CustomLink
          to={ToContactConversationLocation(conv.contact._id!)}
          css={[
            styles.conversationCard,
            selectedContactId == conv.contact._id
              ? styles.selectedConversationCard
              : undefined,
          ]}
          key={conv.contact._id!}
        >
          <Column xs={7} lg={7}>
            <Row noGutters noWrap css={{ margin: "0px" }}>
              <Typography
                variant="textMedium"
                color="Black"
                weight="bold"
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  padding: "4px",
                }}
              >
                {formatPhone(conv.contact.mobilePhone!)}
                {conv.contact.name ? ` - ${conv.contact.name}` : ""}
              </Typography>
            </Row>
            <Row css={{ margin: "0px" }} noGutters noWrap>
              <Typography
                variant="textMedium"
                color="Grey"
                weight="light"
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  padding: "4px",
                }}
              >
                {conv.lastMsg.content ||
                  (conv.lastMsg.medias
                    ? "Midia enviada"
                    : "Mensagem template enviada")}
              </Typography>
            </Row>
          </Column>
          <Column xs={5} lg={5} css={styles.rightColumn}>
            <Typography variant="textMedium" color="Green">
              {moment(conv.lastMsg.sentAt).format("DD/MM/YY HH:mm")}
            </Typography>
            {Boolean(conv.notSeenCount) && (
              <div css={styles.notSeenCount}>{conv.notSeenCount}</div>
            )}
          </Column>
        </CustomLink>
      ))}
    </div>
  );
};

export default ChatList;
