import { UserObject } from "../models/User";
import serviceFetch from "./fetch";
import { AuthContextInterface } from "../contexts/auth";

export const getPartnersByRequest = async (
  auth: AuthContextInterface,
  request: string
): Promise<{ data: Array<UserObject> }> =>
  serviceFetch(auth, `users/?${request}`, null, "get");

export const getPartnerById = async (
  auth: AuthContextInterface,
  id: string
): Promise<{ data: UserObject }> =>
  serviceFetch(auth, `user/${id}`, null, "get");

export const approvePartnerById = async (
  auth: AuthContextInterface,
  id: string
): Promise<{ data: UserObject }> =>
  serviceFetch(auth, `user/${id}/approve`, null, "put");

export const rejectPartnerById = async (
  auth: AuthContextInterface,
  id: string,
  rejectReason: string
): Promise<{ data: UserObject }> =>
  serviceFetch(auth, `user/${id}/reject`, null, "put", { rejectReason });

export const disablePartnerById = async (
  auth: AuthContextInterface,
  id: string
): Promise<{ data: UserObject }> =>
  serviceFetch(auth, `user/${id}/disable`, null, "put");

export const enablePartnerById = async (
  auth: AuthContextInterface,
  id: string
): Promise<{ data: UserObject }> =>
  serviceFetch(auth, `user/${id}/enable`, null, "put");

export const retryPartnerById = async (
  auth: AuthContextInterface,
  id: string
): Promise<{ data: UserObject }> =>
  serviceFetch(auth, `user/${id}/retry`, null, "put", {});

export const countPartners = async (
  auth: AuthContextInterface,
  filter: any
): Promise<{ data: number }> =>
  serviceFetch(auth, `users/count`, null, "post", { filter });
