import { TInputMasks } from "../modules/Forms/models/formInterfaces";

// format string to number
export const formatNumber = (number: any, minimum: number, maximum: number) => {
  return Number(number).toLocaleString("pt-BR", {
    minimumFractionDigits: minimum,
    maximumFractionDigits: maximum,
  });
};

export const formatPrice = (number: any) => {
  return number.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
};

export const transformMapfreNumber = (number: string) => {
  return parseFloat(number.replace(",", "."));
};

export const formatCep = (cep?: string): string => {
  cep = cep || "";
  if (cep.length > 5) {
    return `${cep.slice(0, 5)}-${cep.slice(5)}`;
  }
  return cep;
};

export const formatPhone = (phone: string) => {
  if (!phone) return "";
  phone = phone.replace(/\D/g, "");
  phone = phone.replace(/(\d{2})(\d)/, "($1) $2");
  phone = phone.replace(/(\d)(\d{4})$/, "$1-$2");
  return phone;
};

const formatCnpj = (value: string) => {
  //Coloca ponto entre o segundo e o terceiro dígitos
  value = value.replace(/^(\d{2})(\d)/, "$1.$2");
  //Coloca ponto entre o quinto e o sexto dígitos
  value = value.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
  //Coloca uma barra entre o oitavo e o nono dígitos
  value = value.replace(/\.(\d{3})(\d)/, ".$1/$2");
  //Coloca um hífen depois do bloco de quatro dígitos
  value = value.replace(/(\d{4})(\d)/, "$1-$2");
  return value;
};

const formatRg = (value: string) => {
  value = value.replace(/^(\d{2})(\d)/, "$1.$2");
  value = value.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
  value = value.replace(/\.(\d{3})(\d)/, ".$1-$2");
  return value;
};

const formatCpf = (value: string) => {
  //Coloca um ponto entre o terceiro e o quarto dígitos
  value = value.replace(/(\d{3})(\d)/, "$1.$2");
  //Coloca um ponto entre o terceiro e o quarto dígitos
  value = value.replace(/(\d{3})(\d)/, "$1.$2");
  //Coloca um hífen entre o terceiro e o quarto dígitos
  value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  return value;
};

export const formatCpfCnpj = (value: string) => {
  if (!value) {
    return "";
  }
  return value.length <= 11 ? formatCpf(value) : formatCnpj(value);
};

export const parseMask = (text: string): string => {
  return text.replace(/\D/g, "");
};

export const formatInputMask = (
  value: string,
  mask: TInputMasks | undefined
): string => {
  switch (mask) {
    case "cep":
      return formatCep(value);
    case "phone":
      return formatPhone(value);
    case "cpfCnpj":
      return formatCpfCnpj(value);
    case "rg":
      return formatRg(value);
    default:
      return value;
  }
};

export const decimalCoordToGMSValue = (coord: number) => {
  const coordG = Math.trunc(coord).toString();
  const coordM = Math.trunc((Math.abs(coord) % 1) * 60).toString();
  const coordS = formatNumber((((Math.abs(coord) % 1) * 60) % 1) * 60, 3, 3);
  return { coordG, coordM, coordS };
};

export const GMSToDecimalCoordValue = (coord: {
  coordG: string;
  coordM: string;
  coordS: string;
}) => {
  const sign = parseInt(coord.coordG) < 0 ? -1 : 1;
  const coordG = parseInt(coord.coordG);
  const coordM = parseInt(coord.coordM);
  const coordS = parseFloat(coord.coordS.replace(",", "."));
  return sign * (Math.abs(coordG) + coordM / 60 + coordS / 3600);
};

export const containsOnlyNumbers = (stringValue: string) => {
  return /^\d+$/.test(stringValue);
};
