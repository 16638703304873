/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { theme } from "../../../theme";
import SkeletonCardContainer from "../../../components/Skeletons/components/SkeletonContainer";

interface SkeletonMessagesContainerProps {
  children: React.ReactNode;
  service: "task" | "notification";
}

const styles = {
  taskContainer: {
    borderTop: `2px solid ${theme.colors.LightGrey[100]}`,
    borderBottom: `2px solid ${theme.colors.LightGrey[100]}`,
    padding: 20,
    backgroundColor: "#fff",
    boxSizing: "border-box" as "border-box",
    height: "100%",
    width: "100%",
    minWidth: 250,
    boxShadow: theme.boxShadow.default,
  },
};

const SkeletonMessagesContainer: FC<SkeletonMessagesContainerProps> = ({
  children,
  service,
}) => {
  if (service === "task") {
    return <div css={styles.taskContainer}>{children}</div>;
  }

  return <SkeletonCardContainer>{children}</SkeletonCardContainer>;
};

export default SkeletonMessagesContainer;
