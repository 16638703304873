import { FC, useMemo } from "react";
import useDocumentDetails from "../../../../../hooks/useDocumentDetails";
import Table from "../../../../../components/Table";
import DetailsSection from "../../../../../components/Details/components/Main/Section/DetailsSection";
import {
  DocumentEquipment,
  Equipment,
} from "../../../../../graphql/__GENERATED__/types";
import EquipmentData from "./EquipmentData";

const EquipmentsTable: FC = () => {
  const {
    documentData: { equipments },
  } = useDocumentDetails();

  const propertiesList = useMemo(() => {
    if (!equipments) return [];
    return equipments.map(
      ({ equipment, ...docEquipment }) =>
        ({
          ...equipment,
          ...docEquipment,
        } as DocumentEquipment & Equipment)
    );
  }, [equipments]);

  return (
    <DetailsSection title="Equipamentos">
      <Table
        data={propertiesList}
        columns={[
          {
            label: "Tipo",
            key: "type",
          },
          {
            label: "Marca",
            key: "brand",
          },
          {
            label: "Modelo",
            key: "model",
          },
          {
            label: "Ano de Fabricação",
            key: "manufactureYear",
          },
          {
            label: "Propriedade",
            key: "property",
            special: "many2one",
          },
        ]}
        DetailsComponent={EquipmentData}
      />
    </DetailsSection>
  );
};

export default EquipmentsTable;
