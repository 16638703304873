/** @jsxImportSource @emotion/react */

import { FC, ReactNode } from "react";
import Typography from "../../../../../components/Typography";
import ScrollContainer from "../../../../../components/ScrollContainer";
import SkeletonDashboardMessages from "../../SkeletonDashboardMessages";
import CustomContainer from "../../../../../components/Grid/CustomContainer";

interface TasksContainerProps {
  count: number;
  children: ReactNode;
  loading?: boolean;
}

const tasksHeaderHeight = 70;

const styles = {
  header: {
    display: "flex" as "flex",
    alignItems: "center" as "center",
    justifyContent: "space-between" as "space-between",
    height: tasksHeaderHeight,
  },
};

const TasksContainer: FC<TasksContainerProps> = ({
  count,
  children,
  loading = false,
}) => {
  return (
    <>
      {loading && <SkeletonDashboardMessages service="task" />}
      {!loading && (
        <CustomContainer horizontal>
          <Typography
            variant="textLarge"
            color="Green"
            uppercase
            component="div"
            align="left"
            weight="bold"
            css={styles.header}
          >
            {`Tarefas [${count}]`}
          </Typography>
          <ScrollContainer height={`calc(100% - ${tasksHeaderHeight}px)`}>
            {children}
          </ScrollContainer>
        </CustomContainer>
      )}
    </>
  );
};

export default TasksContainer;
