import { Helmet } from "react-helmet-async";

export const getPageTitle = (title?: string) =>
  title ? title + " | Prisma Agro" : "Prisma Agro";

const PageTitle = ({ title }: { title?: string }) => {
  return <Helmet title={getPageTitle(title)} />;
};

export default PageTitle;
