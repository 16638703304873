import { AuthContextInterface } from "../contexts/auth";
import serviceFetch from "./fetch";
import { QuotationsObject } from "../models/Quotation";
import { TInsertSimulationDetailsPayload } from "../components/Details/types";
import { TotalsResult } from "../models/Simulation";

export const getQuotationsByRequest = async (
  auth: AuthContextInterface,
  request: string
): Promise<{ data: Array<QuotationsObject> }> =>
  serviceFetch(auth, `cotacoes/?${request}`, null, "get");

export const getQuotationById = async (
  auth: AuthContextInterface,
  id: string
): Promise<{ data: QuotationsObject }> =>
  serviceFetch(auth, `cotacoes/${id}`, null, "get");

export const getQuotationShareToken = async (
  auth: AuthContextInterface,
  id: string
): Promise<{ success: boolean; token: string }> =>
  serviceFetch(auth, `cotacoes/${id}/share`, null, "get");

export const approveQuotationById = async (
  auth: AuthContextInterface,
  id: string
): Promise<{ success: Boolean; propostaId: string }> =>
  serviceFetch(auth, `cotacoes/${id}/approve`, null, "post");

export const rejectQuotationById = async (
  auth: AuthContextInterface,
  id: string,
  rejectReason: string
): Promise<{ data: QuotationsObject }> =>
  serviceFetch(auth, `cotacoes/${id}/reject`, null, "put", { rejectReason });

export const retryQuotationById = async (
  id: string
): Promise<{ data: QuotationsObject }> =>
  serviceFetch(null, `cotacoes/${id}/retry`, null, "put", {});

export const updateQuotationDetailsById = async (
  id: string,
  payload: TInsertSimulationDetailsPayload
): Promise<{ success: Boolean }> =>
  serviceFetch(null, `cotacoes/${id}/details`, null, "put", payload);

export const assignQuotationById = async (
  auth: AuthContextInterface,
  id: string,
  userId: string
): Promise<{ success: Boolean }> =>
  serviceFetch(auth, `cotacoes/${id}/assign`, null, "put", { userId });

export const totalsCalcQuotations = async (
  auth: AuthContextInterface,
  filter: any
): Promise<{ data: TotalsResult }> =>
  serviceFetch(auth, `cotacoes/totals`, null, "post", { filter });

export const changeOriginatorQuotationById = async (
  auth: AuthContextInterface,
  id: string,
  userId: string
): Promise<{ success: Boolean }> =>
  serviceFetch(auth, `cotacoes/${id}/change-originator`, null, "put", {
    userId,
  });
