import { SelectInputOptionInterface } from "../../../../components/Input/SelectInputs/SelectInput";
import { LatLng } from "../../../../types";
import {
  InputTypes,
  Option,
  SelectionInputTypes,
} from "../../models/formInterfaces";

export const selectionToEvent = (
  name: string,
  type: InputTypes,
  option: Option | SelectInputOptionInterface
) => {
  switch (type) {
    case "labelAndCode":
      return {
        target: {
          name,
          value: {
            label: "name" in option ? option.name : option.label,
            cod_mapfre: option.value,
          },
        },
      } as unknown as React.ChangeEvent<HTMLInputElement>;

    default:
      return {
        target: { name, value: option.value },
      } as unknown as React.ChangeEvent<HTMLInputElement>;
  }
};

export const geolocationToEvent = ({ lat, lng }: LatLng, name: string) => {
  return {
    target: { name, value: { lat, lng } },
  } as unknown as React.ChangeEvent<HTMLInputElement>;
};

export const polygonToEvent = (polygon: LatLng[], name: string) => {
  return {
    target: { name, value: polygon.map(({ lat, lng }) => ({ lat, lng })) },
  } as unknown as React.ChangeEvent<HTMLInputElement>;
};

export const findOptionIndex = (
  type: SelectionInputTypes,
  options: Option[],
  value: any
) => {
  return options.findIndex((option) => {
    switch (type) {
      case "labelAndCode":
        return option.name === value.label;
      case "Many2one":
        return option.value === (value?._id || value);
      default:
        return option.value === value;
    }
  });
};

const parseGroupKey = (group: string): string | number => {
  const intValue = parseInt(group, 10);
  return Number.isNaN(intValue) ? group : intValue;
};

export const findGroupObj = (obj: { [key: string]: any }, groupPath: string) =>
  groupPath.split(".").reduce((partialState, group) => {
    const groupKey = parseGroupKey(group);
    if (groupKey === "main" || groupKey === "") {
      return partialState;
    }
    if (!(groupKey in partialState)) {
      Object.assign(partialState, { [groupKey]: {} });
    }
    return partialState[groupKey];
  }, obj);

export const splitFieldPath = (fieldPath: string) => {
  const paths = fieldPath.split(".");
  return [paths.pop(), paths.join(".")];
};

export const getFieldValue = (object: any, fieldPath: string): any => {
  const [fieldName, groupPath] = splitFieldPath(fieldPath);
  const group = findGroupObj(object, groupPath!);
  return group[fieldName!];
};

export const filterRelevantPaths = <
  T extends { [groupPath: string]: string },
  R = Partial<T>
>(
  obj: T,
  path: string
): R => {
  const r = Object.keys(obj).reduce((res: R, key) => {
    if (key.startsWith(path + ".") || key === path) {
      res[key as keyof R] = obj[key] as R[keyof R];
    }
    return res;
  }, {} as R);
  return r;
};

export const deepCopy = (obj: any): typeof obj => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map((each) => deepCopy(each));
  }
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      return [key, deepCopy(value)];
    })
  );
};

export const composeFieldPath = (groupPath: string, fieldName: string) => {
  return groupPath ? `${groupPath}.${fieldName}` : fieldName;
};
