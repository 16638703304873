import { ListContactDocumentsQuery } from "../../../graphql/__GENERATED__/types";

type NonNullableDocuments = NonNullable<ListContactDocumentsQuery["documents"]>;

type DocumentType = NonNullable<NonNullableDocuments[0]>;

export const getDocumentContactRoles = (
  document: DocumentType,
  contactId: string
) => {
  let roles: string[] = [];
  if (document.farmerId === contactId) roles.push("Produtor");
  if (document.contactId === contactId) roles.push("Contato");
  if (document.allowedVisitPeopleIds?.includes(contactId))
    roles.push("Pessoa autorizada");
  if (document.beneficiaryId === contactId) roles.push("Beneficiário");
  if (document.paymentData?.responsibleId === contactId)
    roles.push("Responsável Financeiro");
  return roles.join(" / ");
};
