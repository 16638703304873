import { FC, useMemo } from "react";
import Inspection, {
  InspectionTypeLabels,
  InspectionStatusLabels,
  MappedInspection,
} from "./components/Inspection";
import EditableSection from "../../../../../../components/EditableSection";
import Typography from "../../../../../../components/Typography";
import { GetCreateInspectionOnFormResponseDocument } from "../../../../../../graphql/__GENERATED__/types";
import useDocumentDetails from "../../../../../../hooks/useDocumentDetails";
import Table from "../../../../../../components/Table";
import useDetailsColor from "../../../../../../hooks/useDetailsColor";

const Inspections: FC = () => {
  const {
    documentData: { _id, inspections, claimStatus },
    refetch,
  } = useDocumentDetails();

  const mappedInspections = useMemo<MappedInspection[]>(() => {
    return (
      inspections?.map((inspection) => ({
        ...inspection,
        claimsLabel: inspection.claims!.map((claim) => claim!.name).join(", "),
      })) || []
    );
  }, [inspections]);

  const { color } = useDetailsColor();

  if (!claimStatus) return null;

  return (
    <EditableSection
      title="Vistorias"
      noTitle
      main
      createPayload={{ documentId: _id! }}
      createQuery={GetCreateInspectionOnFormResponseDocument}
      addFlag="admin-only"
      addText={"Adicionar Vistoria"}
      onSubmit={refetch}
    >
      {!inspections?.length ? (
        <Typography variant="textMedium" uppercase color={color}>
          Não há vistorias agendadas
        </Typography>
      ) : (
        <Table
          data={mappedInspections}
          color={color}
          withBorder
          columns={[
            { key: "date", label: "Data", special: "date" },
            {
              key: "type",
              label: "Tipo de vistoria",
              options: InspectionTypeLabels,
            },
            {
              key: "claimsLabel",
              label: "Sinistros",
            },
            { key: "inspector", label: "Perito", special: "many2one" },
            { key: "status", label: "status", options: InspectionStatusLabels },
          ]}
          DetailsComponent={Inspection}
        />
      )}
    </EditableSection>
  );
};

export default Inspections;
