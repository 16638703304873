import ChatList from "./ChatList";
import { useGetTwilioConversationsQuery } from "../../../graphql/__GENERATED__/types";

const ChatListQueryWrapper = () => {
  const query = useGetTwilioConversationsQuery({
    variables: {},
  });
  return <ChatList query={query} />;
};

export default ChatListQueryWrapper;
