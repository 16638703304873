/** @jsxImportSource @emotion/react */

import { FC } from "react";
import Typography from "../../../../../Typography";
import {
  File,
  Maybe,
  Services,
} from "../../../../../../graphql/__GENERATED__/types";
import useDownloadFile from "../../../../../../hooks/useDownloadFile";
import Row from "../../../../../Grid/Row";
import Icon from "../../../../../Icons";
import { IconNames } from "../../../../../Icons/styles/iconNames";
import useDetailsColor from "../../../../../../hooks/useDetailsColor";

export interface FileDetailsProps {
  file: Maybe<File> | undefined;
  objectId: Maybe<string> | undefined;
  fieldPath: string;
  serviceName: Services;
}

const FileDetails: FC<FileDetailsProps> = ({
  file,
  objectId,
  fieldPath,
  serviceName,
}) => {
  const { color } = useDetailsColor();
  const handleDownload = useDownloadFile({
    serviceName,
    objectId: objectId!,
    fieldPath,
  });
  if (!file || !objectId)
    return (
      <Typography variant="textMedium" uppercase component="div" align="left">
        -
      </Typography>
    );
  return (
    <Row css={{ margin: 0 }} align="center" noGutters>
      <Typography variant="textMedium" uppercase component="div" align="left">
        {file.fileName}
      </Typography>
      <Icon name={IconNames.Download} onClick={handleDownload} color={color} />
    </Row>
  );
};

export default FileDetails;
