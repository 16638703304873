/** @jsxImportSource @emotion/react */

import { FC, useMemo } from "react";
import { User } from "../../../graphql/__GENERATED__/types";
import DetailsSectionColumns from "../components/Main/Grid/DetailsSectionColumns";
import {
  formatCpfCnpj,
  formatInputMask,
  formatPhone,
} from "../../../utils/formatNumber";

interface UserDataProps {
  user: User;
}

const UserData: FC<UserDataProps> = ({
  user: { name, cpf, email, phone, rg, civilState, city, state, profession },
}) => {
  const partnerInfo = useMemo(
    () => [
      {
        label: "nome",
        value: name,
      },
      {
        label: "cpf",
        value: formatCpfCnpj(cpf!),
      },
      {
        label: "email",
        value: email,
      },
      {
        label: "telefone",
        value: formatPhone(phone!),
      },
    ],
    [name, cpf, email, phone]
  );

  const personalInfo = useMemo(
    () => [
      {
        label: "rg",
        value: rg && formatInputMask(rg!, "rg"),
      },
      {
        label: "estado civil",
        value: civilState,
      },
      {
        label: "profissão",
        value: profession,
      },
      {
        label: "cidade // estado",
        value: `${city} - ${state}`,
      },
    ],
    [civilState, profession]
  );

  return (
    <>
      <DetailsSectionColumns columns={[partnerInfo, personalInfo]} />
    </>
  );
};

export default UserData;
