import { useMemo } from "react";
import Card, { CardFC } from "../../components/CardList/components/Card";
import { User } from "../../graphql/__GENERATED__/types";
import useContactColor from "../../hooks/useContactColor";
import { cardUserStatusFilters } from "../../filters/users";
import Icon from "../../components/Icons";
import { formatISODate } from "../../utils/dateUtils";
import Responsibles from "../Documents/DocumentCards/components/Responsibles";

const PartnerCard: CardFC<User> = ({ data, active, onClick }) => {
  const cardData = useMemo(
    () => ({
      line1: data.name || "",
      line2: data.profession || "",
      line3: data.city ? `${data.city} ${data.state && "//" + data.state}` : "",
      line4: data.signedDate
        ? formatISODate(data.signedDate)
        : formatISODate(data.createdAt),
    }),
    [data]
  );

  const responsibles = useMemo(() => {
    return [data.responsible?.name || ""];
  }, [data]);

  const cardColor = useContactColor(data.role as string);
  const statusIcon = () => {
    return cardUserStatusFilters().find(
      (option) => option.filter.status === data.status
    )?.iconName;
  };

  return (
    <Card
      detailTop={<Icon name={statusIcon()!} color={cardColor} />}
      cardData={cardData}
      color={cardColor}
      active={active}
      onClick={onClick}
      detailBottom={
        <Responsibles
          responsibles={responsibles}
          color={cardColor}
          docId={data._id!}
        />
      }
    />
  );
};

export default PartnerCard;
