/** @jsxImportSource @emotion/react */

import SkeletonTypography from "../../../components/Skeletons/components/SkeletonTypography";
import SkeletonMessage from "../../../components/Skeletons/components/SkeletonMessage";
import { notificationsHeaderHeight } from "./Notifications/components/NotificationsHeader";
import ScrollContainer from "../../../components/ScrollContainer";
import SkeletonAnimationContainer from "../../../components/Skeletons/components/SkeletonAnimationContainer";
import { FC } from "react";
import SkeletonMessagesContainer from "./SkeletonMessagesContainer";

interface SkeletonDashboardMessagesProps {
  service: "notification" | "task";
}

const SkeletonDashboardMessages: FC<SkeletonDashboardMessagesProps> = ({
  service,
}) => {
  const messages = Array(10).fill(<SkeletonMessage />);
  return (
    <div css={{ height: "100%", overflow: "hidden" }}>
      <SkeletonAnimationContainer />
      <SkeletonMessagesContainer service={service}>
        <SkeletonTypography variant="textExtraLarge" />
        <br />
        <ScrollContainer height={`calc(100% - ${notificationsHeaderHeight}px)`}>
          <br />
          {messages.map((_, i) => (
            <div key={`skeleton-notification-${i}`}>
              <SkeletonTypography variant="textMedium" />
              <SkeletonTypography variant="textMedium" />
              <br />
            </div>
          ))}
        </ScrollContainer>
      </SkeletonMessagesContainer>
    </div>
  );
};

export default SkeletonDashboardMessages;
