/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { FC, ReactNode, useCallback } from "react";
import {
  Lead,
  LeadStatus,
  RatingScale,
  Services,
  useAddCommentToLeadMutation,
  useChangeLeadStatusMutation,
  useRateLeadMutation,
} from "../graphql/__GENERATED__/types";
import {
  AddTechnicalResponsibleLeadDocument,
  AddCommercialResponsibleLeadDocument,
} from "../graphql/__GENERATED__/types";
import { useApolloClient } from "@apollo/client";
import { useErrorHandler } from "../hooks/useErrorHandler";
import PageTitle from "../components/PageTitle";

interface LeadDetailsContextValue {
  refetch: () => Promise<void>;
  leadData: Lead;
  handleAssignCommercialResponsible: (contactId: string) => Promise<void>;
  handleAssignTechnicalResponsible: (contactId: string) => Promise<void>;
  handleChangeLeadStatus: (status: LeadStatus) => Promise<void>;
  handleRateLead: (rating: RatingScale) => Promise<void>;
  addCommentToLead: (comment: string) => Promise<void>;
}
export const LeadDetailsContext = React.createContext<LeadDetailsContextValue>({
  leadData: {},
  refetch: async () => {},
  handleAssignCommercialResponsible: async () => {},
  handleAssignTechnicalResponsible: async () => {},
  handleChangeLeadStatus: async () => {},
  handleRateLead: async () => {},
  addCommentToLead: async () => {},
});

interface LeadDetailsProviderProps {
  children: ReactNode;
  leadData: Lead;
  refetch: () => Promise<any>;
}

const LeadDetailsProvider: FC<LeadDetailsProviderProps> = ({
  children,
  leadData,
  refetch,
}) => {
  const apolloClient = useApolloClient();
  const { errorHandler } = useErrorHandler();

  const handleAssignCommercialResponsible = useCallback(
    async (responsibleId: string) => {
      try {
        await apolloClient.mutate({
          mutation: AddCommercialResponsibleLeadDocument,
          variables: {
            service: Services.Leads,
            leadId: leadData._id,
            responsibleId,
          },
        });
        await refetch();
      } catch (e) {
        errorHandler(new Error("Erro ao atribuir responsável comercial"), e);
      }
    },
    [leadData._id]
  );

  const handleAssignTechnicalResponsible = useCallback(
    async (responsibleId: string) => {
      try {
        await apolloClient.mutate({
          mutation: AddTechnicalResponsibleLeadDocument,
          variables: {
            service: Services.Leads,
            leadId: leadData._id,
            responsibleId,
          },
        });
        await refetch();
      } catch (e) {
        errorHandler(new Error("Erro ao atribuir responsável técnico"), e);
      }
    },
    [leadData._id]
  );

  const [changeLeadStatusMutation] = useChangeLeadStatusMutation();
  const handleChangeLeadStatus = useCallback(
    async (status: LeadStatus) => {
      try {
        await changeLeadStatusMutation({
          variables: {
            leadId: leadData._id!,
            status,
          },
        });
        await refetch();
      } catch (e) {
        errorHandler(new Error("Erro ao alterar status do lead"), e);
      }
    },
    [leadData._id]
  );

  const [rateLeadMutation] = useRateLeadMutation();
  const handleRateLead = useCallback(
    async (rating: RatingScale) => {
      try {
        await rateLeadMutation({
          variables: {
            leadId: leadData._id!,
            rating,
          },
        });
        await refetch();
      } catch (e) {
        errorHandler(new Error("Erro ao avaliar lead"), e);
      }
    },
    [leadData._id]
  );

  const [addCommentToLeadMutation] = useAddCommentToLeadMutation();
  const addCommentToLead = useCallback(
    async (comment: string) => {
      try {
        await addCommentToLeadMutation({
          variables: {
            leadId: leadData._id!,
            comment,
          },
        });
        await refetch();
      } catch (e) {
        errorHandler(new Error("Erro ao adicionar comentário ao lead"), e);
      }
    },
    [leadData._id]
  );

  return (
    <>
      {leadData.name && <PageTitle title={`Lead - ${leadData.name}`} />}
      <LeadDetailsContext.Provider
        value={{
          refetch,
          leadData,
          handleAssignCommercialResponsible,
          handleAssignTechnicalResponsible,
          handleChangeLeadStatus,
          handleRateLead,
          addCommentToLead,
        }}
      >
        {children}
      </LeadDetailsContext.Provider>
    </>
  );
};

export default LeadDetailsProvider;
