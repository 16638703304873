/** @jsxImportSource @emotion/react */

import { FC, useEffect } from "react";
import CustomContainer from "../../../../../components/Grid/CustomContainer";
import Table from "../../../../../components/Table";
import { formatNumber, formatPrice } from "../../../../../utils/formatNumber";
import { useAuth } from "../../../../../hooks/useAuth";
import { useGetUserIndicatorsLazyQuery } from "../../../../../graphql/__GENERATED__/types";

const HiringTable: FC = () => {
  const { user, loaded } = useAuth();
  const [getIndicators, { data }] = useGetUserIndicatorsLazyQuery();
  useEffect(() => {
    if (loaded && user._id) {
      getIndicators({
        variables: {
          userId: user._id,
        },
      });
    }
  }, [user._id, loaded]);

  const pendingIndicators = data?.user?.indicators?.find(
    (indicator) => indicator._id === "pending"
  );
  const doneIndicators = data?.user?.indicators?.find(
    (indicator) => indicator._id === "done"
  );

  const rows = [
    {
      total: "Área",
      hiring: formatNumber(pendingIndicators?.totalArea || 0, 2, 2),
      hired: formatNumber(doneIndicators?.totalArea || 0, 2, 2),
    },
    {
      total: "Prêmio",
      hiring: formatPrice(pendingIndicators?.totalPremium || 0),
      hired: formatPrice(doneIndicators?.totalPremium || 0),
    },
    // {
    //   total: "Comissão",
    //   hiring: formatPrice(pendingIndicators?.totalCommission!),
    //   hired: formatPrice(doneIndicators?.totalCommission!),
    // },
  ];

  return (
    <CustomContainer
      horizontal
      style={{ display: "flex", alignItems: "center" }}
    >
      <Table
        columns={[
          {
            key: "total",
            label: "Total",
            textAlign: "left",
            fontWeight: "bold",
          },
          { key: "hiring", label: "Pendente", textAlign: "right" },
          {
            key: "hired",
            label: "Contratado",
            textAlign: "right",
          },
        ]}
        data={rows}
      />
    </CustomContainer>
  );
};

export default HiringTable;
