/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { Services } from "../../../../graphql/__GENERATED__/types";
import Many2OneInput from "../../../Input/SelectInputs/Many2OneInput";
import { SelectInputOptionInterface } from "../../../Input/SelectInputs/SelectInput";

interface UserSelectProps {
  domain: any;
  value: string;
  placeholder: string;
  onSelect: (option: SelectInputOptionInterface) => void;
  inputName: string;
}

const UserSelect: FC<UserSelectProps> = (props) => {
  return (
    <>
      <Many2OneInput
        serviceName={Services.Users}
        optionDisplay="avatar"
        {...props}
      />
    </>
  );
};

export default UserSelect;
