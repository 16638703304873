/** @jsxImportSource @emotion/react */

import { FC, ReactNode, useState } from "react";
import { theme } from "../../theme";
import Icon from "../Icons";
import { IconNames } from "../Icons/styles/iconNames";
import { IconSizes } from "../Icons/styles/iconSizes";
import { css } from "@emotion/react";
import Row from "../Grid/Row";
import Column from "../Grid/Column";

interface CarouselProps {
  columns: ReactNode[];
}

const styles = {
  controllers: {
    position: "absolute" as const,
    zIndex: 10,
    backgroundColor: theme.colors.Grey[40],
    display: "flex",
    alignItems: "center",
    padding: 5,
    borderRadius: "50%",
  },
  controlerLeft: {
    left: "1rem",
    top: `calc(50% - ${IconSizes.Large / 2}px)`,
  },
  controlerRight: {
    right: "1rem",
    top: `calc(50% - ${IconSizes.Large / 2}px)`,
  },
  container: {
    width: "100%",
    height: "100%",
    position: "relative" as const,
    margin: 0,
  },
  col: {
    height: "100%",
  },
};

const Carousel: FC<CarouselProps> = ({ columns }) => {
  const [visibleColumnIndex, setVisibleColumnIndex] = useState<number>(0);

  const handleNextColumn = () => {
    if (visibleColumnIndex === columns.length - 1) {
      setVisibleColumnIndex(0);
    } else {
      setVisibleColumnIndex(visibleColumnIndex + 1);
    }
  };
  const handlePreviousColumn = () => {
    if (visibleColumnIndex === 0) {
      setVisibleColumnIndex(columns.length - 1);
    } else {
      setVisibleColumnIndex(visibleColumnIndex - 1);
    }
  };

  return (
    <Row justify="center" align="center" css={styles.container}>
      <div css={css(styles.controllers, styles.controlerLeft)}>
        <Icon name={IconNames.ChevronLeft} onClick={handlePreviousColumn} />
      </div>
      <div css={css(styles.controllers, styles.controlerRight)}>
        <Icon name={IconNames.ChevronRight} onClick={handleNextColumn} />
      </div>
      <Column xs={12} css={styles.col}>
        {columns[visibleColumnIndex]}
      </Column>
    </Row>
  );
};

export default Carousel;
