import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Form from "../../../modules/Forms";
import { createFormResponse } from "../../../modules/Forms/services/form";
import { ToDirectQuotationFormLocation } from "../../../Locations";
import { useAuth } from "../../../hooks/useAuth";
import { useUIStore } from "../../../hooks/useUIStore";

const DirectQuotationForm = () => {
  const { formId, docId } = useParams();
  const navigate = useNavigate();
  const authState = useAuth();
  const { openSubmittedModal } = useUIStore();

  useEffect(() => {
    if (formId && docId === "new") {
      asyncCreateFormResponse(formId);
    }
  }, [formId, docId]);

  const asyncCreateFormResponse = async (formId: string) => {
    try {
      const { data } = await createFormResponse(authState, formId);
      navigate(ToDirectQuotationFormLocation(data.formState._id), {
        replace: true,
      });
    } catch (e) {
      console.log("error", e);
    }
  };

  const onSubmit = async () => {
    openSubmittedModal(
      "Obrigado pelas informações! Entraremos em contato em breve."
    );
  };

  return (
    <>
      {docId && formId && docId !== "new" && (
        <Form formId={formId} docId={docId} onSubmit={onSubmit} />
      )}
    </>
  );
};

export default DirectQuotationForm;
