/** @jsxImportSource @emotion/react */
import { FC } from "react";
import { FormFieldV2 } from "../../../../graphql/__GENERATED__/types";
import ObjectListItemField from "./ObjectListItemField";
import Row from "../../../../components/Grid/Row";
import Column from "../../../../components/Grid/Column";
import { IconNames } from "../../../../components/Icons/styles/iconNames";
import useFormField from "../../hooks/useFormField";
import { ColorNames, theme } from "../../../../theme";
import { CSSObject } from "@emotion/react";
import Icon from "../../../../components/Icons";
import Typography from "../../../../components/Typography";

const styles: CSSObject = {
  clickable: {
    cursor: "pointer",
  },
  plusContainer: {
    border: "1px solid",
    borderRadius: 5,
    overflow: "auto",
    boxShadow: theme.boxShadow.default,
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "45px",
    width: "45px",
    marginRight: "1.5em",
  },
  icons: {
    display: "flex",
    alignItems: "center",
    // "& > span": {
    //   paddingLeft: 10,
    // },
  },
  buttons: {},
};

interface ObjectListFieldProps {
  field: FormFieldV2;
}

const ObjectListField: FC<ObjectListFieldProps> = ({ field }) => {
  const { fields, label, allowAdd, allowDelete } = field;
  const { addListItem } = useFormField(field);
  return (
    <Column xs={12} useYPaddings>
      {fields!.map((field) => (
        <Row key={field.fieldPath} noMargins>
          <ObjectListItemField field={field} allowDelete={allowDelete} />
        </Row>
      ))}
      {allowAdd && (
        <Row noMargins>
          <Column xs={12} lg={6}>
            <Row
              align="center"
              justify="start"
              css={styles.clickable}
              onClick={addListItem}
              noMargins
            >
              <div css={styles.plusContainer}>
                <Icon name={IconNames.Add} color={ColorNames.GREEN} />
              </div>
              <Typography
                variant="textLarge"
                color={ColorNames.GREEN}
                uppercase
              >
                {`Adicionar ${label}`}
              </Typography>
            </Row>
          </Column>
          {/* TODO: Permite copiar último item */}
        </Row>
      )}
    </Column>
  );
};

export default ObjectListField;
