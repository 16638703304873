/** @jsxImportSource @emotion/react */

import { FC, ReactNode, useMemo } from "react";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../../hooks/useCSSRulesWithTheme";
import { ColorNames, getThemeColorFromAlias, theme } from "../../../theme";

const getCSSRules: CSSRulesResolver<{
  color: string;
  active?: boolean;
  onClick?: () => void;
  noHover?: boolean;
}> = ({ color, onClick, active, noHover }) => ({
  card: {
    backgroundColor: active
      ? theme.colors.LightGrey[100]
      : theme.colors.White[100],
    boxSizing: "border-box" as "border-box",
    cursor: onClick ? "pointer" : "auto",
    padding: 20,
    height: "100%",
    width: "100%",
    minWidth: 250,
    border: "2px solid",
    borderColor: color,
    borderRadius: 10,
    boxShadow: theme.boxShadow.default,
    textAlign: "initial" as "initial",
    "&:hover": {
      backgroundColor: noHover ? "none" : theme.colors.LightGrey[100],
    },
  },
});

export interface CardContainerProps {
  active?: boolean;
  children: ReactNode;
  borderColor?: ColorNames;
  onClick?: () => void;
  disabled?: boolean;
  noHover?: boolean;
}

const CardContainer: FC<CardContainerProps> = ({
  active,
  children,
  borderColor = ColorNames.GREEN,
  onClick,
  disabled = false,
  noHover = false,
}) => {
  const color = useMemo(() => {
    return disabled
      ? getThemeColorFromAlias(ColorNames.GREY, theme.colors)!
      : getThemeColorFromAlias(borderColor, theme.colors)!;
  }, [borderColor, disabled]);

  const styles = useCSSRulesWithTheme(getCSSRules, {
    color,
    onClick,
    active,
    noHover,
  });

  return (
    <div onClick={onClick} css={styles.card}>
      <div css={{ height: "100%", width: "100%" }}>{children}</div>
    </div>
  );
};

export default CardContainer;
