/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { TModelFilter } from "../../../hooks/useModelFilter";
import Row from "../../Grid/Row";
import Column from "../../Grid/Column";
import Many2ManyInput from "../../Input/SelectInputs/Many2ManyInput";
import MultiSelectInput from "../../Input/SelectInputs/MultiSelectInput";
import Typography from "../../Typography";
import Button from "../../Button";
import { IconNames } from "../../Icons/styles/iconNames";

interface FiltersProps {
  filter: TModelFilter;
}

const Filters: FC<FiltersProps> = ({ filter }) => {
  const { filters, setFilterValue } = filter;

  return (
    <>
      {filters.map((filter) => (
        <Row key={`filter-${filter.name}`}>
          <Column>
            <Typography
              variant="textMedium"
              color="Black"
              uppercase
              weight="bold"
              component="div"
              align="left"
            >
              {filter.label}
            </Typography>
            {!filter.many2Many && (
              <MultiSelectInput
                options={filter.options}
                inputName={filter.name}
                onSelect={(value) => setFilterValue(filter.name, value)}
                value={filter.value}
                hideDropdown
                multiValueDisplay={filter.selectMultiValueDisplay}
                optionDisplay={filter.selectOptionDisplay}
                placeholder="Todos"
              />
            )}
            {filter.many2Many && (
              <Many2ManyInput
                serviceName={filter.many2Many?.serviceName}
                domain={filter.many2Many?.domain}
                inputName={filter.name}
                onSelect={(value) => setFilterValue(filter.name, value)}
                value={filter.value}
                hideDropdown
                multiValueDisplay={filter.selectMultiValueDisplay}
                optionDisplay={filter.selectOptionDisplay}
                placeholder="Todos"
              />
            )}
          </Column>
        </Row>
      ))}
      <Row noGutters justify="end">
        <Button
          text="Limpar Filtros"
          iconName={IconNames.Close}
          width="auto"
          disabled={filters.every((filter) => filter.value.length === 0)}
          onClick={() =>
            filters.map((filter) => setFilterValue(filter.name, []))
          }
        />
      </Row>
    </>
  );
};

export default Filters;
