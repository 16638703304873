import { useEffect, useState } from "react";
import Form from "../../../modules/Forms";
import { useCreateFormResponseMutation } from "../../../graphql/__GENERATED__/types";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { ToLeadDetailsLocation, ToLeadFormLocation } from "../../../Locations";

const LeadRegisterForm = () => {
  const { docId } = useParams();
  const { loaded } = useAuth();
  const [createFormResponseMutation] = useCreateFormResponseMutation();
  const [showMessage, setShowMessage] = useState(false);

  const navigate = useNavigate();

  const asyncCreateFormResponse = async () => {
    try {
      const { data } = await createFormResponseMutation({
        variables: {
          formId: "leads",
          metadata: {},
        },
      });
      navigate(ToLeadFormLocation(data!.createFormResponse!._id!), {
        replace: true,
      });
    } catch (e) {
      console.log("error", e);
    }
  };

  const handleSubmit = (id: any) => {
    id && navigate(ToLeadDetailsLocation(id.objectId));
    setShowMessage(true);
  };

  useEffect(() => {
    if (docId === "new" && loaded) {
      asyncCreateFormResponse();
    }
  }, [docId, loaded]);

  return (
    <>
      {!showMessage && docId && docId !== "new" && (
        <Form formId="leads" docId={docId} onSubmit={handleSubmit} />
      )}
    </>
  );
};

export default LeadRegisterForm;
