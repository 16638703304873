/** @jsxImportSource @emotion/react */

import { useMemo } from "react";
import useDocumentDetails from "../../../../../../hooks/useDocumentDetails";
import {
  ContactTags,
  useModelTasksQuery,
} from "../../../../../../graphql/__GENERATED__/types";
import UserSelect from "../../../../../../components/Details/components/Main/UserSelect";
import DetailsSectionColumn from "../../../../../../components/Details/components/Main/Grid/DetailsSectionColumn";
import { Services } from "../../../../../../graphql/__GENERATED__/types";
import { concatArrayOfStrings } from "../../../../../../utils/formatString";
import ContactSelect from "../../../../../../components/Details/components/Main/ContactSelect";
import { useAuth } from "../../../../../../hooks/useAuth";
import { UserRoles } from "../../../../../../graphql/__GENERATED__/types";

const SelectUsers = () => {
  const {
    documentData: {
      commercialResponsibleId,
      operationalResponsibleId,
      claimResponsibleId,
      originatorId,
      _id,
      contactId,
    },
    handleAssignCommercialResponsible,
    handleAssignOperationalResponsible,
    handleAssignClaimResponsible,
    handleAssignOriginator,
    handleSetContact,
  } = useDocumentDetails();

  const { data } = useModelTasksQuery({
    variables: {
      serviceName: Services.Documents,
      objectId: _id,
    },
  });

  const auth = useAuth();

  const responsibles = useMemo(() => {
    return [
      {
        inputName: "Responsável Comercial",
        role: UserRoles.Admin,
        onSelect: handleAssignCommercialResponsible,
        value: commercialResponsibleId || "",
      },
      {
        inputName: "Responsável Operacional",
        role: UserRoles.Admin,
        onSelect: handleAssignOperationalResponsible,
        value: operationalResponsibleId || "",
      },
      {
        inputName: "Responsável pelo Sinistro",
        role: UserRoles.Admin,
        onSelect: handleAssignClaimResponsible,
        value: claimResponsibleId || "",
      },
    ];
  }, [commercialResponsibleId, operationalResponsibleId, claimResponsibleId]);

  const responsiblesInfo = useMemo(() => {
    return responsibles.map((responsible) => ({
      label: responsible.inputName,
      value: (
        <UserSelect
          domain={{ role: responsible.role }}
          value={responsible.value}
          placeholder=""
          onSelect={(option) => responsible.onSelect(option.value)}
          inputName={responsible.inputName}
        />
      ),
    }));
  }, [responsibles]);

  const contactInfo = useMemo(() => {
    const docContacts = [
      {
        inputName: "Parceiro",
        value: originatorId,
        onSelect: handleAssignOriginator,
        disabled: auth.user.role !== UserRoles.Admin,
        domain: { tags: ContactTags.Consultant },
      },
      {
        inputName: "Contato",
        value: contactId,
        onSelect: handleSetContact,
        disabled: false,
      },
    ];
    return docContacts.map((contact) => ({
      label: contact.inputName,
      value: (
        <ContactSelect
          value={contact.value}
          onSelect={(option) => contact.onSelect(option.value)}
          inputName={contact.inputName}
          placeholder={contact.inputName}
          disabled={contact.disabled}
          domain={contact.domain}
        />
      ),
    }));
  }, [contactId, originatorId, auth.user.role]);

  const taskInfo = useMemo(() => {
    return {
      label: "Próxima(s) Tarefa(s)",
      value:
        data?.modelTasks &&
        concatArrayOfStrings(data?.modelTasks?.map((task) => task.message)),
    };
  }, [data]);

  return (
    <DetailsSectionColumn
      xs={12}
      sm={6}
      rows={[...responsiblesInfo, ...contactInfo, taskInfo]}
    />
  );
};

export default SelectUsers;
