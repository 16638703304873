/** @jsxImportSource @emotion/react */

import { FC } from "react";
import Row from "../Grid/Row";
import { ColorNames } from "../../theme";
import Button from "../Button";
import Column from "../Grid/Column";
import HR from "../HorizontalLine";
import Icon from "../Icons";
import { IconNames } from "../Icons/styles/iconNames";
import { IconSizes } from "../Icons/styles/iconSizes";
import Typography from "../Typography";

export interface ConfirmedActionProps {
  message: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

const ConfirmedActionComponent: FC<ConfirmedActionProps> = ({
  message = "Tem certeza que deseja prosseguir com a ação?",
  onConfirm,
  onCancel,
}) => {
  const iconName = onConfirm ? IconNames.Pending : IconNames.Valid;
  const color = onConfirm ? ColorNames.ERROR_RED : ColorNames.BLACK;
  return (
    <Row align="center" justify="center" css={{ height: "100%", margin: 0 }}>
      <Column xs={12} lg={8}>
        <Row>
          <Column>
            <Icon name={iconName} color={color} size={IconSizes.Large} />

            <HR color={color} />
            <Typography variant="textMedium" color={color} uppercase>
              {message}
            </Typography>
          </Column>
        </Row>
        {onConfirm && (
          <Row justify="between">
            <Column xs={12} lg={6}>
              <Button text={"Voltar"} onClick={onCancel} />
            </Column>
            <Column xs={12} lg={6}>
              <Button text={"Sim"} onClick={onConfirm} />
            </Column>
          </Row>
        )}
      </Column>
    </Row>
  );
};

export default ConfirmedActionComponent;
