import Typography from "../../../../components/Typography";
import WAChat from "../../../../components/WAChat";
import { useFlags } from "../../../../hooks/useFlags";
import { usePartnerDetails } from "../hooks/usePartnerDetails";

export const PartnerChat = () => {
  const { data } = usePartnerDetails();
  const { isFlagEnabled } = useFlags();
  if (!isFlagEnabled("use-whatsapp")) {
    // There is another to do task(PM-1923) that is waiting for the Contact pages been merged so we can actually hide the chat tab according to the featureFlag and use it also on the route flag properly
    return (
      <div style={{ marginTop: "40px" }}>
        <Typography variant="textExtraLarge">
          Você não tem acesso à essa página
        </Typography>
      </div>
    );
  }
  return <WAChat contactId={data.contactId!} />;
};
