import { FC, useMemo } from "react";
import DetailsSectionColumns from "../../components/Details/components/Main/Grid/DetailsSectionColumns";
import { formatPrice } from "../../utils/formatNumber";
import {
  GetEditIntegrationSettingsFormResponseDocument,
  GetSwissIntegrationSettingsQuery,
  SwissStateSettings,
  useGetSwissStateIntegrationSettingsQuery,
} from "../../graphql/__GENERATED__/types";
import EditableTable from "../../components/EditableTable";
import DetailsSection from "../../components/Details/components/Main/Section/DetailsSection";
import { formatISODate } from "../../utils/dateUtils";

interface SwissSettingsProps {
  data: Omit<
    GetSwissIntegrationSettingsQuery["integrationSettings"][number] &
      GetSwissIntegrationSettingsQuery["integrationSettings"][number]["swiss"],
    "__typename"
  >;
}

const SwissSettingsDetails: FC<SwissSettingsProps> = ({
  data: { culture, swiss, _id },
}) => {
  const settingsInfo = useMemo(
    () => [
      [
        {
          label: "Cultura",
          value: culture?.name,
        },
        {
          label: "Cód. do Produto",
          value: swiss?.productCode,
        },
        {
          label: "Cód. do Sistema",
          value: swiss?.systemCode,
        },
        {
          label: "Cód. do Tipo de Solo",
          value: swiss?.soilTypeCode,
        },
      ],
      [
        {
          label: "Valor da Saca",
          value: formatPrice(swiss?.valSaca),
        },
        {
          label: "Risco/ha (R.N.)",
          value: formatPrice(swiss?.valRiscoNomeado),
        },
        {
          label: "Data de Plantio",
          value: formatISODate(swiss?.plantDate),
        },
      ],
    ],
    [swiss, culture]
  );

  const { data, refetch } = useGetSwissStateIntegrationSettingsQuery({
    variables: {
      settingId: _id,
    },
  });
  const stateSettings = useMemo<SwissStateSettings[]>(() => {
    return data?.integrationSetting.swiss?.stateSettings || [];
  }, [data]);

  return (
    <DetailsSection main title={culture!.name!}>
      <DetailsSectionColumns columns={settingsInfo} />
      <EditableTable
        data={stateSettings}
        createQuery={GetEditIntegrationSettingsFormResponseDocument}
        createPayload={{ formId: "swissStateSettings", objectId: _id }}
        editQuery={GetEditIntegrationSettingsFormResponseDocument}
        editPayload={{ formId: "swissStateSettings" }}
        parentObjectId={_id}
        onSubmit={refetch}
        columns={[
          { label: "Estado", key: "states", special: "multiSelect" },
          { label: "Custeio/ha", key: "valCusteio" },
        ]}
      ></EditableTable>
    </DetailsSection>
  );
};

export default SwissSettingsDetails;
