import { AuthContextInterface } from "../contexts/auth";
import serviceFetch, { FileData, serviceFetchFile } from "./fetch";

export const search = async <T>(
  auth: AuthContextInterface,
  modelName: string,
  filter: any
): Promise<{ data: T[] }> => {
  return await serviceFetch(auth, `${modelName}/search`, null, "post", {
    filter,
  });
};

export const count = async (
  auth: AuthContextInterface,
  modelName: string,
  filter: any
): Promise<{ data: number }> => {
  return await serviceFetch(auth, `${modelName}/count`, null, "post", {
    filter,
  });
};

export const get = async <T>(
  auth: AuthContextInterface,
  modelName: string,
  docId: string
): Promise<{ data: T }> => {
  return await serviceFetch(auth, `${modelName}/${docId}`, null, "get");
};

export const getShareToken = async (
  auth: AuthContextInterface,
  modelName: string,
  id: string
): Promise<{ success: boolean; token: string }> =>
  serviceFetch(auth, `${modelName}/${id}/share`, null, "get");

export const uploadFile = async (
  auth: AuthContextInterface,
  modelName: string,
  id: string,
  fileField: string,
  file: File,
  payload: any = {}
): Promise<{ success: boolean }> =>
  serviceFetch(
    auth,
    `${modelName}/${id}/upload-file/${fileField}`,
    null,
    "post",
    payload,
    file
  );

export const downloadFile = async (
  auth: AuthContextInterface,
  modelName: string,
  id: string,
  fileField: string
): Promise<FileData> =>
  serviceFetchFile(
    auth,
    `${modelName}/${id}/download-file/${fileField}`,
    null,
    "get"
  );

export const fetchTotals = async (
  auth: AuthContextInterface,
  modelName: string,
  filter: any
): Promise<{ data: { totalArea: number; totalValue: number } }> =>
  serviceFetch(auth, `${modelName}/totals`, null, "post", { filter });
