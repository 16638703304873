import { useCallback, useEffect, useMemo, useState } from "react";
import DetailsSection, {
  DetailsSectionProps,
} from "../Details/components/Main/Section/DetailsSection";
import Form from "../../modules/Forms";
import { SectionFooterButton } from "../../views/Documents/DocumentDetails/components/SectionFooter";
import useObjectFormResponse, {
  ObjectFormResponseProps,
} from "../../hooks/useObjectFormResponse";
import { useUIStore } from "../../hooks/useUIStore";
import { OperationVariables, useApolloClient } from "@apollo/client";
import Column from "../Grid/Column";
import { IconNames } from "../Icons/styles/iconNames";
import { useFlags } from "../../hooks/useFlags";
import FormV2 from "../../modules/Forms/form.v2";

interface EditableSectionProps<
  T extends OperationVariables,
  E extends OperationVariables
> extends DetailsSectionProps,
    ObjectFormResponseProps<T, E> {
  addText?: string;
  addFlag?: string;
  editText?: string;
  editFlag?: string;
  onSubmit?: (id: any) => void;
  editingObjectId?: string | null;
  formWidth?: number;
  useV2?: boolean;
}

const EditableSection = <
  T extends OperationVariables,
  E extends OperationVariables
>({
  addText,
  addFlag,
  editText,
  editFlag,
  objectId,
  createQuery,
  createPayload,
  editQuery,
  onSubmit,
  footerButtons = [],
  children,
  editingObjectId,
  formWidth,
  editPayload = {} as E,
  useV2,
  ...sectionProps
}: EditableSectionProps<T, E>) => {
  const { documentColor } = useUIStore();
  const { isFlagEnabled } = useFlags();
  const apolloClient = useApolloClient();
  const { handleEditObject, handleNewObject } = useObjectFormResponse({
    createQuery: createQuery!,
    createPayload,
    editQuery,
    objectId: objectId,
    editPayload: { objectId, ...editPayload },
  });
  const [formResponseId, setFormResponseId] = useState<string>("");
  const [formId, setFormId] = useState<string>("");

  const handleSubmit = useCallback(
    (id: any) => {
      setFormResponseId("");
      onSubmit && onSubmit(id);
    },
    [onSubmit, setFormResponseId]
  );

  useEffect(() => {
    if (editingObjectId) {
      handleEditObject(editingObjectId).then(({ formResponseId, formId }) => {
        setFormResponseId(formResponseId), setFormId(formId);
      });
    } else {
      setFormResponseId("");
    }
  }, [editingObjectId]);

  const buttons = useMemo<SectionFooterButton[]>(() => {
    const res = footerButtons.map((button) => {
      if (!button.formQuery) {
        return button;
      }
      const { query, variables } = button.formQuery;
      return {
        ...button,
        onClick: () =>
          apolloClient
            .query({
              query,
              variables,
              fetchPolicy: "network-only",
            })
            .then(({ data }) => {
              const { formResponseId, formId } = Object.values(data)[0];
              setFormResponseId(formResponseId), setFormId(formId);
            }),
      };
    });
    if (!editFlag || isFlagEnabled(editFlag)) {
      if (objectId && editQuery) {
        res.unshift({
          text: editText || "Editar",
          onClick: () =>
            handleEditObject().then(({ formResponseId, formId }) => {
              setFormResponseId(formResponseId), setFormId(formId);
            }),
          iconName: IconNames.Edit,
        });
      }
    }
    if (!addFlag || isFlagEnabled(addFlag)) {
      if (!objectId && createQuery) {
        res.unshift({
          text: addText || "Adicionar",
          iconName: IconNames.Add,
          onClick: () =>
            handleNewObject().then(({ formResponseId, formId }) => {
              setFormResponseId(formResponseId), setFormId(formId);
            }),
        });
      }
    }
    return res;
  }, [handleNewObject, handleEditObject, documentColor, footerButtons]);

  return (
    <DetailsSection
      {...sectionProps}
      footerButtons={!(formResponseId && formId) ? buttons : []}
    >
      {formResponseId && formId && !useV2 && (
        <Column xs={12} sm={formWidth || 12}>
          <Form
            formId={formId}
            docId={formResponseId}
            onSubmit={handleSubmit}
            singleSection
            large
            onCancel={() => setFormResponseId("")}
          />
        </Column>
      )}
      {formResponseId && useV2 && (
        <Column xs={12} sm={formWidth || 12}>
          <FormV2 formResponseId={formResponseId} onSubmit={handleSubmit} />
        </Column>
      )}
      {!formResponseId && <>{children}</>}
    </DetailsSection>
  );
};

export default EditableSection;
