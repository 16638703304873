/** @jsxImportSource @emotion/react */
import {
  FC,
  PropsWithChildren,
  ReactNode,
  createContext,
  useContext,
  useState,
} from "react";
import Modal from "..";

interface ModalStoreContextValue {
  openModal: (modal: ReactNode) => void;
  closeModal: () => void;
}

export const ModalStoreContext = createContext<ModalStoreContextValue>({
  openModal: () => {},
  closeModal: () => {},
});

interface ModalStoreProviderProps extends PropsWithChildren {}

const ModalStoreProvider: FC<ModalStoreProviderProps> = ({ children }) => {
  const [modal, setModal] = useState<ReactNode | null>(null);
  const openModal = (modal: ReactNode) => {
    setModal(modal);
  };

  const closeModal = () => {
    setModal(null);
  };

  return (
    <ModalStoreContext.Provider
      value={{
        openModal,
        closeModal,
      }}
    >
      <div style={{ position: "relative", height: "100%" }}>
        {children}
        {modal && <Modal>{modal}</Modal>}
      </div>
    </ModalStoreContext.Provider>
  );
};

export default ModalStoreProvider;

export const useModalStore = () => useContext(ModalStoreContext);
