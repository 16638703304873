/* eslint-disable @typescript-eslint/no-unused-vars */

import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import {
  GetUserDetailsQuery,
  RatingScale,
  UserRating,
  useAddCommentToUserMutation,
  useAddCultureToUserMutation,
  useAddCourseToUserMutation,
  useAddRatingToUserMutation,
  useAddResponsibleToUserMutation,
  useGetUserDetailsQuery,
} from "../../../../graphql/__GENERATED__/types";
import SkeletonDetails from "../../../../components/Details/components/SkeletonDetails";
import { useUIStore } from "../../../../hooks/useUIStore";
import useContactColor from "../../../../hooks/useContactColor";
import { useErrorHandler } from "../../../../hooks/useErrorHandler";
import { AuthContextInterface } from "../../../../contexts/auth";
import {
  approvePartnerById,
  disablePartnerById,
  enablePartnerById,
} from "../../../../services/partners";
import { useAuth } from "../../../../hooks/useAuth";
import useConfirmedAction from "../../../../hooks/useConfirmedAction";
import { useNavigate } from "react-router-dom";
import { ToConsultantRejectionLocation } from "../../../../Locations";
import PageTitle from "../../../../components/PageTitle";

interface PartnerDetailsContextValue {
  refetch: () => Promise<any>;
  data: NonNullable<GetUserDetailsQuery["user"]>;
  loading?: boolean;
  handleAssignPartnerResponsible: (responsibleId: string) => void;
  handleAddRating: (rating: RatingScale) => void;
  handleAddComment: (comment: string) => void;
  handleApprovePartner: () => void;
  handleRejectPartner: () => void;
  handleDisablePartner: () => void;
  handleEnablePartner: () => void;
  handleAddCultureToPartner: (culturesId: string[]) => void;
  handleAddCourse: (finishedCourse: boolean) => void;
}
export const PartnerDetailsContext =
  React.createContext<PartnerDetailsContextValue>({
    data: null as any,
    refetch: async () => {},
    handleAssignPartnerResponsible: () => null,
    handleAddRating: () => null,
    handleAddComment: () => null,
    handleApprovePartner: () => null,
    handleRejectPartner: () => null,
    handleDisablePartner: () => null,
    handleEnablePartner: () => null,
    handleAddCultureToPartner: () => null,
    handleAddCourse: () => null,
  });

interface PartnerDetailsProviderProps extends PropsWithChildren {
  userId: string;
}

const PartnerDetailsProvider: FC<PartnerDetailsProviderProps> = ({
  userId,
  children,
}) => {
  const { data, refetch, loading } = useGetUserDetailsQuery({
    variables: {
      userId,
    },
  });
  const { errorHandler } = useErrorHandler();
  const auth = useAuth();
  const navigate = useNavigate();

  const user = useMemo(() => {
    return data?.user;
  }, [data?.user]);

  const { setDocumentColor } = useUIStore();
  const roleColor = useContactColor(user?.role!);

  useEffect(() => {
    setDocumentColor(roleColor);
  }, [roleColor]);

  const [addResponsibleToUserMutation] = useAddResponsibleToUserMutation();
  const [addRatingToUserMutation] = useAddRatingToUserMutation();
  const [addCommentToUserMutation] = useAddCommentToUserMutation();
  const [addCultureToPartnerMutation] = useAddCultureToUserMutation();
  const [addCourseToUserMutation] = useAddCourseToUserMutation();

  const handleAssignPartnerResponsible = useCallback(
    async (responsibleId: string) => {
      try {
        await addResponsibleToUserMutation({
          variables: { userId: user!._id!, responsibleId },
        });
        refetch();
      } catch (e) {
        errorHandler(new Error("Erro ao atribuir responsável"), e);
      }
    },
    [user?._id]
  );

  const handleAddRating = useCallback(
    async (rating: RatingScale) => {
      try {
        await addRatingToUserMutation({
          variables: { userId: user!._id!, rating },
        });
        refetch();
      } catch (e) {
        errorHandler(new Error("Erro ao atribuir rating"), e);
      }
    },
    [user?._id]
  );

  const handleAddCourse = useCallback(
    async (finishedCourse: boolean) => {
      try {
        await addCourseToUserMutation({
          variables: { userId: user!._id!, finishedCourse },
        });
        refetch();
      } catch (e) {
        errorHandler(new Error("Erro ao atribuir curso"), e);
      }
    },
    [user?._id]
  );

  const handleAddComment = useCallback(
    async (newComment: string) => {
      try {
        addCommentToUserMutation({
          variables: { userId: user!._id!, comment: newComment },
        });
      } catch (e) {
        errorHandler(new Error("Erro ao atribuir comentário"), e);
      }
    },
    [user?._id]
  );

  const handleApprovePartner = useCallback(async () => {
    try {
      await approvePartnerById({ ...auth }, user?._id!);
      refetch();
    } catch (e) {
      errorHandler(new Error("Nao foi possivel aprovar o parceiro"), e);
    }
  }, [user?._id]);

  const rejectPartner = useCallback(async () => {
    navigate(ToConsultantRejectionLocation(user?._id!));
  }, [user?._id]);

  const handleRejectPartner = useConfirmedAction(
    "Tem certeza que deseja recusar o parceiro?",
    () => rejectPartner()
  );

  const disablePartner = useCallback(async () => {
    try {
      await disablePartnerById({ ...auth }, user?._id!);
      refetch();
    } catch (e) {
      errorHandler(new Error("Nao foi possivel desabilitar o parceiro"), e);
    }
  }, [user?._id]);

  const handleDisablePartner = useConfirmedAction(
    "Tem certeza que deseja desabilitar o parceiro?",
    () => disablePartner()
  );

  const enablePartner = useCallback(async () => {
    try {
      await enablePartnerById({ ...auth }, user?._id!);
      refetch();
    } catch (e) {
      errorHandler(new Error("Nao foi possivel reabilitar o parceiro"), e);
    }
  }, [user?._id]);

  const handleEnablePartner = useConfirmedAction(
    "Tem certeza que deseja reabilitar o parceiro?",
    () => enablePartner()
  );

  const handleAddCultureToPartner = useCallback(
    async (cultureIds: string[]) => {
      try {
        await addCultureToPartnerMutation({
          variables: { userId: user!._id!, cultureIds },
        });
        refetch();
      } catch (e) {
        errorHandler(new Error("Erro ao atribuir cultura"), e);
      }
    },
    [user?._id]
  );

  if (loading || !data?.user) return <SkeletonDetails />;

  return (
    <>
      {data.user?.name && <PageTitle title={`Contato - ${data.user.name}`} />}
      <PartnerDetailsContext.Provider
        value={{
          data: data?.user,
          refetch,
          loading,
          handleAssignPartnerResponsible,
          handleAddRating,
          handleAddComment,
          handleApprovePartner,
          handleRejectPartner,
          handleDisablePartner,
          handleEnablePartner,
          handleAddCultureToPartner,
          handleAddCourse,
        }}
      >
        {children}
      </PartnerDetailsContext.Provider>
    </>
  );
};

export default PartnerDetailsProvider;
