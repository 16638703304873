/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { GetTwilioMediaUrlQuery } from "../../../graphql/__GENERATED__/types";
import { theme } from "../../../theme";
import Typography from "../../Typography";
import Icon from "../../Icons";
import { IconNames } from "../../Icons/styles/iconNames";
import { IconSizes } from "../../Icons/styles/iconSizes";

const styles = {
  mediaItem: {
    margin: "4px",
    padding: "8px",
    backgroundColor: theme.colors.LightGrey[100],
    borderRadius: "6px",
    border: `1px solid ${theme.colors.Grey[100]}`,
    cursor: "pointer",
  },
  downloadIcon: {
    marginLeft: 10,
  },
};

const Media: FC<{
  getMediaUrl: () => Promise<GetTwilioMediaUrlQuery | undefined>;
  fileName: string;
}> = ({ getMediaUrl, fileName }) => {
  return (
    <div css={styles.mediaItem} onClick={getMediaUrl}>
      <Typography variant={"textSmall"}>{fileName}</Typography>
      <span css={styles.downloadIcon}>
        <Icon
          name={IconNames.Download}
          size={IconSizes.Small}
          // we add it here too or else the cursor is not pointer on the icon LOL
          onClick={getMediaUrl}
        />
      </span>
    </div>
  );
};

export default Media;
