/** @jsxImportSource @emotion/react */

import { FC } from "react";
import Sidebar from "../../components/Sidebar";
import DetailsMenu from "../../components/Details/components/Header/DetailsTabs";
import { ColorNames } from "../../theme";
import ContactSummary from "./ContactDetails/components/ContactSummary";
import ContactDocuments from "./ContactDetails/components/ContactDocuments";
import Subcontacts from "./ContactDetails/components/Subcontacts";

const ContactFlow: FC = () => {
  return (
    <Sidebar
      TopComponent={() => <DetailsMenu />}
      menuItems={[
        {
          title: "Geral",
          Component: ContactSummary,
          color: ColorNames.GREEN,
        },
        {
          title: "Documentos",
          Component: ContactDocuments,
          color: ColorNames.BLUE,
        },
        {
          title: "Subcontatos",
          Component: Subcontacts,
          color: ColorNames.PINK,
        },
      ]}
    />
  );
};

export default ContactFlow;
