/** @jsxImportSource @emotion/react */

import { FC, useMemo } from "react";
import { Contact } from "../../../graphql/__GENERATED__/types";
import DetailsSectionColumns from "../components/Main/Grid/DetailsSectionColumns";
import {
  formatCep,
  formatCpfCnpj,
  formatInputMask,
  formatPhone,
} from "../../../utils/formatNumber";
import { formatISODate } from "../../../utils/dateUtils";

interface ContactDataProps {
  contact: Contact;
}

const ContactData: FC<ContactDataProps> = ({
  contact: {
    name,
    cpfCnpj,
    email,
    mobilePhone,
    rg,
    emissionInstitution,
    expeditionDate,
    civilState,
    gender,
    profession,
    birthDate,
    address,
  },
}) => {
  const farmerInfo = useMemo(
    () => [
      {
        label: "nome",
        value: name,
      },
      {
        label: "cpf/cnpj",
        value: formatCpfCnpj(cpfCnpj!),
      },
      {
        label: "email",
        value: email,
      },
      {
        label: "telefone",
        value: formatPhone(mobilePhone!),
      },
    ],
    [name, cpfCnpj, mobilePhone, rg, emissionInstitution, expeditionDate]
  );

  const personalInfo = useMemo(
    () => [
      {
        label: "rg",
        value: rg && formatInputMask(rg!, "rg"),
      },
      {
        label: "órgão emissor/data de expedição",
        value:
          emissionInstitution && formatISODate(expeditionDate)
            ? emissionInstitution + " - " + formatISODate(expeditionDate)
            : "",
      },
      {
        label: "estado civil/ gênero",
        value: `${civilState ? civilState : " - "} / ${
          gender ? gender : " - "
        }`,
      },
      {
        label: "data de nascimento",
        value: formatISODate(birthDate),
      },
      {
        label: "profissão",
        value: profession,
      },
    ],
    [civilState, gender, birthDate, profession]
  );

  const addressInfo = useMemo(
    () => [
      {
        label: "cep",
        value: formatCep(address?.cep!),
      },
      {
        label: "cidade/estado",
        value: `${address?.city || " - "} / ${address?.state || " - "}`,
      },
      {
        label: "bairro",
        value: address?.neighborhood,
      },
      {
        label: "endereço",
        value: `${address?.street || " - "}${
          address?.number ? ", " + address?.number : ""
        }`,
      },
      {
        label: "complemento",
        value: address?.complement,
      },
    ],
    [address?.street, address?.cep, address?.city, address?.state]
  );

  return (
    <>
      <DetailsSectionColumns columns={[farmerInfo, personalInfo]} />
      <DetailsSectionColumns columns={[addressInfo, []]} />
    </>
  );
};

export default ContactData;
