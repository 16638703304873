import React, { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { HOMETAB, PARTNERSTAB } from "../Routes";
import { ColorAlias, ColorNames } from "../theme";
import ConfirmedActionComponent from "../components/Modal/ConfirmedAction";
import Modal from "../components/Modal";

export interface UIState {
  isLoading: boolean;
  loadingMessage: string;
  isProfileModalOpen: boolean;
  isConfirmationModalOpen: boolean;
  isSubmittedModalOpen: boolean;
  directQuotationCulture: string;
  tabColor: ColorAlias;
  isNewMessageOpen: boolean;
  selectedDashboard: string;
  documentColor: ColorAlias;
  isDrawerFullWidth: boolean;
}

export const initialState: UIState = {
  isLoading: false,
  loadingMessage: "",
  isProfileModalOpen: false,
  isConfirmationModalOpen: false,
  isSubmittedModalOpen: false,
  directQuotationCulture: "",
  tabColor: "Green",
  isNewMessageOpen: false,
  selectedDashboard: "",
  documentColor: ColorNames.GREEN,
  isDrawerFullWidth: false,
};

export interface UIStore {
  isDrawerOpen: boolean;
  closeDrawer: () => void;
  openDrawer: () => void;
  isProfileModalOpen: boolean;
  openProfileModal: () => void;
  closeProfileModal: () => void;
  isConfirmationModalOpen: boolean;
  openConfirmationModal: (
    message: string,
    onConfirm: () => void,
    onCancel: () => void
  ) => void;
  closeConfirmationModal: () => void;
  renderConfirmationModal: () => ReactNode;
  isSubmittedModalOpen: boolean;
  openSubmittedModal: (message: string) => void;
  closeSubmittedModal: () => void;
  renderSubmittedModal: () => ReactNode;
  isLoading: boolean;
  loadingMessage: string;
  setIsLoading: (status: boolean, message?: string) => void;
  directQuotationCulture: string;
  onNavigateToDirectQuotation: (cultureId: string) => void;
  activateTab: (tab: number) => void;
  tabColor: ColorAlias;
  isNewMessageOpen: boolean;
  closeNewMessage: () => void;
  documentColor: ColorAlias;
  setDocumentColor: (color: ColorAlias) => void;
  isDrawerFullWidth: boolean;
  toggleDrawerSize: () => void;
}

const UIContext = React.createContext<UIStore>({
  isDrawerOpen: false,
  closeDrawer: () => null,
  openDrawer: () => null,
  isLoading: initialState.isLoading,
  setIsLoading: () => null,
  loadingMessage: "",
  isProfileModalOpen: false,
  openProfileModal: () => null,
  closeProfileModal: () => null,
  isConfirmationModalOpen: false,
  openConfirmationModal: () => null,
  closeConfirmationModal: () => null,
  renderConfirmationModal: () => null,
  isSubmittedModalOpen: false,
  openSubmittedModal: () => null,
  closeSubmittedModal: () => null,
  renderSubmittedModal: () => null,
  directQuotationCulture: initialState.directQuotationCulture,
  onNavigateToDirectQuotation: () => null,
  activateTab: () => null,
  tabColor: initialState.tabColor,
  isNewMessageOpen: initialState.isNewMessageOpen,
  closeNewMessage: () => null,
  documentColor: initialState.documentColor,
  setDocumentColor: () => null,
  isDrawerFullWidth: initialState.isDrawerFullWidth,
  toggleDrawerSize: () => null,
});

interface UIContextProviderProps {
  children: ReactNode;
}

export const UIContextProvider: FC<UIContextProviderProps> = ({ children }) => {
  const [tabColor, setTabColor] = useState(initialState.tabColor);

  const activateTab = (tab: number) => {
    if (tab === HOMETAB.SIMULATIONS || tab === PARTNERSTAB.CONSULTANTS) {
      setTabColor("Green");
    } else if (tab === HOMETAB.QUOTATIONS) {
      setTabColor("Pink");
    } else if (tab === HOMETAB.PROPOSALS) {
      setTabColor("Blue");
    } else if (tab === HOMETAB.INSURANCES) {
      setTabColor("LightGrey");
    }
  };
  const [documentColor, setDocumentColor] = useState(
    initialState.documentColor
  );

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [isDrawerFullWidth, setIsDrawerFullWidth] = useState(
    initialState.isDrawerFullWidth
  );

  const openDrawer = () => {
    setIsDrawerOpen(true);
    setIsDrawerFullWidth(true);
  };
  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setIsDrawerFullWidth(false);
    setDocumentColor(ColorNames.GREEN);
  };
  const toggleDrawerSize = () => setIsDrawerFullWidth(!isDrawerFullWidth);

  const [isProfileModalOpen, setIsProfileModalOpen] = useState<boolean>(
    initialState.isProfileModalOpen
  );
  const openProfileModal = () => setIsProfileModalOpen(true);
  const closeProfileModal = () => setIsProfileModalOpen(false);

  const [isNewMessageOpen, setIsNewMessageOpen] = useState<boolean>(
    initialState.isNewMessageOpen
  );
  const closeNewMessage = () => setIsNewMessageOpen(false);

  useEffect(() => {
    if (isNewMessageOpen) {
      setTimeout(() => {
        setIsNewMessageOpen(false);
      }, 4000);
    }
  }, [isNewMessageOpen]);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(initialState.isConfirmationModalOpen);
  const [confirmationModalMessage, setConfirmationModalMessage] =
    useState<string>("tem certeza que deseja sair?");
  const [onConfirmModal, setOnConfirmModal] = useState<() => void>(() => null);
  const [onCancelModal, setOnCancelModal] = useState<() => void>(() => null);

  function openConfirmationModal(
    message: string,
    onConfirm: () => void,
    onCancel: () => void
  ) {
    setIsConfirmationModalOpen(true);
    setConfirmationModalMessage(message);
    setOnConfirmModal(() => onConfirm);
    setOnCancelModal(() => onCancel);
  }

  function renderConfirmationModal() {
    return (
      <Modal hideCloseButton>
        <ConfirmedActionComponent
          message={confirmationModalMessage}
          onConfirm={onConfirmModal}
          onCancel={onCancelModal}
        />
      </Modal>
    );
  }

  const closeConfirmationModal = () => setIsConfirmationModalOpen(false);

  const [isSubmittedModalOpen, setIsSubmittedModalOpen] = useState<boolean>(
    initialState.isSubmittedModalOpen
  );
  const [submittedModalMessage, setSubmittedModalMessage] = useState<string>(
    "operação realizada com sucesso!"
  );
  function openSubmittedModal(message: string) {
    setIsSubmittedModalOpen(true);
    setSubmittedModalMessage(message);
  }
  function renderSubmittedModal() {
    return <ConfirmedActionComponent message={submittedModalMessage} />;
  }
  const closeSubmittedModal = () => setIsSubmittedModalOpen(false);

  const [isLoading, setIsLoading] = useState<boolean>(initialState.isLoading);
  const [loadingMessage, setLoadingMessage] = useState<string>(
    initialState.loadingMessage
  );

  const toggleLoading = useCallback(
    (status: boolean, message: string = "") => {
      setIsLoading(status);
      setLoadingMessage(message);
    },
    [setIsLoading, loadingMessage]
  );

  const [directQuotationCulture, setDirectQuotationCulture] = useState(
    initialState.directQuotationCulture
  );
  const onNavigateToDirectQuotation = (cultureId: string) => {
    setDirectQuotationCulture(cultureId);
  };

  return (
    <UIContext.Provider
      value={{
        isDrawerOpen,
        closeDrawer,
        openDrawer,
        isLoading,
        setIsLoading: toggleLoading,
        loadingMessage,
        isProfileModalOpen,
        openProfileModal,
        closeProfileModal,
        isConfirmationModalOpen,
        openConfirmationModal,
        closeConfirmationModal,
        renderConfirmationModal,
        isSubmittedModalOpen,
        openSubmittedModal,
        closeSubmittedModal,
        renderSubmittedModal,
        directQuotationCulture,
        onNavigateToDirectQuotation,
        activateTab,
        tabColor,
        isNewMessageOpen,
        closeNewMessage,
        documentColor,
        setDocumentColor,
        isDrawerFullWidth,
        toggleDrawerSize,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};

export default UIContext;
