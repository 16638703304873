/** @jsxImportSource @emotion/react */

import { Outlet, useNavigate } from "react-router-dom";
import MainFrame from "../../components/MainFrame";
import CardList from "../../components/CardList";
import { useUIStore } from "../../hooks/useUIStore";
import Layout from "../../components/Layout";
import { DocumentsLocation, ToDocumentDetailsLocation } from "../../Locations";
import { useListDocumentsQuery } from "../../graphql/__GENERATED__/types";
import DocumentCard from "./DocumentCards/DocumentCard";
import useDocumentsFilter from "../../hooks/useDocumentsFilter";

const Documents = () => {
  const { toggleDrawerSize } = useUIStore();
  const navigate = useNavigate();

  const documentFilters = useDocumentsFilter();

  const { data, loading } = useListDocumentsQuery({
    variables: {
      filter: documentFilters.composeQuery(),
      sort: { updatedAt: -1 },
    },
  });

  return (
    <Layout>
      <MainFrame
        drawerContent={<Outlet />}
        onClose={() => navigate(DocumentsLocation)}
        onToggle={toggleDrawerSize}
        filter={documentFilters}
      >
        <CardList
          data={data?.documents || []}
          redirectLocation={ToDocumentDetailsLocation}
          CardComponent={DocumentCard}
          loading={loading}
        />
      </MainFrame>
    </Layout>
  );
};

export default Documents;
