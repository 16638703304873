import { FC } from "react";
import { FormFieldV2 } from "../../../../graphql/__GENERATED__/types";
import { TInputMasks } from "../../models/formInterfaces";
import useFormField from "../../hooks/useFormField";
import Column from "../../../../components/Grid/Column";
import FloatInput from "../../../../components/Input/FloatInput";
import useDebouncedMaskedValue from "./useMaskedDebouncedValue";
import FormLabel from "../FormLabel";

interface NumberFieldProps {
  field: FormFieldV2;
}

const NumberField: FC<NumberFieldProps> = ({ field }) => {
  const { name, label, value, disabled, inputMask, type } = field;

  const { handleChange, editing, startEditing, finishEditing, error } =
    useFormField(field);

  const { localValue, handleInputChange } = useDebouncedMaskedValue(
    value,
    editing,
    (v: string) => handleChange(v ? parseFloat(v) : ""),
    inputMask as TInputMasks
  );

  return (
    <Column xs={12} useYPaddings>
      <FormLabel>{label.toUpperCase()}</FormLabel>
      <FloatInput
        placeholder={label.toLowerCase()}
        disabled={!!disabled}
        type={type}
        name={name}
        value={localValue}
        onChange={handleInputChange}
        onFocus={startEditing}
        onBlur={finishEditing}
        errorMessage={error}
      />
    </Column>
  );
};

export default NumberField;
