import { FC } from "react";
import { FormFieldV2 } from "../../../../graphql/__GENERATED__/types";
import FormField from "../../form.field";
import Row from "../../../../components/Grid/Row";
import Column from "../../../../components/Grid/Column";

interface ObjectFieldProps {
  field: FormFieldV2;
}

const ObjectField: FC<ObjectFieldProps> = ({ field: { fields } }) => {
  return (
    <Column xs={12} style={{ padding: 0 }}>
      <Row noMargins>
        {fields!.map((field) => (
          <FormField key={field.fieldPath} field={field} />
        ))}
      </Row>
    </Column>
  );
};

export default ObjectField;
