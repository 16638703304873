/** @jsxImportSource @emotion/react */

import { FC, useMemo } from "react";
import { Lead, LeadTypes } from "../../../graphql/__GENERATED__/types";
import DetailsSectionColumns from "../components/Main/Grid/DetailsSectionColumns";
import { formatPhone } from "../../../utils/formatNumber";
import { LeadTypeLabels } from "../../../views/Leads/LeadDetails/sections/Summary";

interface LeadDataProps {
  lead: Lead;
}

const LeadData: FC<LeadDataProps> = ({
  lead: { type, name, phone, email, linkedin },
}) => {
  const partnerInfo = useMemo(
    () => [
      {
        label: "tipo",
        value: `${LeadTypeLabels[type as LeadTypes]}`,
      },
      {
        label: "nome",
        value: name,
      },
      {
        label: "telefone",
        value: phone && formatPhone(phone),
      },
      {
        label: "email",
        value: email,
      },
      {
        label: "linkedin",
        value: linkedin,
      },
    ],
    [name, phone, email, type, linkedin]
  );

  return (
    <>
      <DetailsSectionColumns columns={[partnerInfo]} />
    </>
  );
};

export default LeadData;
