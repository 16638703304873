/** @jsxImportSource @emotion/react */

import moment from "moment";
import { ObjectNotifications, Task } from "../../graphql/__GENERATED__/types";
import { theme, ColorNames } from "../../theme";
import Icon from "../Icons";
import { IconNames } from "../Icons/styles/iconNames";
import { IconSizes } from "../Icons/styles/iconSizes";
import Typography from "../Typography";
import { FC, useMemo } from "react";
import Avatar from "../Avatar";
import { isToday } from "../../utils/dateUtils";
import CustomLink from "../CustomLink";

interface NotificationProps {
  notification?: ObjectNotifications;
  task?: Task;
  redirectLocation: (docId: string) => string;
}

const styles = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    cursor: "pointer",
    transition: "background-color 0.5s ease",
    ":hover": {
      backgroundColor: theme.colors.LightGrey[100],
    },
    borderRadius: 5,
    padding: "10px 10px",
  },
  iconWithTextContainer: {
    display: "flex",
    alignItems: "start",
    justifyContent: "start",
  },
  docInfoText: {
    padding: "0 35px",
  },
};

type NotificationData = {
  message: string[];
  date: string;
  objectId: string;
  originInfo: string;
  responsible: string | null;
  color: ColorNames;
  author?: string | null;
};

const Notification: FC<NotificationProps> = ({
  notification,
  task,
  redirectLocation,
}) => {
  const notificationGroupType = useMemo(() => {
    if (notification?.notificationGroup.includes("-messages")) {
      return "messages";
    }
    return "actions";
  }, [notification?.notificationGroup]);

  const data = useMemo<NotificationData>(() => {
    if (notification) {
      return {
        message: [
          notificationGroupType === "messages"
            ? `${notification.groupNotifications.length} Nova${
                notification.groupNotifications.length > 1 ? "s" : ""
              } mensage${
                notification.groupNotifications.length > 1 ? "ns" : "m"
              }`
            : notification.groupNotifications[0].text,
        ],
        date: moment(notification.groupNotifications[0].createdAt).format(
          "HH:mm"
        ),
        objectId: notification.groupNotifications[0].objectId,
        originInfo: notification.groupNotifications[0].originInfo,
        responsible: null,
        author: notification.groupNotifications[0].author?.name,
        color: isToday(notification.groupNotifications[0].createdAt)
          ? ColorNames.PINK
          : ColorNames.GREEN,
      };
    } else {
      return {
        message: [task!.message],
        date: moment(task!.dateDue).format("DD/MM"),
        objectId: task!.docId,
        originInfo: task!.originInfo!,
        responsible: task!.responsible?.name || "",
        color: ColorNames.GREEN,
        author: null,
      };
    }
  }, [notification, task]);

  const redirect = useMemo(() => {
    return redirectLocation(
      `${data.objectId}/${
        notification?.notificationGroup.includes("-messages") ? "notas" : ""
      }`
    );
  }, [notification?.notificationGroup, redirectLocation]);

  if (!data) return null;

  return (
    <CustomLink to={redirect} css={styles.container}>
      <div css={styles.iconWithTextContainer}>
        {data.responsible !== null ? (
          <Avatar
            name={data.responsible}
            size={IconSizes.Large}
            color={data.color}
          />
        ) : (
          <Icon
            name={IconNames.AvatarFilled}
            size={IconSizes.ExtraSmall}
            color={data.color}
          />
        )}
        <div css={styles.docInfoText}>
          {data.message.map((m, i) => (
            <Typography
              variant="textMedium"
              lowercase
              weight="bold"
              component="p"
              align="left"
              key={`${data.objectId}-${i}`}
            >
              {m}
              {data.author && (
                <Typography
                  variant="textMedium"
                  lowercase
                  component="span"
                  align="left"
                >
                  {notificationGroupType === "messages" ? " de " : " por "}
                  {data.author}
                </Typography>
              )}
            </Typography>
          ))}
          <Typography variant="textMedium" lowercase component="p" align="left">
            {data.originInfo}
          </Typography>
        </div>
      </div>
      <Typography variant="textMedium" lowercase color={data.color}>
        {data.date}
      </Typography>
    </CustomLink>
  );
};

export default Notification;
