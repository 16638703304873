import {
  DocumentStages,
  DocumentStatus,
} from "../../../../../../graphql/__GENERATED__/types";

export const stageLabel = {
  [DocumentStages.Simulation]: "Cotação",
  [DocumentStages.Quotation]: "Cotação",
  [DocumentStages.Proposal]: "Proposta",
  [DocumentStages.Policy]: "Apólice",
};

export const statusLabel = {
  [DocumentStatus.Pending]: "Pendente",
  [DocumentStatus.Incomplete]: "Incompleta",
  [DocumentStatus.Done]: "Finalizada",
  [DocumentStatus.Sent]: "Enviada",
  [DocumentStatus.Effective]: "Vigente",
  [DocumentStatus.NonEffective]: "Não vigente",
  [DocumentStatus.Rejected]: "Recusada",
  [DocumentStatus.UnderAnalysis]: "Em análise",
  [DocumentStatus.Cancelled]: "Cancelada",
};
