import { FC, useMemo } from "react";
import {
  GetEditPaymentOnDocumentFormResponseDocument,
  GetEditPaymentOnDocumentFormResponseQueryVariables,
  Payment,
  Services,
  SubventionType,
} from "../../../../../../graphql/__GENERATED__/types";
import { formatPrice } from "../../../../../../utils/formatNumber";
import EditableSection from "../../../../../../components/EditableSection";
import { useModalStore } from "../../../../../../components/Modal/context/ModalStore";
import useDocumentDetails from "../../../../../../hooks/useDocumentDetails";
import DetailsSectionColumns from "../../../../../../components/Details/components/Main/Grid/DetailsSectionColumns";
import { PaymentStatusLabels } from "../Payments";
import { DetailsRowValueVariants } from "../../../../../../components/Details/components/Main/Grid/DetailsRowValue";
import { DetailsRowProps } from "../../../../../../components/Details/components/Main/Grid/DetailsRow";
import { formatISODate } from "../../../../../../utils/dateUtils";

export const SubventionTypeLabels = {
  [SubventionType.Federal]: "Federal",
  [SubventionType.State]: "Estadual",
};

interface PaymentProps {
  data: Payment;
}

const PaymentFC: FC<PaymentProps> = ({ data: payment }) => {
  const { refetch } = useDocumentDetails();
  const paymentInfo = useMemo(() => {
    return [
      [
        {
          label: "Parcela",
          value: payment.name,
        },
        {
          label: "Tipo de subvenção",
          value: SubventionTypeLabels[payment.subventionType!],
        },
        {
          label: "Seguradora",
          value: payment.provider,
        },
      ],
      [
        {
          label: "Vencimento",
          value: formatISODate(payment.dateDue),
        },
        {
          label: "Valor",
          value: formatPrice(payment.amount),
        },
        {
          label: "Boleto",
          variant: DetailsRowValueVariants.File,
          value: payment.billFile?.fileKey && {
            file: payment.billFile,
            objectId: payment?._id,
            fieldPath: "billFile",
            serviceName: Services.Payments,
          },
        },
        {
          label: "Status",
          value: PaymentStatusLabels[payment.status],
        },
      ].filter(Boolean) as DetailsRowProps[],
    ];
  }, [payment]);

  const { closeModal } = useModalStore();

  return (
    <EditableSection<{}, GetEditPaymentOnDocumentFormResponseQueryVariables>
      title="Parcela"
      main
      editQuery={GetEditPaymentOnDocumentFormResponseDocument}
      editFlag="admin-only"
      objectId={payment._id}
      onSubmit={() => {
        refetch(), closeModal();
      }}
    >
      <DetailsSectionColumns columns={paymentInfo} />
    </EditableSection>
  );
};

export default PaymentFC;
