import { FC, useCallback, useMemo } from "react";
import { FormFieldV2, FormSectionV2 } from "../../graphql/__GENERATED__/types";
import FormField from "./form.field";
import Row from "../../components/Grid/Row";
import Button from "../../components/Button";
import Column from "../../components/Grid/Column";
import useForm from "./hooks/useForm.v2";

interface FormSectionProps {
  section: FormSectionV2;
}

const FormSection: FC<FormSectionProps> = ({ section }) => {
  const { setShowFormErrors, handleSubmitFormResponse } = useForm();

  const sectionHasError = useMemo(() => {
    const fieldHasError = ({ error, fields }: FormFieldV2): boolean =>
      !!(error || fields?.some(fieldHasError));
    return section.fields.some(fieldHasError);
  }, [...section.fields]);

  const handleFormSubmit = useCallback(() => {
    if (sectionHasError) {
      setShowFormErrors(true);
    } else {
      handleSubmitFormResponse();
    }
  }, [sectionHasError, setShowFormErrors]);

  // const sectionErrors = useMemo(() => {
  //   const fieldErrors = ({ error, fields, name }: FormFieldV2): string[] => [
  //     ...(error ? [`${name} - ${error}`] : []),
  //     ...(fields?.flatMap(fieldErrors) ?? []),
  //   ];
  //   return section.fields.flatMap(fieldErrors);
  // }, [...section.fields]);

  return (
    <Row noMargins>
      {section.fields.map((field) => {
        return <FormField key={field.fieldPath} field={field} />;
      })}
      <Column xs={12} useYPaddings>
        <Button
          onClick={handleFormSubmit}
          asDisabled={sectionHasError}
          text="CONCLUIR"
        />
      </Column>
    </Row>
  );
};

export default FormSection;
