/** @jsxImportSource @emotion/react */

import { FC, useEffect, useState } from "react";
import ScrollContainer from "../../../../components/ScrollContainer";
import NotificationGroup from "./components/NotificationsGroup";
import NotificationsHeader, {
  notificationsHeaderHeight,
} from "./components/NotificationsHeader";
import {
  NotificationResponse,
  Services,
  useNotificationsQuery,
} from "../../../../graphql/__GENERATED__/types";
import SkeletonDashboardMessages from "../SkeletonDashboardMessages";
import CustomContainer from "../../../../components/Grid/CustomContainer";
import { useFlags } from "../../../../hooks/useFlags";
import ChatListQueryWrapper from "../../../../components/WAChat/components/ChatListQueryWrapper";

interface NotificationsProps {
  serviceName: Services;
  redirectLocation: (docId: string) => string;
}

const styles = {
  container: {
    height: "100%",
    overflow: "hidden",
    boxSizing: "border-box" as "border-box",
  },
};

export enum DocumentNavTabs {
  NOTIFICATIONS,
  CHAT,
}

const Notifications: FC<NotificationsProps> = ({
  serviceName,
  redirectLocation,
}) => {
  const { data, loading, refetch } = useNotificationsQuery({
    variables: { serviceName },
  });

  useEffect(() => {
    if (data) {
      refetch();
    }
  }, []);

  const notifications: NotificationResponse[] = data?.notifications || [];

  const [activeTab, setActiveTab] = useState<DocumentNavTabs>(
    DocumentNavTabs.NOTIFICATIONS
  );

  const { isFlagEnabled } = useFlags();

  return (
    <div css={styles.container}>
      {loading && <SkeletonDashboardMessages service="notification" />}
      <CustomContainer>
        <NotificationsHeader
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <ScrollContainer height={`calc(100% - ${notificationsHeaderHeight}px)`}>
          {activeTab == DocumentNavTabs.NOTIFICATIONS && (
            <>
              {loading && "Carregando..."}
              {notifications.length
                ? notifications.map((dailyNotifications) => (
                    <NotificationGroup
                      key={dailyNotifications.notificationDate}
                      notifications={dailyNotifications!}
                      redirectLocation={redirectLocation}
                    />
                  ))
                : null}
            </>
          )}
          {activeTab == DocumentNavTabs.CHAT &&
            isFlagEnabled("use-whatsapp") && <ChatListQueryWrapper />}
        </ScrollContainer>
      </CustomContainer>
    </div>
  );
};

export default Notifications;
