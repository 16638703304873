/** @jsxImportSource @emotion/react */

import AuthLayout from "../../components/AuthLayout";
import {
  CSSRulesResolver,
  useCSSRulesWithTheme,
} from "../../hooks/useCSSRulesWithTheme";
import loginImage from "../../components/AuthLayout/assets/images/bgImage.png";
import { useEffect, useState } from "react";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import FormApp from "../../modules/Forms";
import { useCreateFormResponseMutation } from "../../graphql/__GENERATED__/types";
import Row from "../../components/Grid/Row";
import Column from "../../components/Grid/Column";
import Typography from "../../components/Typography";
import { ColorNames } from "../../theme";
import HR from "../../components/HorizontalLine";
import { useAuth } from "../../hooks/useAuth";
import { Link } from "react-router-dom";
import { LoginLocation } from "../../Locations";

const getCSSRules: CSSRulesResolver = ({ breakpoints, gutters }) => ({
  bgImage: {
    backgroundImage: `url(${loginImage})`,
    backgroundSize: "cover",
    minHeight: "100%",
    width: "100%",
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      minHeight: 250,
    },
  },
  authContainer: {
    padding: "30px 50px",
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      padding: "30px 15px",
    },
  },
  colMobileMb: {
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      marginBottom: gutters.xs,
    },
  },
  mb: {
    marginBottom: 24,
  },
  messageContainer: {
    [`@media (min-width: ${breakpoints.sm}px)`]: {
      height: "calc(100vh - 246px)",
      boxSizing: "border-box",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
});

const Register = () => {
  const { errorHandler } = useErrorHandler();
  const styles = useCSSRulesWithTheme(getCSSRules);

  const [resId, setResId] = useState<string>("");
  const [showMessage, setShowMessage] = useState(false);
  const [createFormResponseMutation] = useCreateFormResponseMutation();
  const { loaded: authIsLoaded } = useAuth();

  useEffect(() => {
    if (authIsLoaded && !resId) {
      createFormResponseMutation({
        variables: {
          formId: "register",
          metadata: {},
        },
      })
        .then(({ data }) => setResId(data!.createFormResponse!._id!))
        .catch((e) => errorHandler(new Error("Erro ao criar formulário"), e));
    }
  }, [authIsLoaded]);

  const handleSubmit = (id: string) => {
    if (id) {
      setShowMessage(true);
    }
  };

  return (
    <AuthLayout rightSideContent={<div css={styles.bgImage} />}>
      <div css={styles.authContainer}>
        <Typography variant="textMedium" component="div">
          Já tem uma conta? <Link to={LoginLocation}>Faça login</Link>
        </Typography>
        {showMessage && (
          <div css={styles.messageContainer}>
            <Row justify="center" css={styles.successMessage}>
              <Column>
                <Typography
                  uppercase
                  variant="textMedium"
                  color="Grey"
                  align="center"
                  component="p"
                >
                  Obrigado! Estamos analisando seu cadastro.
                </Typography>
                <HR spacingSize="small" color={ColorNames.GREEN} />
              </Column>
            </Row>
          </div>
        )}
        {!showMessage && resId && (
          <FormApp
            formId="register"
            onSubmit={handleSubmit}
            docId={resId}
            singleSection
            large
          />
        )}
      </div>
    </AuthLayout>
  );
};

export default Register;
