import { FC } from "react";
import { FormFieldV2 } from "../../../../graphql/__GENERATED__/types";
import useFormField from "../../hooks/useFormField";
import Column from "../../../../components/Grid/Column";
import useDebouncedMaskedValue from "./useMaskedDebouncedValue";
import PriceInput from "../../../../components/Input/PriceInput";
import FormLabel from "../FormLabel";

interface MonetaryFieldProps {
  field: FormFieldV2;
}

const MonetaryField: FC<MonetaryFieldProps> = ({ field }) => {
  const { name, label, value, disabled, type } = field;

  const { handleChange, editing, startEditing, finishEditing, error } =
    useFormField(field);

  const { localValue, handleInputChange } = useDebouncedMaskedValue(
    value,
    editing,
    (v: string) => handleChange(v ? parseFloat(v) : "")
  );

  return (
    <Column xs={12} useYPaddings>
      <FormLabel>{label.toUpperCase()}</FormLabel>
      <PriceInput
        placeholder={label.toLowerCase()}
        disabled={!!disabled}
        type={type}
        name={name}
        value={localValue}
        handleChange={handleInputChange}
        onFocus={startEditing}
        onBlur={finishEditing}
        errorMessage={error}
      />
    </Column>
  );
};

export default MonetaryField;
