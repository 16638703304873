/** @jsxImportSource @emotion/react */

import { FC, ReactNode, useEffect } from "react";
import Header, { headerHeight } from "./components/Header";
import { useUIStore } from "../../hooks/useUIStore";
import UserProfileModal from "../Modal/userProfile";
import { useAuth } from "../../hooks/useAuth";
import useOutsideClick from "../../hooks/useOutsideClick";

interface LayoutProps {
  children: ReactNode;
  useFullHeight?: boolean;
  padding?: number;
}

const Layout: FC<LayoutProps> = ({ children, useFullHeight, padding = 20 }) => {
  const { isProfileModalOpen, closeProfileModal, closeDrawer } = useUIStore();

  const { isLoggedIn, user, signOut } = useAuth();

  const ref = useOutsideClick(() => {
    if (isProfileModalOpen) {
      closeProfileModal();
    }
  });

  // make sure drawer is closed to avoid maintaining opacity on cards
  useEffect(() => {
    closeDrawer();
  }, []);

  return (
    <>
      <Header
        isLoggedIn={isLoggedIn}
        username={user.name || user.username}
        email={user.email}
        signOut={signOut}
      />
      <div
        css={{
          height: useFullHeight ? "100%" : `calc(100vh - ${headerHeight}px)`,
          padding: padding,
          boxSizing: "border-box",
        }}
      >
        <UserProfileModal
          onRef={ref}
          show={isProfileModalOpen}
          onClose={closeProfileModal}
        />
        {children}
      </div>
    </>
  );
};

export default Layout;
