/** @jsxImportSource @emotion/react */

import { FC, useState } from "react";
import { FormFieldV2, Maybe } from "../../../../graphql/__GENERATED__/types";
import ObjectField from "./ObjectField";
import Typography from "../../../../components/Typography";
import Row from "../../../../components/Grid/Row";
import { CSSObject } from "@emotion/react";
import { ColorNames } from "../../../../theme";
import ArrowUpIcon from "../../../../components/Icons/arrowUpIcon";
import ArrowDownIcon from "../../../../components/Icons/arrowDownIcon";
import Icon from "../../../../components/Icons";
import { IconNames } from "../../../../components/Icons/styles/iconNames";
import Column from "../../../../components/Grid/Column";
import useFormField from "../../hooks/useFormField";

const styles: CSSObject = {
  title: {
    cursor: "pointer",
    margin: 0,
  },
  icons: {
    display: "flex",
    alignItems: "end",
    "& > span": {
      paddingLeft: 10,
    },
  },
};

interface ObjectListItemFieldProps {
  field: FormFieldV2;
  allowDelete?: Maybe<boolean>;
}

const ObjectListItemField: FC<ObjectListItemFieldProps> = ({
  field,
  allowDelete,
}) => {
  const { deleteListItem } = useFormField(field);
  const [show, setShow] = useState(true);
  return (
    <Column xs={12}>
      <Row
        align="center"
        justify="between"
        css={styles.title}
        onClick={() => setShow(!show)}
        noMargins
      >
        <Typography
          variant="textLarge"
          color={ColorNames.GREEN}
          uppercase
          component="p"
        >
          {field.label}
        </Typography>
        <div css={styles.icons}>
          {show && <ArrowUpIcon />}
          {!show && <ArrowDownIcon />}
          {allowDelete && (
            <Icon
              name={IconNames.Delete}
              color={ColorNames.GREEN}
              onClick={deleteListItem}
            />
          )}
        </div>
      </Row>
      {show && (
        <Row>
          <ObjectField field={field} />
        </Row>
      )}
    </Column>
  );
};

export default ObjectListItemField;
