import { FC } from "react";
import Dashboard from ".";
import {
  Services,
  useGetDocumentsDashboardCountersQuery,
  useGetDocumentsDashboardFunnelQuery,
} from "../../graphql/__GENERATED__/types";
import { DocumentsLocation, ToDocumentDetailsLocation } from "../../Locations";
import { documentsCharts } from "./assets/dashboardCharts";
import useDashboardCharts from "./hooks/useDashboardCharts";
import useDashboardFunnel from "./hooks/useDashboardFunnel";
import useDashboardDocumentsFilter from "../../hooks/useDashboardDocumentsFilter";
import useDashboardDocumentsTypeFilter from "../../hooks/useDashboardDocumentsTypeFilter";
import useDashboard from "../../hooks/useDashboard";
import useDashboardFunnelDates from "./hooks/useDashboardFunnelDates";
import { documentsFunnel } from "./assets/dashboardFunnel";

const DocumentsDashboard: FC = () => {
  const filterList = useDashboardDocumentsFilter();
  const typeFilter = useDashboardDocumentsTypeFilter();

  const { data: chartsData, loading: chartsDataLoading } =
    useGetDocumentsDashboardCountersQuery({
      variables: {
        filter: [...filterList.composeQuery(), ...typeFilter.composeQuery()],
      },
    });

  const documentsChartsData = useDashboardCharts(
    documentsCharts,
    chartsData?.getDocumentsDashboardCounters!,
    chartsDataLoading,
    DocumentsLocation
  );

  const { funnelDateRange } = useDashboard();
  const { startDate, endDate } = useDashboardFunnelDates(funnelDateRange);

  const { data: funnelData, loading: funnelDataLoading } =
    useGetDocumentsDashboardFunnelQuery({
      variables: {
        startDate: startDate,
        endDate: endDate,
        filter: [...filterList.composeQuery(), ...typeFilter.composeQuery()],
      },
    });

  const documentsFunnelData = useDashboardFunnel(
    documentsFunnel,
    funnelData?.getDocumentsDashboardFunnel!,
    funnelDataLoading
  );

  return (
    <Dashboard
      serviceName={Services.Documents}
      docIdRedirectLocation={(docId) => ToDocumentDetailsLocation(docId)}
      typeFilter={typeFilter}
      chartData={documentsChartsData}
      funnelData={documentsFunnelData}
      filterList={filterList}
    />
  );
};

export default DocumentsDashboard;
