import { useCallback } from "react";
import { useUIStore } from "./useUIStore";

const useConfirmedAction = (
  confirmationMessage: string = "Tem certeza?",
  positiveAction: () => Promise<void>,
  negativeAction: () => Promise<void> = async () => {}
) => {
  const { openConfirmationModal, closeConfirmationModal } = useUIStore();
  const action = useCallback(async () => {
    openConfirmationModal(
      confirmationMessage,
      () => positiveAction().then(closeConfirmationModal),
      () => negativeAction().then(closeConfirmationModal)
    );
  }, [
    confirmationMessage,
    openConfirmationModal,
    closeConfirmationModal,
    positiveAction,
    negativeAction,
  ]);
  return action;
};

export default useConfirmedAction;
