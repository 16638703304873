import { useParams } from "react-router-dom";
import { useGetDocumentDetailsQuery } from "../../../graphql/__GENERATED__/types";
import WAChat from "..";
import { useFlags } from "../../../hooks/useFlags";

const WAChatByDocId = () => {
  const { docId: paramsId } = useParams();
  const { loading, data } = useGetDocumentDetailsQuery({
    variables: {
      docId: paramsId!,
    },
  });
  const { isFlagEnabled } = useFlags();

  if (loading || !isFlagEnabled("use-whatsapp")) return null;

  return <WAChat contactId={data?.document!.farmer!._id!} />;
};
export default WAChatByDocId;
