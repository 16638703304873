import { AuthContextInterface } from "../contexts/auth";
import { TInsertSimulationDetailsPayload } from "../components/Details/types";
import { SimulationsObject, TotalsResult } from "../models/Simulation";
import serviceFetch from "./fetch";

export const getSimulationsByRequest = async (
  auth: AuthContextInterface,
  request: string
): Promise<{ data: Array<SimulationsObject> }> =>
  serviceFetch(auth, `simulacoes/?${request}`, null, "get");

export const getSimulationById = async (
  auth: AuthContextInterface,
  id: string
): Promise<{ data: SimulationsObject }> =>
  serviceFetch(auth, `simulacoes/${id}`, null, "get");

export const getSimulationShareToken = async (
  auth: AuthContextInterface,
  id: string
): Promise<{ success: boolean; token: string }> =>
  serviceFetch(auth, `simulacoes/${id}/share`, null, "get");

export const approveSimulationById = async (
  auth: AuthContextInterface,
  id: string,
  resultIndex: string
): Promise<{ success: Boolean }> =>
  serviceFetch(auth, `simulacoes/${id}/approve/${resultIndex}`, null, "get");

export const deleteSimulationById = async (
  auth: AuthContextInterface,
  id: string,
  rejectReason: string
): Promise<{ data: SimulationsObject }> =>
  serviceFetch(null, `simulacoes/${id}`, null, "put", { rejectReason });

export const retrySimulationById = async (
  id: string,
  resultIndex: string
): Promise<{ data: SimulationsObject }> =>
  serviceFetch(null, `simulacoes/${id}/retry/${resultIndex}`, null, "put", {});

export const updateSimulationDetailsById = async (
  id: string,
  payload: TInsertSimulationDetailsPayload,
  resultIndex?: string
): Promise<{ success: Boolean }> =>
  serviceFetch(
    null,
    `simulacoes/${id}/details/${resultIndex || ""}`,
    null,
    "put",
    payload
  );

export const assignSimulationById = async (
  auth: AuthContextInterface,
  id: string,
  userId: string
): Promise<{ success: Boolean }> =>
  serviceFetch(auth, `simulacoes/${id}/assign`, null, "put", { userId });

export const totalsCalcSimulations = async (
  auth: AuthContextInterface,
  filter: any
): Promise<{ data: TotalsResult }> =>
  serviceFetch(auth, `simulacoes/totals`, null, "post", { filter });

export const changeOriginatorSimulationById = async (
  auth: AuthContextInterface,
  id: string,
  userId: string
): Promise<{ success: Boolean }> =>
  serviceFetch(auth, `simulacoes/${id}/change-originator`, null, "put", {
    userId,
  });
