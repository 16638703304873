// import { ContactTags } from "../graphql/__GENERATED__/types";
import { TFilter } from "./types";

const contactFilters = (): TFilter[] => [
  // {
  //   label: "Tipo",
  //   value: [],
  //   urlParam: "tipo",
  //   name: "tipo",
  //   selectMultiValueDisplay: "all",
  //   options: [
  //     {
  //       value: "farmer",
  //       label: "Produtor",
  //       filter: {
  //         tags: ContactTags.Farmer,
  //       },
  //     },
  //   ],
  // },
];

export default contactFilters;
