import { useTheme } from "@emotion/react";
import { CSSObject, SerializedStyles } from "@emotion/serialize";
import { CustomTheme } from "../theme";

type IPropertiesToAdd<T extends {}> = T & CustomTheme;

export type ICSSRules = Record<string, CSSObject>;

export type CSSRulesResolver<
  T = {},
  R = Record<string, CSSObject | SerializedStyles["styles"]>
> = (
  //@ts-ignore
  args: IPropertiesToAdd<T>
) => R;

export function useCSSRulesWithTheme<T, R>(
  getCSSRules: CSSRulesResolver<T, R>,
  args: T = {} as T
) {
  const theme = useTheme() as CustomTheme;

  return getCSSRules({ ...args, ...theme }) as R;
}
