/** @jsxImportSource @emotion/react */

import { FC } from "react";
import { User } from "../../../../../graphql/__GENERATED__/types";
import { usePartnerDetails } from "../../hooks/usePartnerDetails";
import UserData from "../../../../../components/Details/UserData";
import DetailsSection from "../../../../../components/Details/components/Main/Section/DetailsSection";

const PartnerData: FC = () => {
  const { data } = usePartnerDetails();

  return (
    <DetailsSection title="Dados do Parceiro" main>
      <UserData user={data as User} />
    </DetailsSection>
  );
};

export default PartnerData;
