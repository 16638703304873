import { FC, useMemo, useState } from "react";
import SelectInput, { SelectInputProps } from "./SelectInput";
import {
  Services,
  useGetMany2XOptionsQuery,
} from "../../../graphql/__GENERATED__/types";
import useDebounce from "../../../hooks/useDebounce";
import { ColorNames } from "../../../theme";

export interface Many2OneInputProps
  extends Omit<Omit<SelectInputProps, "options">, "onInputChange"> {
  serviceName: Services;
  domain: any;
  color?: ColorNames;
}

const Many2OneInput: FC<Many2OneInputProps> = ({
  serviceName,
  domain,
  value,
  color,
  ...props
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const { debouncedValue } = useDebounce(searchTerm, 500);

  const { data, loading } = useGetMany2XOptionsQuery({
    variables: {
      serviceName: serviceName,
      currentValue: [value],
      domain,
      searchTerm: debouncedValue,
    },
    fetchPolicy: "network-only",
  });

  const options = useMemo(() => {
    return (
      data?.getMany2XOptions.map((option) => ({
        label: option.label!,
        value: option.value!,
      })) || []
    );
  }, [data?.getMany2XOptions]);

  return (
    <SelectInput
      {...props}
      value={value}
      onInputChange={setSearchTerm}
      options={options}
      loading={loading}
      color={color}
    />
  );
};

export default Many2OneInput;
