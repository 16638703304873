import { AuthContextInterface } from "../contexts/auth";
import { ProposalsObject } from "../models/Proposals";
import { TotalsResult } from "../models/Simulation";
import serviceFetch, { FileData, serviceFetchFile } from "./fetch";

export const getProposalsByRequest = async (
  auth: AuthContextInterface,
  request: string
): Promise<{ data: Array<ProposalsObject> }> =>
  serviceFetch(auth, `propostas/?${request}`, null, "get");

export const getProposalById = async (
  auth: AuthContextInterface,
  id: string
): Promise<{ data: ProposalsObject }> =>
  serviceFetch(auth, `propostas/${id}`, null, "get");

export const retryProposalById = async (
  id: string
): Promise<{ data: ProposalsObject }> =>
  serviceFetch(null, `propostas/${id}/retry`, null, "put", {});

export const uploadProposalById = async (
  auth: AuthContextInterface,
  id: string,
  fileField: string,
  file: File
): Promise<{ success: Boolean }> =>
  serviceFetch(
    auth,
    `propostas/${id}/upload-file/${fileField}`,
    null,
    "post",
    null,
    file
  );

export const downloadProposalById = async (
  auth: AuthContextInterface,
  id: string,
  fileField: string
): Promise<FileData> =>
  serviceFetchFile(
    auth,
    `propostas/${id}/download-file/${fileField}`,
    null,
    "get"
  );

export const confirmProposalPaymentById = async (
  auth: AuthContextInterface,
  id: string
): Promise<{ success: Boolean; insuranceId: string }> => {
  return serviceFetch(
    auth,
    `propostas/${id}/confirm-payment`,
    null,
    "post",
    {}
  );
};

export const getProposalShareToken = async (
  auth: AuthContextInterface,
  id: string
): Promise<{ success: boolean; token: string }> =>
  serviceFetch(auth, `propostas/${id}/share`, null, "get");

export const assignProposalById = async (
  auth: AuthContextInterface,
  id: string,
  userId: string
): Promise<{ success: Boolean }> =>
  serviceFetch(auth, `propostas/${id}/assign`, null, "put", { userId });

export const totalsCalcProposals = async (
  auth: AuthContextInterface,
  filter: any
): Promise<{ data: TotalsResult }> =>
  serviceFetch(auth, `propostas/totals`, null, "post", { filter });

export const changeOriginatorProposalById = async (
  auth: AuthContextInterface,
  id: string,
  userId: string
): Promise<{ success: Boolean }> =>
  serviceFetch(auth, `propostas/${id}/change-originator`, null, "put", {
    userId,
  });
