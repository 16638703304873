import { useMemo } from "react";
import { DashboardFunnelValue } from "../../../graphql/__GENERATED__/types";
import { TFunnelCard } from "../assets/dashboardFunnel";
import { FunnelCardsProps } from "../components/FunnelCards";

const useDashboardFunnel = (
  dashboardFunnel: TFunnelCard[],
  data: DashboardFunnelValue[],
  loading: boolean
) => {
  const totalsData: FunnelCardsProps = useMemo(() => {
    const cards = dashboardFunnel?.map((card) => ({
      label: card.label,
      color: card.color,
      iconName: card.iconName,
      value:
        data?.find((total) => total.category === card.category)?.value || 0,
      variation: 0,
    }));
    return { data: cards, loading: loading };
  }, [data, loading]);

  return totalsData;
};

export default useDashboardFunnel;
