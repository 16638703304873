import { FC } from "react";
import { FormFieldV2 } from "../../../../graphql/__GENERATED__/types";
import TextInput from "../../../../components/Input/TextInput";
import { TInputMasks } from "../../models/formInterfaces";
import useFormField from "../../hooks/useFormField";
import Column from "../../../../components/Grid/Column";
import useDebouncedMaskedValue from "./useMaskedDebouncedValue";
import FormLabel from "../FormLabel";

interface TextFieldProps {
  field: FormFieldV2;
}

const TextField: FC<TextFieldProps> = ({ field }) => {
  const { name, label, value, disabled, inputMask, type } = field;

  const { handleChange, editing, startEditing, finishEditing, error } =
    useFormField(field);

  const { maskedValue, handleInputChange } = useDebouncedMaskedValue(
    value,
    editing,
    handleChange,
    inputMask as TInputMasks
  );

  return (
    <Column xs={12} useYPaddings>
      <FormLabel>{label.toUpperCase()}</FormLabel>
      <TextInput
        placeholder={label.toLowerCase()}
        disabled={disabled!}
        type={type}
        name={name}
        value={maskedValue}
        onChange={handleInputChange}
        onFocus={startEditing}
        onBlur={finishEditing}
        errorMessage={error}
      />
    </Column>
  );
};

export default TextField;
