import { useMemo } from "react";
import { Contact } from "../../../graphql/__GENERATED__/types";
import Card, { CardFC } from "../../../components/CardList/components/Card";
import { ColorNames } from "../../../theme";

const ContactCard: CardFC<Contact> = ({ data, active, onClick }) => {
  const cardData = useMemo(() => {
    return {
      line1: data.name || "",
      line2: data.cpfCnpj || "",
      line3: data.email || "",
      line4: data.mobilePhone || "",
    };
  }, [data]);

  //   const detailTopRight = useMemo(() => {
  //     return (
  //       <Tooltip
  //         text={statusLabel[data.status! as DocumentStatus]}
  //         id={`status-${data._id!}`}
  //       >
  //         <Icon name={statusIcon()!} color={cardColor} />
  //       </Tooltip>
  //     );
  //   }, [data.status, cardColor, data._id]);

  return (
    <Card
      cardData={cardData}
      //   detailTop={detailTopRight}
      //   detailBottom={
      //     <Responsibles
      //       responsibles={responsibles}
      //       color={cardColor}
      //       docId={data._id!}
      //     />
      //   }
      color={ColorNames.GREEN}
      active={active}
      onClick={onClick}
    />
  );
};

export default ContactCard;
