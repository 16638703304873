import { usePartnerDetails } from "../hooks/usePartnerDetails";
import {
  GetCreateMeetingOnObjectFormResponseDocument,
  Services,
} from "../../../../graphql/__GENERATED__/types";
import Meetings from "../../../../components/Meetings";
import useDetailsColor from "../../../../hooks/useDetailsColor";

const PartnerMeetings = () => {
  const {
    data: { _id, meetings },
    refetch,
  } = usePartnerDetails();

  const { color } = useDetailsColor();

  return (
    <Meetings
      meetings={meetings!}
      createQuery={GetCreateMeetingOnObjectFormResponseDocument}
      parentId={_id!}
      serviceName={Services.Users}
      color={color}
      refetch={refetch}
    />
  );
};

export default PartnerMeetings;
