/** @jsxImportSource @emotion/react */
import { FC } from "react";
import Typography from "../../../components/Typography";
import { ColorNames } from "../../../theme";
import { FormSection } from "../models/formInterfaces";
import editPencil from "../../../components/assets/editPencil.png";

const styles = {
  titleLine: {
    display: "flex",
    alignItems: "center",
    lineHeight: "20px",
  },
  icon: {
    maxHeight: "1em",
    marginLeft: "1em",
    cursor: "pointer",
  },
};

interface SectionTitleProps {
  sectionData: FormSection;
  handleEdit?: () => void;
}
const SectionTitle: FC<SectionTitleProps> = ({ sectionData, handleEdit }) => {
  return (
    <>
      <div css={styles.titleLine}>
        <Typography
          variant="textMedium"
          align="left"
          component="span"
          color={ColorNames.BLACK}
          uppercase
        >
          {sectionData.title}
        </Typography>
        {handleEdit && (
          <img onClick={handleEdit} src={editPencil} css={styles.icon}></img>
        )}
      </div>
      <Typography
        variant="textMedium"
        align="left"
        component="div"
        color={ColorNames.GREY}
        uppercase
      >
        {sectionData.subtitle}
      </Typography>
    </>
  );
};

export default SectionTitle;
